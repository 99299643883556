import {
  forwardRef,
  HTMLAttributes,
  InputHTMLAttributes,
  Ref,
  useEffect,
  useId,
  useState,
} from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';
import { TYPOGRAPHY } from '../../styles/typography';
import useForwardedRef from '../../hooks/useForwardedRef';
import { COLOR, GREYSCALE, PirateShipColor } from '../../styles/colors';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../styles/borders';
import withOpacity from '../../utils/withOpacity';
import { SPACING } from '../../styles/spacing';
import useHighlightColor from '../../hooks/useHighlightColor';

type TextFieldWrapperProps = {
  small: boolean;
  highlightColor: PirateShipColor;
  prefill?: boolean;
};

export const TextFieldWrapper = styled('div')<TextFieldWrapperProps>`
  height: ${({ small }) => (small ? '30px' : '50px')};
  border-width: ${({ small }) => (small ? BORDER_WIDTH.xs : BORDER_WIDTH.sm)};
  border-style: solid;
  border-color: ${({ highlightColor, prefill }) => (prefill ? COLOR.blue : highlightColor)};
  border-radius: ${BORDER_RADIUS.sm};
  box-sizing: border-box;
  position: relative;
  color: ${GREYSCALE.black};
  background-color: ${GREYSCALE.white};
  box-shadow: inset 0 0 0 ${withOpacity(GREYSCALE.black, 0.075)};
  overflow: hidden;
  display: ${({ small }) => (small ? 'flex' : 'block')};
`;

type TextFieldLabelProps = {
  active?: boolean;
};

const TextFieldLabel = styled('label')<TextFieldLabelProps>`
  position: absolute;
  transform-origin: top left;
  // overflow, white-space, text-overflow and width are defined to prevent line breaks within the label.
  overflow: clip;
  white-space: nowrap;
  text-overflow: ellipsis;
  // width: 90% is set to ensure the label wont overlap with for example a search button rendered on top of the input field
  // since the label is downscaled by 25% on active, the width may expand by 25%
  width: ${({ active }) => (active ? '125%' : '90%')};
  transform: ${({ active }) =>
    active ? 'translate(2px, 8px) scale(.75)' : 'translate(0, 17px) scale(1)'};
  transition: all 0.1s ease-in-out;
  left: 10px;
  top: -${SPACING.sm};
  color: ${GREYSCALE.grey50};
  font-weight: ${TYPOGRAPHY.fontWeight.regular};
  pointer-events: none;

  ${TextFieldWrapper}.small & {
    position: static;
    display: inline-block;
    padding: ${SPACING.xs} 0px ${SPACING.xs} ${SPACING.sm};
    font-size: ${TYPOGRAPHY.fontSize.sm};
    line-height: 24px;
    transform: none;
  }
`;

const AddonWrapper = styled.div`
  display: flex;
  height: 100%;
  font-size: ${TYPOGRAPHY.fontSize.md};
  ${TextFieldWrapper}.small & {
    flex: 1;
    flex-direction: row;
    font-size: ${TYPOGRAPHY.fontSize.sm};
  }
`;

const Addon = styled.span`
  white-space: pre;
  display: flex;
  align-items: flex-end;
  align-self: center;
  position: relative;
  height: 100%;
  line-height: 24px;
  padding-bottom: 2px;
`;

const Prefix = styled(Addon)`
  padding-right: ${SPACING.none};
  padding-left: ${SPACING.sm};

  ${TextFieldWrapper}.small ${TextFieldLabel} ~ ${AddonWrapper} & {
    margin-right: -${SPACING.sm}; // this pulls the text input left
  }

  ${TextFieldWrapper}:not(.small) ${TextFieldLabel} ~ ${AddonWrapper} & {
    margin-right: -${SPACING.md}; // this pulls the text input left
    padding-left: ${SPACING.md};
    padding-right: ${SPACING.none};
  }
`;

const Suffix = styled(Addon)`
  padding-left: ${SPACING.none};

  ${TextFieldWrapper}:not(.small) ${TextFieldLabel} ~ ${AddonWrapper} & {
    padding-right: ${SPACING.md};
    padding-left: ${SPACING.none};
  }
`;

type TextFieldInputProps = {
  center?: boolean;
  prefill?: boolean;
};

const TextFieldInput = styled('input')<TextFieldInputProps>`
  border: ${BORDER_WIDTH.none};
  background-color: ${({ prefill }) => (prefill ? COLOR.lightBlue : 'revert')};
  outline: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  flex: 1;
  box-sizing: border-box;
  padding: 0px ${SPACING.md};
  line-height: 24px;
  text-align: ${(props) => (props.center ? 'center' : 'unset')};
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: ${SPACING.none};
  }
  -moz-appearance: textfield;
  ${TextFieldWrapper}:not(.small) ${TextFieldLabel} ~ ${AddonWrapper} & {
    padding: ${SPACING.xl} ${SPACING.md} ${SPACING.sm};
  }

  ${TextFieldWrapper}.small & {
    height: 30px;
    padding: ${SPACING.xs} ${SPACING.sm};
    ::-webkit-input-placeholder {
      position: relative !important;
    }
    ::-moz-placeholder,
    ::-ms-input-placeholder {
      vertical-align: middle;
    }
  }
`;

function isNotEmpty(value: JSX.IntrinsicElements['input']['value']) {
  return value != null && value !== '';
}

export type TextFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  center?: boolean;
  error?: boolean;
  small?: boolean;
  prefix?: string;
  suffix?: string;
  prefill?: boolean;
};

function TextFieldComponent(
  {
    id,
    type,
    label,
    value: controlledValue,
    center,
    autoComplete = 'off',
    error = false,
    small = false,
    prefix,
    suffix,
    placeholder,
    prefill,
    onFocus,
    onChange,
    onBlur,
    ...others
  }: TextFieldProps,
  ref: Ref<HTMLInputElement>,
) {
  const forwardedRef = useForwardedRef(ref);
  const textfieldId = useId();
  const [focused, setFocused] = useState(false);
  const [active, setActive] = useState(false);
  const [internalValue, setInternalValue] = useState<typeof controlledValue>(controlledValue);
  const value = controlledValue !== undefined ? controlledValue : internalValue;

  const highlightColor = useHighlightColor(error, focused);

  useEffect(() => {
    setActive(
      focused || isNotEmpty(value) || prefix != null || suffix != null || placeholder != null,
    );
  }, [focused, value, prefix, suffix, placeholder]);

  return (
    <TextFieldWrapper
      small={small}
      prefill={prefill}
      highlightColor={highlightColor}
      className={clsx({ small })}
      onClick={() => {
        if (forwardedRef && typeof forwardedRef === 'object' && forwardedRef.current) {
          forwardedRef.current.focus();
        }
      }}
    >
      {label && (
        <TextFieldLabel active={active} htmlFor={id || textfieldId}>
          {label}
        </TextFieldLabel>
      )}
      <AddonWrapper>
        {prefix && <Prefix>{prefix}</Prefix>}
        <TextFieldInput
          {...others}
          prefill={prefill}
          ref={forwardedRef}
          id={id || textfieldId}
          center={center}
          type={type || 'text'}
          value={value}
          onFocus={(event) => {
            setFocused(true);
            if (onFocus) onFocus(event);
          }}
          onBlur={(event) => {
            setFocused(false);
            if (onBlur) onBlur(event);
          }}
          onChange={(event) => {
            if (controlledValue === undefined) {
              setInternalValue(event.target.value);
            }
            if (onChange) onChange(event);
          }}
          autoComplete={autoComplete}
          placeholder={placeholder}
        />
        {suffix && <Suffix>{suffix}</Suffix>}
      </AddonWrapper>
    </TextFieldWrapper>
  );
}

const TextField = forwardRef(TextFieldComponent);
export default TextField;

// -------------- Fake Text Field ----------------
// used for wrapping credit card IFrame elements from Stripe and Spreedly.
// In both cases, the children element contains the IFrame, which contains the text field from them.
// It is a "fake" text field because we have no direct access to the value stored in it (e.g for validating via Formik/yup)

const FakeTextFieldContent = styled.div`
  width: 100%;
`;

export type FakeTextFieldProps = HTMLAttributes<HTMLDivElement> & {
  label?: string;
  active?: boolean;
  focused?: boolean;
  error?: boolean;
  small?: boolean;
  prefix?: string;
  suffix?: string;
};

export const FakeTextField = forwardRef(function FakeTextField(
  props: FakeTextFieldProps,
  ref: Ref<HTMLDivElement>,
) {
  const {
    label,
    active,
    focused,
    error = false,
    small = false,
    prefix,
    suffix,
    children,
    ...others
  } = props;

  const highlightColor = useHighlightColor(error, Boolean(focused));

  return (
    <TextFieldWrapper className={clsx({ small })} small={small} highlightColor={highlightColor}>
      {label && <TextFieldLabel active={active}>{label}</TextFieldLabel>}
      <AddonWrapper>
        {prefix && <Prefix>{prefix}</Prefix>}
        <FakeTextFieldContent ref={ref} {...others}>
          {children}
        </FakeTextFieldContent>
        {suffix && <Suffix>{suffix}</Suffix>}
      </AddonWrapper>
    </TextFieldWrapper>
  );
});
