import styled from '@emotion/styled';
import { useCallback, useMemo, useState, useRef } from 'react';
import { Form, Formik } from 'formik';
import { Container } from 'react-grid-system';
import { useIntercom } from 'react-intercom-hook';
import { useNavigate } from 'react-router';
import { Col, PageContainer, Row } from '../../layout/Grid';
import Link, { FakeLink } from '../../Link';
import { PlatformIcon } from '../../Icon';
import logoUrl from '../../../images/pirateshipLogoFull.svg';
import Main from '../../layout/Main';
import PageContent from '../../layout/PageContent';
import Label from '../../form/Label';
import { TYPOGRAPHY } from '../../../styles/typography';
import useIsEu from '../../../hooks/useIsEu';
import SignupTerms from './signup/SignupTerms';
import useSignup from '../../../hooks/useSignup';
import SignupError from './signup/SignupError';
import { SignupFormValues, initialValues } from './signup/SignupFormValues';
import { initSignupValidationSchema } from './signup/signupValidation';
import SignupSubmitButton from './signup/form/SignupSubmitButton';
import SignupOriginAddress from './signup/form/SignupOriginAddress';
import SignupReturnAddress from './signup/form/SignupReturnAddress';
import SignupEmail from './signup/form/SignupEmail';
import SignupPassword from './signup/form/SignupPassword';
import SignupUseOriginAsReturnCheckbox from './signup/form/SignupUseOriginAsReturnCheckbox';
import sanitizePhoneNumber from '../../../utils/sanitizePhoneNumber';
import TurnstileWidget, { TurnstileWidgetRef } from './turnstile/TurnstileWidget';

export type ShopifyRegistrationPageProps = {
  enableTurnstilePreClearance?: boolean;
  user?: {
    email?: string;
  };
  use_address_as_return?: boolean | number;
  address?: {
    company?: string;
    address1?: string;
    address2?: string;
    city?: string;
    region_id?: string;
    postcode?: string;
    phone?: string | number;
  };
  turnstileKey?: string;
};

const Styled = {
  Separator: styled.span`
    margin: 0px 50px 35px 30px;
  `,
  Logo: styled.div`
    font-size: 0;
    color: black;
    width: 172.8px;
    height: 87.8px;
    background-image: url('${logoUrl}');
    background-repeat: no-repeat;
  `,
  ServiceClaim: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 12px 12px;

    span[role='img'] {
      font-size: ${TYPOGRAPHY.fontSize.xxl};
      margin-right: 10px;
      margin-bottom: -3px;
    }
  `,

  LiveChatSection: styled.div`
    text-align: center;
  `,
};

export default function ShopifyRegistrationPage({
  turnstileKey,
  enableTurnstilePreClearance,
  user = { email: initialValues.email },
  use_address_as_return = initialValues.useOriginAsReturnAddress,
  address = initialValues.originAddress,
}: ShopifyRegistrationPageProps) {
  const [pasted, setPasted] = useState(false);
  const handlePaste = useCallback(() => setPasted(true), [setPasted]);
  const { show: showIntercom } = useIntercom();
  const turnstileWidgetRef = useRef<TurnstileWidgetRef>(null);
  const [signup, { loading, error: signupError }] = useSignup(turnstileWidgetRef);
  const isEu = useIsEu();
  const validationSchema = useMemo(() => initSignupValidationSchema({ isEu }), [isEu]);
  const navigate = useNavigate();
  const [isChallenging, setIsChallenging] = useState(enableTurnstilePreClearance);

  const handleChallengeSolved = useCallback(() => {
    setIsChallenging(false);
  }, [setIsChallenging]);

  const handleSubmit = async (values: SignupFormValues) => {
    const sanitizedValues = {
      ...values,
      originAddress: {
        ...values.originAddress,
        phone: sanitizePhoneNumber(values.originAddress.phone),
      },
    };
    const signupRequestResponse = await signup(sanitizedValues, pasted, 'shopify_app_store');

    if (signupRequestResponse.redirect) {
      // set the user to be logged in (bridged), as the bridged template
      // is not rendered again before we show signup complete page
      window.isLoggedIn = true;

      navigate('/signup/completed', {
        state: {
          ...(!signupRequestResponse.redirect.endsWith('/ship')
            ? {
                redirectUrl: signupRequestResponse.redirect,
              }
            : {}),
        },
      });
    }
  };

  const featureSet = [
    { emoji: '💵', ariaLabel: 'dollar bill', prompt: '100% free' },
    { emoji: '⚖️', ariaLabel: 'balance scale', prompt: 'No fees or hidden costs' },
    { emoji: '🔒', ariaLabel: 'lock', prompt: 'Your data is secure & never sold' },
    { emoji: '📦', ariaLabel: 'package', prompt: 'UPS-certified & USPS-approved postage' },
  ];

  return (
    <Main id="main-content">
      <PageContent>
        <Row justify="center" spaceBelow>
          <Col width="auto">
            <span>
              Already have a Pirate Ship account?{' '}
              <Link to="/" bridgeHref="/">
                Login to Connect Shopify
              </Link>
            </span>
          </Col>
        </Row>
        <Row justify="center" spaceBelow>
          <Col width="auto">
            <h1>Create a free Pirate Ship account</h1>
          </Col>
        </Row>
        <Row justify="center" align="end" spaceBelow>
          <PlatformIcon icon="shopify" height={100} width={150} />
          <Styled.Separator>+</Styled.Separator>
          <Styled.Logo />
        </Row>
        <PageContainer>
          <Formik
            initialValues={{
              ...initialValues,
              email: user?.email ?? initialValues.email,
              useOriginAsReturnAddress: !!(
                use_address_as_return ?? initialValues.useOriginAsReturnAddress
              ),
              originAddress: {
                ...initialValues.originAddress,
                ...address,
                regionCode: address?.region_id ?? initialValues.originAddress.regionCode,
                phone:
                  address?.phone?.toString().replace(/[^\d]/g, '') ??
                  initialValues.originAddress.phone,
              },
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ values }) => (
              <Form>
                <Container style={{ maxWidth: 970 }}>
                  <Row justify="center" align="center" spaceBelow>
                    {featureSet.map(({ prompt, ariaLabel, emoji }) => (
                      <Styled.ServiceClaim key={ariaLabel}>
                        <span role="img" aria-label={ariaLabel}>
                          {emoji}
                        </span>
                        <small>{prompt}</small>
                      </Styled.ServiceClaim>
                    ))}
                  </Row>

                  <Row spaceBelow>
                    <Col>
                      <Styled.LiveChatSection>
                        Have any questions?{' '}
                        <FakeLink onClick={showIntercom}>Live chat now</FakeLink>
                      </Styled.LiveChatSection>
                    </Col>
                  </Row>
                  <SignupError error={signupError} />

                  <Row>
                    <Col md={12}>
                      <Label>Create your free account</Label>
                    </Col>
                    <Col spaceBelow md={6}>
                      <SignupEmail onPaste={handlePaste} />
                    </Col>
                    <Col spaceBelow md={6}>
                      <SignupPassword onPaste={handlePaste} />
                    </Col>
                    <Col md={12}>
                      <SignupOriginAddress onPaste={handlePaste} columnLayout />
                    </Col>
                    <Col spaceBelow>
                      <SignupUseOriginAsReturnCheckbox />
                    </Col>
                  </Row>

                  {!values.useOriginAsReturnAddress && (
                    <Row>
                      <Col md={12}>
                        <SignupReturnAddress onPaste={handlePaste} columnLayout />
                      </Col>
                    </Row>
                  )}

                  <SignupTerms isEu={isEu} />

                  <Row justify="center">
                    <Col md={6}>
                      <TurnstileWidget
                        ref={turnstileWidgetRef}
                        siteKey={turnstileKey}
                        autoExecute={enableTurnstilePreClearance}
                        onSuccess={handleChallengeSolved}
                      />
                      <SignupSubmitButton loading={loading} disabled={isChallenging} />
                    </Col>
                  </Row>

                  <Row justify="center">
                    <Col width="auto">
                      <small>
                        Already have an account?{' '}
                        <Link to="/" bridgeHref="/">
                          Login
                        </Link>
                      </small>
                    </Col>
                  </Row>
                </Container>
              </Form>
            )}
          </Formik>
        </PageContainer>
      </PageContent>
    </Main>
  );
}
