import CenteredPageHeader from '../../layout/CenteredPageHeader';
import LandlubberContent from '../../layout/LandlubberContent';
import ChangePasswordForm from './password/ChangePasswordForm';
import environment from '../../../utils/environment';
import { setFlashMessage } from '../../../apollo/cache/flashMessages';

type InvitePasswordPageProps = {
  turnstileKey?: string;
};

export default function InvitePasswordPage({ turnstileKey }: InvitePasswordPageProps) {
  return (
    <LandlubberContent>
      <CenteredPageHeader title="Create a new password" />
      <ChangePasswordForm
        turnstileKey={turnstileKey}
        labels={{ submit: 'Set Password' }}
        onSuccess={() => {
          if (environment.isBridge()) {
            setFlashMessage('Your password was set successfully', 'success', undefined, true);
          }
        }}
      />
    </LandlubberContent>
  );
}
