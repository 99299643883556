import styled from '@emotion/styled';
import { GREYSCALE } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';

const InputSubText = styled.div`
  color: ${GREYSCALE.grey50};
  text-align: center;
  font-size: ${TYPOGRAPHY.fontSize.sm};
  padding-top: ${SPACING.xs};
`;

export default InputSubText;
