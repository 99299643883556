import { ChangeEvent, useState, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { PaymentSource } from '../../gql/graphql';
import { addRumCustomAction } from '../../utils/addRumCustomAttributes';
import formatPaymentSourceTitle from '../../utils/formatPaymentSourceTitle';
import Button from '../form/Button';
import FormControl from '../form/FormControl';
import Select from '../form/Select';
import { Col, Row } from '../layout/Grid';
import Modal from './Modal';
import { SPACING } from '../../styles/spacing';
import { MEDIA_QUERY } from '../../styles/breakpoints';

type ComponentProps = PropsWithChildren<{
  paymentSources: PaymentSource[];
  submitTitle: string;
  submitButtonVariant: 'success' | 'primary';
  selectedPaymentSourceId: string;
  open: boolean;
  onClose: () => void;
  onPaymentSourceChange: (paymentSourceId: string) => void;
  onPaymentSourceAddRequest: () => void;
}>;

const ModalContainer = styled('div')`
  display: flex;
  gap: ${SPACING.xl};
  flex-direction: column;
  @media (min-width: ${MEDIA_QUERY.smMin}) {
    justify-content: space-between;
  }
`;

const Center = styled('div')`
  display: grid;
  height: 225px;
  place-items: center;
`;

export default function ChangePaymentSourceModal({
  paymentSources,
  submitTitle,
  submitButtonVariant,
  selectedPaymentSourceId,
  open,
  onClose,
  onPaymentSourceChange,
  onPaymentSourceAddRequest,
}: ComponentProps) {
  const [newPaymentSourceId, setNewPaymentSourceId] = useState<string | null>(null);

  return (
    <Modal
      title="Your payment was declined"
      width={600}
      open={open}
      onOpen={() => {
        addRumCustomAction('change_payment_source_modal_open', {
          reason: 'payment_source_declined',
        });
      }}
      onClose={onClose}
      datadogActionClose="change_payment_source_modal_cancel"
      datadogActionBackdrop="change_payment_source_modal_cancel"
    >
      <ModalContainer id="modalBody">
        <Row>
          {paymentSources.length > 1 && (
            <Col>
              <p>
                Your payment was declined by your financial institution. To complete your purchase,
                you’ll need to change to a different payment method.
              </p>
              <Center>
                <FormControl
                  name="lala.paymentSourceId"
                  as={Select}
                  options={[
                    { title: 'Please select', value: '' },
                    ...paymentSources
                      .filter((ps) => ps.id !== selectedPaymentSourceId)
                      .map((source) => ({
                        title: formatPaymentSourceTitle(source),
                        value: source.id,
                      })),
                  ]}
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                    setNewPaymentSourceId(event.target.value);
                  }}
                />
                <Button
                  size="xLarge"
                  variant={submitButtonVariant}
                  disabled={newPaymentSourceId === null}
                  data-dd-action-name="change_payment_source_modal_change_action"
                  onClick={() => {
                    if (newPaymentSourceId !== null) onPaymentSourceChange(newPaymentSourceId);
                  }}
                >
                  {submitTitle}
                </Button>
              </Center>
            </Col>
          )}
        </Row>
        <Row>
          {paymentSources.length === 1 && (
            <Col>
              <p>
                Your payment was declined by your financial institution. To complete your purchase,
                you’ll need to add a different payment method.
              </p>
              <Center style={{ height: '150px' }}>
                <Button
                  size="large"
                  variant="primary"
                  data-dd-action-name="change_payment_source_modal_add_action"
                  onClick={onPaymentSourceAddRequest}
                >
                  Add New Payment Method
                </Button>
              </Center>
            </Col>
          )}
        </Row>
      </ModalContainer>
    </Modal>
  );
}
