import {
  useState,
  useRef,
  ChangeEvent,
  KeyboardEvent,
  ClipboardEvent,
  InputHTMLAttributes,
  useEffect,
} from 'react';
import { useField } from 'formik';
import styled from '@emotion/styled';
import { SPACING } from '../../styles/spacing';
import TextField from './TextField';

const Styled = {
  FormField: styled.div`
    display: flex;
    align-items: center;

    > * {
      flex: 1;
      text-align: center;

      &:not(:last-child) {
        margin-right: ${SPACING.lg};
      }
    }
  `,
  TextField: styled(TextField)`
    ::selection {
      background-color: transparent;
      text-decoration: none;
    }
  `,
};

type OneTimePasswordProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  length?: number;
};

export default function OneTimePassword({ name, length = 6 }: OneTimePasswordProps) {
  const [, { value: otp }, { setTouched, setValue: setFormikValue, setError }] =
    useField<string>(name);
  const setValue = async (input: string) => {
    await setTouched(true);
    await setFormikValue(input);
  };

  useEffect(() => {
    if (otp && otp.length < length) {
      setError(undefined);
    }
  }, [otp, setError, length]);

  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef(Object([...Array(length)]));
  const getOTPValue = (): string[] => (otp ? otp.split('') : []);

  const focusInput = (index: number): void => {
    if (inputRefs.current[index]) {
      inputRefs.current[index]?.focus();
      inputRefs.current[index]?.select();
      setActiveInput(index);
    }
  };

  const handleOTPChange = async (otpArray: Array<string>): Promise<void> => {
    await setValue(otpArray.join(''));
  };

  const changeCode = async (changeValue: string): Promise<void> => {
    const otpValues = getOTPValue();
    otpValues[activeInput] = changeValue;
    await handleOTPChange(otpValues.slice(0, length));
  };

  const handleClick = (index: number): void => {
    focusInput(index);
  };

  const handleChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const value = event.target.value.slice(-1); // only the last char
    await changeCode(value);
    focusInput(activeInput > otp.length ? otp.length + 1 : activeInput + 1);
  };

  const handleFocus = (index: number): void => {
    setActiveInput(index);
  };

  const handleKeyDown = async (event: KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (event.key === otp[activeInput]) {
      event.preventDefault();
      focusInput(activeInput + 1);
    }

    const specialKeys = [
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'Backspace',
      'Delete',
      'Space',
      'Spacebar',
    ];

    if (specialKeys.includes(event.code)) {
      event.preventDefault();
      switch (event.code) {
        case 'Backspace':
          await changeCode('');
          focusInput(activeInput - 1);
          break;
        case 'Delete':
          await changeCode('');
          break;
        case 'ArrowLeft':
          focusInput(activeInput - 1);
          break;
        case 'ArrowRight':
          focusInput(activeInput + 1);
          break;
        default:
          break;
      }
    }
  };

  function assignRef(element: HTMLInputElement | null, index: number) {
    inputRefs.current[index] = element;
  }

  const handlePaste = async (event: ClipboardEvent) => {
    event.preventDefault();
    focusInput(0);
    setActiveInput(0);
    const otpValue = getOTPValue();

    const pastedData = event.clipboardData
      ?.getData('text/plain')
      .replace(/[-_.\\/\s]/g, '')
      .slice(0, length)
      .split('');

    [...Array(length)].forEach((_, index) => {
      otpValue[index] = pastedData?.shift() ?? '';
      focusInput(index);
      setActiveInput(index);
    });

    await handleOTPChange(otpValue);
  };

  return (
    <Styled.FormField data-testid="inputWrapper">
      {Array.from({ length }, (_, index) => index).map((index) => (
        <div key={`otp-input-${index + 1}`}>
          <Styled.TextField
            data-testid={`otp-input-${index + 1}`}
            onChange={handleChange}
            onFocus={() => handleFocus(index)}
            ref={(element) => assignRef(element, index)}
            value={getOTPValue()[index] ?? ''}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            onClick={() => handleClick(index)}
            aria-label={`Please enter OTP character ${index + 1}`}
            inputMode="numeric"
            autoComplete="one-time-code"
          />
        </div>
      ))}
    </Styled.FormField>
  );
}
