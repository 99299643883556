import { formatCurrency } from '../../utils/currency';

type BatchPriceSummaryForShipmentPriceInfo = {
  averagePrice: number;
  isRefunded: boolean;
  samePriceForAll: boolean;
  shipmentCount: number;
};

// renders the price with the word "each" or "average" if per-shipment info is available
function renderPerShipmentPriceInfo(priceSummary: BatchPriceSummaryForShipmentPriceInfo) {
  const refundedPrice = formatCurrency(-priceSummary.averagePrice);
  const price = formatCurrency(priceSummary.averagePrice);
  if (priceSummary.isRefunded) {
    if (priceSummary.shipmentCount === 1) return `(${refundedPrice} refunded)`;
    return priceSummary.samePriceForAll
      ? `(${refundedPrice} refunded per label)`
      : `(${refundedPrice} refunded per label, average)`;
  }
  if (priceSummary.shipmentCount === 1) return `@ ${price}`;
  return priceSummary.samePriceForAll ? `@ ${price} each` : `@ ${price} average`;
}

export default renderPerShipmentPriceInfo;
