import { getIn, useFormikContext } from 'formik';
import styled from '@emotion/styled';
import { Row, Col } from '../layout/Grid';
import IntercomArticleLink from '../IntercomArticleLink';
import FormControl from '../form/FormControl';
import Alert from '../Alert';
import Checkbox from '../form/Checkbox';
import { SPACING } from '../../styles/spacing';
import { NamespacedSubform } from './types';

const Styled = {
  Alert: styled(Alert)`
    margin-bottom: ${SPACING.none};
  `,
};

export type HazardousMaterialsSubformValues = {
  hazardousMaterialsEnabled: boolean;
};

export default function HazardousMaterialsSubform<NS extends string>({
  namespace,
}: NamespacedSubform<NS>) {
  const { values } = useFormikContext<Record<string, unknown>>();
  const { hazardousMaterialsEnabled } = getIn(values, namespace) as HazardousMaterialsSubformValues;
  return (
    <Row>
      <Col md={12}>
        <FormControl
          name={`${namespace}.hazardousMaterialsEnabled`}
          label="Hazardous Materials"
          type="checkbox"
          as={Checkbox}
          secondaryText={
            <>
              Perfume, nail polish, hair spray, dry ice, lithium batteries, firearms, lighters,
              fuels, etc.{' '}
              <IntercomArticleLink href="https://support.pirateship.com/en/articles/2945251-shipping-hazardous-materials-with-usps">
                Learn how to ship Hazardous Materials
              </IntercomArticleLink>
            </>
          }
        />
      </Col>
      <Col md={12} spaceBelow>
        {hazardousMaterialsEnabled && (
          <Styled.Alert variant="danger">
            By using Pirate Ship, you certify that your shipment does not contain any undeclared{' '}
            <IntercomArticleLink href="https://support.pirateship.com/en/articles/2945251-shipping-hazardous-materials-with-usps">
              hazardous materials
            </IntercomArticleLink>{' '}
            or any matter{' '}
            <IntercomArticleLink href="https://support.pirateship.com/en/articles/2945261-usps-prohibited-items">
              prohibited by law or postal regulation
            </IntercomArticleLink>
            .
          </Styled.Alert>
        )}
      </Col>
    </Row>
  );
}
