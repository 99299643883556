import {
  ChangeEventHandler,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import debounce from 'lodash.debounce';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  ColumnDef,
  ColumnFiltersState,
  ColumnOrderState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
  Row as TableRow,
  Column,
  ColumnSizingState,
  RowSelectionState,
  PaginationState,
} from '@tanstack/react-table';
import type { DragStart, DragUpdate } from '@hello-pangea/dnd';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import uniq from 'lodash.uniq';
import { DataGridFilterStorageKeyTemplate, GRID_PAGE_SIZE_OPTIONS } from '../../constants';
import useOutsideClickEffect from '../../hooks/useOutsideClickEffect';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import TextCell from './cellRenderer/TextCell';
import ColumnHeader from './controls/ColumnHeader';
import SelectColumnHeader from './controls/SelectColumnHeader';
import FilterBar from './controls/FilterBar';
import Pagination from './controls/Pagination';
import { GREYSCALE } from '../../styles/colors';
import { TYPOGRAPHY } from '../../styles/typography';
import withOpacity from '../../utils/withOpacity';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../styles/borders';
import {
  InMemoryStorageKey,
  getItem as getStorageItem,
  setItem as setStorageItem,
} from '../../services/storage';
import HorizontalGridScrollWrapper from './controls/HorizontalGridScrollWrapper';
import clamp from '../../utils/clamp';
import DataPlaceholders from './controls/DataPlaceholders';
import ColumnMenu from './controls/ColumnMenu';
import useElementResizeObserver from '../../hooks/useElementResizeObserver';
import reorderArray from './utils/reorderArray';
import { safelyParseColumnOrder } from './utils/safelyParseColumns';
import adjustHeaderStyle from './utils/adjustHeaderStyle';
import { SPACING } from '../../styles/spacing';
import complexTextCellSort from './utils/complexTextCellSort';
import PageSizeSelect from './controls/PageSizeSelect';
import PageSizeInfo from './controls/PageSizeInfo';
import { MEDIA_QUERY } from '../../styles/breakpoints';
import { setFlashMessage } from '../../apollo/cache/flashMessages';
import { Col, Row } from '../layout/Grid';
import InputGroup from '../form/InputGroup';
import TextField from '../form/TextField';
import useNavigateOrHref from '../../hooks/useNavigateOrHref';

export const DEFAULT_COLUMN_WIDTH = 164;
export const MIN_COLUMN_WIDTH = 80;
export const MAX_COLUMN_WIDTH = 1000;

const PERSIST_LOCAL_FILTERS_DELAY = 100;
const PERSIST_LOCAL_FILTERS_LIFETIME = 2630000 * 1000; // 1 month

/*
TODO:
- Pagination as select for mobile
- Column menu sometimes closes on toggling columns
*/

type TableRowProps = {
  loading?: boolean;
  expanded?: boolean;
  grouped?: boolean;
  selected?: boolean;
  someSelected?: boolean;
  clickable?: boolean;
  disableHover?: boolean;
  pinned?: boolean;
};

export type LocalFiltersStorageType = {
  searchTerm: string;
  filters: ColumnFiltersState;
};

const Styled = {
  Grid: styled.div`
    font-size: ${TYPOGRAPHY.fontSize.sm};
    border: ${BORDER_WIDTH.sm} solid ${GREYSCALE.grey30};
    border-radius: ${BORDER_RADIUS.sm};
    touch-action: pan-y;
  `,
  GridFooter: styled.footer`
    display: grid;
    grid-template-columns: 1fr 200px auto auto;
    column-gap: 1.5em;
    grid-template-areas: 'pagination footerinfo pagesizeselect pagesizeinfo';
    padding: 0.5em 0.75em;
    align-items: center;
    border-top: ${BORDER_WIDTH.sm} solid ${GREYSCALE.grey30};
    border-radius: ${BORDER_RADIUS.none} ${BORDER_RADIUS.none} ${BORDER_RADIUS.sm}
      ${BORDER_RADIUS.sm};
    background-color: ${GREYSCALE.grey10};
    color: ${GREYSCALE.grey50};
    box-shadow: inset 0 1px 0 ${withOpacity(GREYSCALE.white, 0.2)},
      0 1px 2px ${withOpacity(GREYSCALE.black, 0.05)};
    @media print {
      display: none;
    }
    @media (max-width: ${MEDIA_QUERY.mdMax}) {
      grid-template-areas: 'pagination pagesizeselect' 'footerinfo pagesizeinfo';
      column-gap: 0.5em;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(2, 1fr);
    }
  `,
  FooterInfo: styled.span`
    grid-area: footerinfo;
    text-align: right;
    @media (max-width: ${MEDIA_QUERY.mdMax}) {
      text-align: left;
      margin-left: 0.2em;
    }
  `,
  Table: styled.table`
    table-layout: fixed;
    margin: 0;
    width: 100%;
    max-width: none;
    border-collapse: separate;
    border-spacing: 0;
    empty-cells: show;
    border-width: ${BORDER_WIDTH.none};
    outline: 0;
  `,
  TableHeader: styled.thead``,
  TableBody: styled.tbody``,
  TableHeaderRow: styled.tr`
    background: ${GREYSCALE.grey20};
  `,
  TableRow: styled('tr', {
    // https://github.com/emotion-js/emotion/issues/2193
    shouldForwardProp: (prop: string) =>
      !['loading', 'someSelected', 'clickable', 'grouped', 'expanded', 'pinned'].includes(prop),
  })<TableRowProps>`
    ${({ loading }) =>
      loading &&
      css`
        opacity: 0.5;
        pointer-events: none;
      `}

    ${({ clickable }) =>
      clickable &&
      css`
        cursor: pointer;
      `}

    ${({ disableHover }) =>
      !disableHover &&
      css`
        :hover {
          background-color: ${GREYSCALE.black};
          color: ${GREYSCALE.white};
        }
      `}

    ${({ pinned }) =>
      pinned &&
      css`
        background: ${GREYSCALE.grey10};
      `}
  `,
  TableHeaderGroupCell: styled.th`
    position: relative;
    margin: 0;
    padding: ${SPACING.md};
    color: ${GREYSCALE.grey60};
    background: ${GREYSCALE.grey10};
    text-align: center;
    min-height: 46px;
    line-height: 18px;
    border-width: ${BORDER_WIDTH.none} ${BORDER_WIDTH.none} ${BORDER_WIDTH.sm} ${BORDER_WIDTH.sm};
    border-style: solid;
    border-color: ${GREYSCALE.grey30};
    box-shadow: ${BORDER_WIDTH.sm} 0 0 ${GREYSCALE.grey30};
    font-weight: bold;

    :first-of-type {
      border-left-width: ${BORDER_WIDTH.none};
      border-top-left-radius: ${BORDER_RADIUS.sm};
    }

    :last-of-type {
      border-top-right-radius: ${BORDER_RADIUS.sm};
    }
  `,
  TableHeaderLeafCell: styled.th<{ isDragging?: boolean }>`
    position: relative;
    margin: 0;
    padding: 0;
    color: ${GREYSCALE.grey60};
    background: linear-gradient(to bottom, ${GREYSCALE.white} 24%, ${GREYSCALE.grey20} 100%);
    text-align: left;
    min-height: 46px;
    line-height: 18px;
    border-width: ${BORDER_WIDTH.none} ${BORDER_WIDTH.none} ${BORDER_WIDTH.sm} ${BORDER_WIDTH.sm};
    border-style: solid;
    border-color: ${GREYSCALE.grey30};
    box-shadow: ${BORDER_WIDTH.sm} 0 0 ${GREYSCALE.grey30};
    font-weight: bold;

    :first-of-type {
      border-left-width: ${BORDER_WIDTH.none};
      border-top-left-radius: ${BORDER_RADIUS.sm};
    }

    :last-of-type {
      border-top-right-radius: ${BORDER_RADIUS.sm};
    }

    ${({ isDragging }) =>
      isDragging &&
      css`
        box-shadow: ${BORDER_WIDTH.sm} 0 0 ${GREYSCALE.grey30},
          0 -${BORDER_WIDTH.sm} 0 ${GREYSCALE.grey30},
          ${BORDER_WIDTH.sm} -${BORDER_WIDTH.sm} 0 ${GREYSCALE.grey30};
      `}
  `,
  TableCell: styled.td`
    padding: 0.4em 0.6em;
    line-height: 1.6em;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    border-width: ${BORDER_WIDTH.none} ${BORDER_WIDTH.none} ${BORDER_WIDTH.xs} ${BORDER_WIDTH.xs};
    border-style: solid;
    border-color: ${GREYSCALE.grey20};

    &:first-of-type {
      border-left-width: ${BORDER_WIDTH.none};
    }

    &:last-of-type {
      border-right-width: ${BORDER_WIDTH.none};
    }
  `,
  DragHandle: styled.div``,
  ResizeHandle: styled.button<{ isLast: boolean; cursor: string }>`
    appearance: none;
    position: absolute;
    cursor: ${({ cursor }) => cursor};
    right: -6px;
    top: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    padding: 0;
    border: 0;
    background: none;

    ${({ isLast }) =>
      isLast &&
      css`
        right: 0;
        width: 5px;
      `}
  `,
  SearchBarWrapper: styled.div`
    @media print {
      display: none !important;
    }
  `,
};

const saveLocalFilters = debounce(
  (storageKey: InMemoryStorageKey, updatedFilters: ColumnFiltersState, searchTerm: string) =>
    setStorageItem(
      storageKey,
      {
        searchTerm,
        filters: updatedFilters,
      },
      {
        lifetime: PERSIST_LOCAL_FILTERS_LIFETIME,
      },
    ),
  PERSIST_LOCAL_FILTERS_DELAY,
);

export type FetchDataCallback<TData> = (settings: {
  pagination: PaginationState;
  sorting: SortingState;
  columnFilters: ColumnFiltersState;
  globalFilter: string;
  columns: Column<TData>[];
  forceNetwork?: boolean;
}) => Promise<unknown>;

export type DataGridImperativeHandleRef = {
  refetchData: (forceNetwork?: boolean) => Promise<unknown>;
  exportData: (ids?: string[]) => Promise<void>;
  clearRowSelection: () => void;
};

export type ExportDataCallback<TData> = (settings: {
  searchTerm: string;
  filters: ColumnFiltersState;
  columns: Column<TData>[];
  sortBy: SortingState;
  visibleColumns: Column<TData>[];
  ids?: string[];
}) => Promise<unknown>;

export type DataGridProps<TData> = {
  gridId: string; // used for caching
  columns: {
    [K in keyof Required<TData>]: ColumnDef<TData, TData[K]>;
  }[keyof TData][]; // ugly typing, but this fixes a incorrect type requirement from the TanStack library, see https://github.com/TanStack/table/issues/4382
  data: TData[];
  fetchData: FetchDataCallback<TData>;
  exportData?: ExportDataCallback<TData>;
  searchDelay?: number;
  loading: boolean;
  totalItemCount?: number;
  pageCount?: number;
  maxPageSize?: number;
  initialPageIndex?: number;
  initialPageSize?: number;
  initialSorting?: SortingState;
  initialColumnFilters?: ColumnFiltersState;
  initialSearchTerm?: string;
  initialRowSelection?: RowSelectionState;
  localFiltersEnabled?: boolean;
  rowSelectEnabled?: boolean;
  checkableIds?: string[]; // if checkableIds are passed, "select all" will select all checkableIds on all pages (KendoGrid behavior), otherwise "select all" will select all rows on the current page
  useCheckableIds?: boolean;
  searchBarLabel?: string;
  showDataOnFirstLoad?: boolean;
  onColumnFiltersChange?: (filters: ColumnFiltersState) => void;
  onSortingChange?: (rules: SortingState) => void;
  onPageSizeChange?: (pageSize: number) => void;
  onSelectedRowsChange?: (ids: string[]) => void;
  onColumnSizingChange?: (sizes: ColumnSizingState) => void;
  onColumnOrderChange?: (order: ColumnOrderState) => void;
  onColumnVisibilityChange?: (visibility: VisibilityState) => void;
  onRowClick?: (row: TableRow<TData>, event: MouseEvent | KeyboardEvent) => string | null;
  paginationPageButtonCount?: number;
  imperativeHandleRef?: Ref<DataGridImperativeHandleRef>;
  footerInfo?: ReactNode;
  rightSideSlot?: ReactNode;
};

function DataGrid<TData extends { id: string; pinned?: boolean; is_selectable?: boolean }>({
  gridId,
  columns,
  data,
  fetchData,
  exportData,
  searchDelay = 500,
  loading,
  pageCount: controlledPageCount,
  totalItemCount,
  initialPageIndex = 0,
  initialPageSize = GRID_PAGE_SIZE_OPTIONS[0],
  maxPageSize = 1000,
  initialSorting = [],
  initialColumnFilters = [],
  initialSearchTerm = '',
  initialRowSelection = {},
  localFiltersEnabled = false,
  rowSelectEnabled = false,
  checkableIds,
  useCheckableIds = false,
  searchBarLabel,
  showDataOnFirstLoad = true,
  onColumnFiltersChange,
  onSortingChange,
  onPageSizeChange,
  onSelectedRowsChange,
  onColumnSizingChange,
  onColumnOrderChange,
  onColumnVisibilityChange,
  onRowClick,
  paginationPageButtonCount,
  imperativeHandleRef,
  footerInfo,
  rightSideSlot,
}: DataGridProps<TData>) {
  /**
   * Local Filters (persisted to storage)
   */
  const localFiltersStorageKey: DataGridFilterStorageKeyTemplate = `psDataGridFilters_${gridId}`;
  const initialLocalFilters = useMemo(
    () =>
      (localFiltersEnabled && getStorageItem(localFiltersStorageKey)) || {
        searchTerm: '',
        filters: [],
      },
    [localFiltersEnabled, localFiltersStorageKey],
  );

  // drag n drop state
  const [grabbedHeaderIndex, setGrabbedHeaderIndex] = useState<number>(-1);
  const [draggingOverIndex, setDraggingOverIndex] = useState<number>(-1);

  const [error, setError] = useState<Error>();

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(() =>
    Object.fromEntries(columns.map((column) => [column.id!, !column.meta?.hidden])),
  );
  const [columnSizing, setColumnSizing] = useState<ColumnSizingState>(() =>
    Object.fromEntries(columns.map((column) => [column.id!, column.size || DEFAULT_COLUMN_WIDTH])),
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(() => {
    const idOnly = (c: { id?: string }) => c.id!;
    return [...safelyParseColumnOrder(columns.map(idOnly))];
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    localFiltersEnabled ? initialLocalFilters.filters : initialColumnFilters,
  );
  const [globalFilter, setGlobalFilter] = useState<string>(() => {
    if (localFiltersEnabled) return initialLocalFilters.searchTerm;
    return initialSearchTerm || '';
  });
  const [sorting, setSorting] = useState<SortingState>(initialSorting);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: initialPageIndex,
    pageSize: initialPageSize,
  });

  // row selection
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(initialRowSelection);
  // we need to replicate the tanstack internal rowSelection state in order to keep a sorted version of the selected ids
  const [rowSelectionSorted, setRowSelectionSorted] = useState<string[]>([]);
  const [selectAllEnabled, setSelectAllEnabled] = useState<boolean>(false);
  const [unselectedIds, setUnselectedIds] = useState<string[]>([]);
  const navigateOrHref = useNavigateOrHref();

  // custom meta state
  const [selectedHeaderId, setSelectedHeaderId] = useState<string>();

  const table = useReactTable({
    columns,
    data,
    sortingFns: {
      complexTextCellSort,
    },
    defaultColumn: {
      header: 'Column Header',
      filterFn: 'auto',
      cell: TextCell,
      size: DEFAULT_COLUMN_WIDTH,
      minSize: MIN_COLUMN_WIDTH, // these constrain the size of the column
      maxSize: MAX_COLUMN_WIDTH,
    },

    pageCount: controlledPageCount,
    manualPagination: true, // true, as setting pagination calls the fetchData function (server-side pagination)
    manualSorting: true, // true, as setting sorting calls the fetchData function (server-side sorting)
    manualFiltering: true, // true, as setting filters calls the fetchData function (server-side filtering)
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // set extra special table variables and functions to control state
    meta: {
      gridId,
      sortable: true, // ability to disable sorting at table-level
      loading,
    },
    // state variables.
    state: {
      columnVisibility,
      columnFilters,
      globalFilter,
      columnOrder,
      sorting,
      columnSizing,
      pagination,
      rowSelection,
    },
    enableMultiRowSelection: rowSelectEnabled,
    enableRowSelection: (row: TableRow<TData>) => Boolean(row.original.is_selectable),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getRowId: (row, _index) => `${row.id}`, // there is no signature for this that doesn't take index, but we don't want it, hence the lint disable
    onColumnSizingChange: setColumnSizing,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onColumnOrderChange: setColumnOrder,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    columnResizeMode: 'onChange',
  });
  // extract some useful things from the table
  const { rows } = table.getRowModel();
  const allColumns = table.getAllLeafColumns();
  const visibleColumns = table.getVisibleLeafColumns();
  const tableTotalSize = table.getTotalSize();
  const scrollableRef = useRef<HTMLDivElement>(null);
  const gridRef = useRef<HTMLDivElement>(null);

  /**
   * Button handlers
   */

  const clearAllFilters = useCallback(() => {
    allColumns.forEach((column) => column.setFilterValue(undefined));
    table.setGlobalFilter(undefined);
  }, [allColumns, table]);

  // BUG IN REACT-TABLE: pagination being set to -1 when data is empty
  // https://github.com/TanStack/table/issues/4478
  useEffect(() => {
    if (pagination.pageIndex < 0) setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
  }, [pagination.pageIndex, pagination.pageSize]);

  const [globalFilterValue, setGlobalFilterValue] = useState<string>(globalFilter);

  /**
   * Change listeners via effects
   */

  // If the user changes any of the following via the UI:
  // * pagination settings
  // * column sorting
  // * filters (global or column)
  // then call the fetchData function passed to the grid to refresh the data displayed
  useEffect(() => {
    fetchData({
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      },
      sorting,
      globalFilter,
      columnFilters,
      columns: allColumns,
    }).catch(setError);
  }, [
    fetchData,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    globalFilter,
    columnFilters,
    allColumns,
  ]);

  // if we need to call the fetch data / export data method from outside the component:
  // 1. create a ref from the file you call the connected data grid with type DataGridImperativeHandleRef
  // 2. set the ConnectedDataGrid's imperativeHandleRef prop to that ref
  // 3. call this function via [refname].current.refetchData() or exportData()
  useImperativeHandle(
    imperativeHandleRef,
    () => ({
      refetchData: async (forceNetwork = true) => {
        await fetchData({
          pagination: {
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize,
          },
          sorting,
          globalFilter,
          columnFilters,
          columns: allColumns,
          forceNetwork,
        }).catch(setError);
      },
      exportData: async (ids) => {
        if (!data.length) {
          setFlashMessage('There is no data in this grid to export.', 'info');
          return;
        }
        await exportData?.({
          searchTerm: globalFilter,
          filters: columnFilters,
          columns: allColumns,
          visibleColumns,
          sortBy: sorting,
          ids,
        });
      },
      clearRowSelection: () => {
        setRowSelection({});
      },
    }),
    [
      data,
      fetchData,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      globalFilter,
      columnFilters,
      allColumns,
      exportData,
      visibleColumns,
    ],
  );

  // Save searchTerm and filters to local storage if feature is enabled
  useEffect(() => {
    if (localFiltersEnabled) {
      saveLocalFilters(localFiltersStorageKey, columnFilters, globalFilter);
    }
  }, [columnFilters, globalFilter, localFiltersStorageKey, localFiltersEnabled]);

  // Listen for changes of filters and pass them on
  useEffect(() => {
    onColumnFiltersChange?.(columnFilters);
  }, [onColumnFiltersChange, columnFilters]);

  // Listen for changes of sorting rules and pass them on
  useEffect(() => {
    onSortingChange?.(sorting);
  }, [onSortingChange, sorting]);

  // Listen for changes to the page size and pass them on
  useEffect(() => {
    onPageSizeChange?.(pagination.pageSize);
  }, [onPageSizeChange, pagination.pageSize]);

  // Listen for column order changes and pass them on
  useEffect(() => {
    if (onColumnOrderChange && columns.length > 0) {
      onColumnOrderChange(columnOrder);
    }
  }, [onColumnOrderChange, columnOrder, columns.length]);

  // Listen for column visibility changes and pass them on
  useEffect(() => {
    if (onColumnVisibilityChange && columns.length > 0) {
      onColumnVisibilityChange(columnVisibility);
    }
  }, [onColumnVisibilityChange, columns.length, columnVisibility]);

  // Listen for changes of the selected row ids and pass them on as array
  useEffect(() => {
    if (onSelectedRowsChange) {
      onSelectedRowsChange(rowSelectionSorted);
    }
  }, [onSelectedRowsChange, rowSelectionSorted]);

  // Listen for column size changes and pass them on
  useEffect(() => {
    const columnIsResizing = table.getState().columnSizingInfo.isResizingColumn;
    if (onColumnSizingChange && columnIsResizing && columns.length > 0) {
      const constrainedColumnSizing = Object.fromEntries(
        Object.entries(columnSizing).map(([key, value]) => [
          key,
          clamp(value, MIN_COLUMN_WIDTH, MAX_COLUMN_WIDTH),
        ]),
      );
      onColumnSizingChange(constrainedColumnSizing);
    }
  }, [columnSizing, columns.length, onColumnSizingChange, table]);

  /**
   * Search
   */

  const onSearch = useDebouncedCallback(setGlobalFilter, searchDelay);

  // Reset page index if global filter or column filter are updated
  useEffect(() => {
    table.setPageIndex(0);
  }, [globalFilter, columnFilters, table]);

  /**
   * Column handling
   */

  // Close column menu on outside click

  const columnMenuRef = useRef<HTMLDivElement>(null); // ref to the opened column menu
  const openColumnMenuOverlayRef = useRef<HTMLDivElement>(null); // ref to a column menu overlay, e.g. date pickers

  useOutsideClickEffect(
    [
      columnMenuRef,
      openColumnMenuOverlayRef,
      { current: selectedHeaderId ? document.getElementById(selectedHeaderId) : null }, // when clicking the opened header, close it
    ],
    () => setSelectedHeaderId(undefined),
  );

  /**
   * Drag'n'Drop
   */

  const onDragStart = ({ source }: DragStart) => {
    setGrabbedHeaderIndex(source.index);
    setDraggingOverIndex(source.index);
  };

  const onDragUpdate = ({ destination }: DragUpdate) => {
    if (destination == null) {
      setDraggingOverIndex(grabbedHeaderIndex); // set destination to 'where it came from'
    } else if (rowSelectEnabled && destination.index === 0) {
      setDraggingOverIndex(1); // if leftmost col is frozen, don't let it move when being dragged over
    } else {
      setDraggingOverIndex(destination.index);
    }
  };

  const onDragEnd = () => {
    if (draggingOverIndex !== grabbedHeaderIndex) {
      // as column order does not care about column visibility (i.e. it saves order of all columns, visible or not), we need to:
      // step 1: create an array of visible headers and their "full" index (index if every column was visible)
      const visibleHeaders = table
        .getAllLeafColumns()
        .map((c, i) => ({ fullIndex: i, isVisible: c.getIsVisible() }))
        .filter((c) => c.isVisible);
      // step 2: map the dragged, visible from and to indices to their "full" index counterparts
      const draggedFromHeaderIndex = visibleHeaders[grabbedHeaderIndex].fullIndex;
      const draggedToHeaderIndex = visibleHeaders[draggingOverIndex].fullIndex;
      // step 3: reorder the full array with their new positions
      const reorderedIds = reorderArray(
        table.getAllLeafColumns().map((r) => r.id),
        draggedFromHeaderIndex,
        draggedToHeaderIndex,
      );
      // step 4: set the column order
      setColumnOrder(reorderedIds);

      setGrabbedHeaderIndex(-1);
      setDraggingOverIndex(-1);
    }
  };

  // constants relevant for rendering
  const anyFiltersActive = !!globalFilter || columnFilters.length > 0;
  const [needsFillerColumn, setNeedsFillerColumn] = useState<boolean>(false);
  const [gridResizedWidth] = useElementResizeObserver(gridRef.current);
  // set the need for the filler column in case the user resizes the entire window
  useEffect(() => {
    setNeedsFillerColumn(tableTotalSize < gridResizedWidth);
  }, [tableTotalSize, gridResizedWidth]);
  // set the need for the filler column in case the user adds or removes a column, or resizes a column
  useEffect(() => {
    setNeedsFillerColumn(
      gridRef?.current?.clientWidth !== undefined && tableTotalSize < gridRef.current.clientWidth,
    );
  }, [gridRef, tableTotalSize]);

  // the header context needed for the column menu. if no header is selected, return undefined
  const selectedHeaderContext = table
    .getLeafHeaders()
    .find((h) => h.id === selectedHeaderId)
    ?.getContext();

  /**
   * Row selection
   */

  const updateSelectedRows = useCallback(
    (selectAll?: boolean) => {
      if (!useCheckableIds || !checkableIds) {
        // feature disabled / data not provided or loaded yet
        return;
      }

      const toRowSelectionState = (ids: string[]): RowSelectionState =>
        ids.reduce((acc, id) => Object.assign(acc, { [id]: true }), {});

      // Force check all rows
      if (selectAll === true) {
        setRowSelection(toRowSelectionState(checkableIds));
        return;
      }

      // Force uncheck all rows
      if (selectAll === false) {
        setRowSelection({});
        return;
      }

      // "smart" selection of rows by excluding previously unselected
      if (selectAllEnabled) {
        const isNotDeselected = (id: string) => !unselectedIds.includes(id);
        const ids = checkableIds.filter(isNotDeselected);
        setRowSelection(toRowSelectionState(ids));
        return;
      }

      // Normal row selection ("check all" checkbox is disabled): Restrict selected ids to available ids
      const isSelected = (id: string) => Object.keys(rowSelection).includes(id);
      const ids = checkableIds.filter(isSelected);
      setRowSelection(toRowSelectionState(ids));
    },
    [useCheckableIds, selectAllEnabled, checkableIds, rowSelection, unselectedIds],
  );

  // Keep track of explicitly deselected rows
  useEffect(() => {
    // Don't keep track, if we don't use custom checkable ids
    if (!useCheckableIds || !checkableIds) {
      // feature disabled / data not provided or loaded yet
      return;
    }

    // Reset deselected rows if "check all" is disabled
    if (!selectAllEnabled) {
      setUnselectedIds([]);
      return;
    }

    // We don't need to exclude ids, if nothing is selected
    if (Object.keys(rowSelection).length === 0) {
      return;
    }

    const isNotSelected = (id: string) => !Object.keys(rowSelection).includes(id);

    setUnselectedIds(
      uniq([...unselectedIds.filter(isNotSelected), ...checkableIds.filter(isNotSelected)]),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCheckableIds, selectAllEnabled, checkableIds, rowSelection]);

  // Update selected rows on searchTerm or filter change
  useEffect(() => {
    if (!useCheckableIds) {
      return;
    }

    updateSelectedRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCheckableIds, selectAllEnabled, checkableIds]);

  const handleCheckAllChanged = useCallback(
    (): ChangeEventHandler<HTMLInputElement> =>
      ({ target }) => {
        if (!useCheckableIds) {
          table.toggleAllPageRowsSelected(target.checked);
          return;
        }

        setSelectAllEnabled(target.checked);
        updateSelectedRows(target.checked);
      },
    [table, useCheckableIds, updateSelectedRows],
  );

  // Synchronize Tanstack selected ids to an ordered array
  useEffect(() => {
    const rowSelectionUnsorted = Object.keys(rowSelection);

    if (selectAllEnabled) {
      // we don't have any order on "select-all"
      if (!useCheckableIds || !checkableIds) {
        setRowSelectionSorted(rowSelectionUnsorted);
        return;
      }

      setRowSelectionSorted(checkableIds.filter((id) => rowSelectionUnsorted.includes(id)));
      return;
    }

    setRowSelectionSorted(
      (oldValue) =>
        oldValue
          .filter((id) => rowSelectionUnsorted.includes(id)) // remove unselected row(s)
          .concat(rowSelectionUnsorted.filter((id) => !oldValue.includes(id))), // append new selected row(s)
    );
  }, [rowSelection, selectAllEnabled, useCheckableIds, checkableIds]);

  return (
    <>
      {selectedHeaderContext && (
        <ColumnMenu
          headerContext={selectedHeaderContext}
          forwardedRef={columnMenuRef}
          overlayRef={openColumnMenuOverlayRef}
          gridRef={gridRef}
        />
      )}
      <Styled.SearchBarWrapper>
        <Row>
          <Col spaceBelow>
            <InputGroup suffixIcon="search">
              <TextField
                onChange={(event) => {
                  setGlobalFilterValue(event.target.value);
                  onSearch(event.target.value);
                }}
                value={globalFilterValue}
                label={searchBarLabel}
                data-testid="search-bar"
              />
            </InputGroup>
          </Col>
          {rightSideSlot}
        </Row>
      </Styled.SearchBarWrapper>
      <FilterBar
        table={table}
        removeGlobalFilter={() => {
          setGlobalFilter('');
          setGlobalFilterValue('');
        }}
      />
      <Styled.Grid ref={gridRef}>
        <HorizontalGridScrollWrapper
          ref={scrollableRef}
          setSelectedHeaderId={setSelectedHeaderId}
          tableTotalSize={tableTotalSize}
        >
          <Styled.Table id={gridId}>
            <colgroup>
              {table
                .getAllLeafColumns()
                .filter((column) => column.getIsVisible())
                .map((column) => (
                  <col
                    key={column.id}
                    style={{
                      width: Number(column.getSize()),
                    }}
                  />
                ))}
              {needsFillerColumn && <col key="filler" />}
            </colgroup>
            <Styled.TableHeader>
              {/* Note: as we don't group headers, headerGroups is always a 1-length array containing all headers */}
              {table.getHeaderGroups().map((headerGroup) => (
                <DragDropContext
                  key={headerGroup.id}
                  onDragStart={onDragStart}
                  onDragUpdate={onDragUpdate}
                  onDragEnd={onDragEnd}
                >
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(droppableProvided) => (
                      <Styled.TableHeaderRow ref={droppableProvided.innerRef}>
                        {headerGroup.headers.map((header, headerIndex) => {
                          if (header.subHeaders.length > 0) {
                            return (
                              <Styled.TableHeaderGroupCell key={header.id} colSpan={header.colSpan}>
                                {flexRender(header.column.columnDef.header, header.getContext())}
                              </Styled.TableHeaderGroupCell>
                            );
                          }

                          const onClick = (headerId: string) =>
                            setSelectedHeaderId(headerId === selectedHeaderId ? '' : headerId);
                          const isUtilityColumn = ['row_actions', 'row_select'].includes(header.id);

                          const resizeHandle = (
                            <Styled.ResizeHandle
                              cursor={header.column.getCanResize() ? 'col-resize' : 'auto'}
                              onMouseDown={header.getResizeHandler()}
                              onTouchStart={header.getResizeHandler()}
                              isLast={headerIndex === visibleColumns.length - 1}
                            />
                          );

                          // utility cols should be locked (i.e. not wrapped in a Draggable)
                          if (isUtilityColumn) {
                            const renderUtilityColumnHeader = () => {
                              if (header.id === 'row_select') {
                                // "check all" checkbox state
                                const isAllRowsSelected =
                                  checkableIds &&
                                  checkableIds.length > 0 &&
                                  checkableIds.length === Object.keys(rowSelection).length;
                                const isSomeRowsSelected =
                                  !isAllRowsSelected && Object.keys(rowSelection).length > 0;

                                const keySuffix = () => {
                                  if (isAllRowsSelected) {
                                    return '-all';
                                  }

                                  if (isSomeRowsSelected) {
                                    return '-some';
                                  }

                                  return '';
                                };

                                return (
                                  <SelectColumnHeader
                                    key={`${header.id}-${keySuffix()}`}
                                    headerContext={header.getContext()}
                                    onChange={handleCheckAllChanged}
                                    isChecked={useCheckableIds ? isAllRowsSelected : undefined}
                                    isIndeterminate={
                                      useCheckableIds ? isSomeRowsSelected : undefined
                                    }
                                  />
                                );
                              }

                              return (
                                <ColumnHeader
                                  key={header.id}
                                  headerContext={header.getContext()}
                                  isSelected={header.id === selectedHeaderId}
                                  onClick={onClick}
                                />
                              );
                            };

                            return (
                              <Styled.TableHeaderLeafCell
                                key={header.id}
                                id={header.id}
                                colSpan={header.colSpan}
                              >
                                {renderUtilityColumnHeader()}
                                {resizeHandle}
                              </Styled.TableHeaderLeafCell>
                            );
                          }

                          // non-locked cols
                          return (
                            <Draggable key={header.id} draggableId={header.id} index={headerIndex}>
                              {(provided, snapshot) => (
                                <Styled.TableHeaderLeafCell
                                  id={header.id}
                                  ref={provided?.innerRef}
                                  colSpan={header.colSpan}
                                  {...provided?.draggableProps}
                                  isDragging={!!snapshot?.isDragging}
                                  style={
                                    snapshot && provided
                                      ? adjustHeaderStyle(
                                          headerIndex,
                                          snapshot.isDragging,
                                          provided.draggableProps.style,
                                          rowSelectEnabled,
                                          grabbedHeaderIndex,
                                          draggingOverIndex,
                                          table,
                                          visibleColumns[grabbedHeaderIndex]?.id,
                                        )
                                      : {}
                                  }
                                >
                                  <Styled.DragHandle {...provided?.dragHandleProps}>
                                    <ColumnHeader
                                      headerContext={header.getContext()}
                                      isSelected={header.id === selectedHeaderId}
                                      onClick={onClick}
                                    />
                                  </Styled.DragHandle>
                                  {resizeHandle}
                                </Styled.TableHeaderLeafCell>
                              )}
                            </Draggable>
                          );
                        })}
                        {needsFillerColumn &&
                          (headerGroup.headers[0]?.subHeaders.length > 0 ? (
                            <Styled.TableHeaderGroupCell key="filler" />
                          ) : (
                            <Styled.TableHeaderLeafCell key="filler" />
                          ))}
                        {/* The following construct satisfies both the dnd lib that wants the placeholder to be rendered
                            and our desire to hide it while not getting warnings about invalid nesting of table elements. */}
                        <th style={{ display: 'none' }}>
                          <table>
                            <thead>
                              <tr>{droppableProvided.placeholder}</tr>
                            </thead>
                          </table>
                        </th>
                      </Styled.TableHeaderRow>
                    )}
                  </Droppable>
                </DragDropContext>
              ))}
            </Styled.TableHeader>
            <Styled.TableBody>
              {!error && rows.length > 0 ? (
                rows.map((row) => (
                  <Styled.TableRow
                    key={row.id}
                    loading={loading}
                    expanded={row.getIsExpanded()}
                    grouped={row.getIsGrouped()}
                    selected={row.getIsSelected()}
                    someSelected={row.getIsSomeSelected()}
                    clickable={onRowClick !== undefined}
                    onClick={(event) => {
                      if (rowSelectEnabled) {
                        return row.toggleSelected();
                      }
                      // perform the onRowClick event. If it generates a link,
                      // navigate to it now (considering possible keypresses)
                      const link = onRowClick?.(row, event);
                      if (!link) return null;
                      if (event?.ctrlKey || event?.metaKey) {
                        // programatically open in new tab, simulating anchor tag
                        window.open(`${window.location.origin}${link}`);
                        return null;
                      }
                      navigateOrHref(link);
                      return null;
                    }}
                    pinned={row.original.pinned}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <Styled.TableCell key={cell.id} style={{ width: cell.column.getSize() }}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Styled.TableCell>
                    ))}
                    {needsFillerColumn && <Styled.TableCell key="filler" />}
                  </Styled.TableRow>
                ))
              ) : (
                <DataPlaceholders
                  loading={loading}
                  error={error}
                  showDataOnFirstLoad={showDataOnFirstLoad}
                  anyFiltersActive={anyFiltersActive}
                  clearAllFilters={clearAllFilters}
                />
              )}
            </Styled.TableBody>
          </Styled.Table>
        </HorizontalGridScrollWrapper>
        <Styled.GridFooter>
          <Pagination
            canNextPage={table.getCanNextPage()}
            canPreviousPage={table.getCanPreviousPage()}
            gotoPage={table.setPageIndex}
            previousPage={table.previousPage}
            nextPage={table.nextPage}
            pageCount={table.getPageCount()}
            pageIndex={pagination.pageIndex}
            buttonCount={paginationPageButtonCount}
          />
          {footerInfo}
          <PageSizeSelect
            pageSize={pagination.pageSize}
            setPageSize={table.setPageSize}
            pageSizeOptions={GRID_PAGE_SIZE_OPTIONS}
            maxPageSize={maxPageSize}
          />
          <PageSizeInfo
            pageSize={pagination.pageSize}
            loading={loading}
            pageIndex={pagination.pageIndex}
            currentRowCount={rows.length}
            hidePagerInfo={false}
            totalItemCount={totalItemCount}
            totalPageCount={table.getPageCount()}
          />
        </Styled.GridFooter>
      </Styled.Grid>
      {/* DEBUG
      <code style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(table.getState(), null, 2)}</code> */}
    </>
  );
}

export default DataGrid;
