import { FormikState, useFormikContext } from 'formik';
import { useEffect, useMemo, useRef } from 'react';

export type FormEffectProps<Values> = {
  onChange(this: void, state: FormikState<Values>, prevState: FormikState<Values>): void;
};

/**
 * DO NOT USE THIS TO SAVE STATE ELSWHERE!
 * DO NOT USE THIS TO SAVE STATE ELSWHERE!
 * DO NOT USE THIS TO SAVE STATE ELSWHERE!
 * You have been warned!
 *
 * Based on https://github.com/jaredpalmer/formik-effect
 */
export default function FormEffect<Values>({ onChange }: FormEffectProps<Values>) {
  const formikContext = useFormikContext<Values>();
  const formikState: FormikState<Values> = useMemo(
    () => ({
      values: formikContext.values,
      errors: formikContext.errors,
      touched: formikContext.touched,
      isValidating: formikContext.isValidating,
      isSubmitting: formikContext.isSubmitting,
      submitCount: formikContext.submitCount,
    }),
    [formikContext],
  );
  const prevFormikState = useRef<FormikState<Values>>(formikState);

  useEffect(() => {
    onChange(formikState, prevFormikState.current);
    prevFormikState.current = formikState;
  }, [onChange, formikState]);

  return null;
}
