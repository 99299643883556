import subDays from 'date-fns/subDays';
import subWeeks from 'date-fns/subWeeks';
import subMonths from 'date-fns/subMonths';
import subYears from 'date-fns/subYears';
import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';

import useDateInUserTimezone from './useDateInUserTimezone';
import { getItem, setItem } from '../services/storage';
import { STORAGE_KEYS } from '../constants';

export type DatetimeRangeFilter = {
  startDatetime: string;
  endDatetime: string;
};

export type StaticRange = {
  title: ReportsFilters;
  description: string;
  startDatetime: Date;
  endDatetime: Date;
};

export type ReportsFilters =
  | 'today'
  | 'yesterday'
  | 'last7days'
  | 'last30days'
  | 'thisMonth'
  | 'lastMonth'
  | 'last8Weeks'
  | 'currentYear'
  | 'lastYear'
  | 'allTime'
  | 'customRange';

export default function useStaticRangesInUserTimezone() {
  const { createDate } = useDateInUserTimezone();
  const now = createDate('now');
  const firstDayOfThisMonth = subDays(now, now.getUTCDate() - 1);
  const firstDayOfLastMonth = subMonths(firstDayOfThisMonth, 1);
  const lastDayOfThisMonth = subDays(addMonths(firstDayOfThisMonth, 1), 1);
  const lastDayOfLastMonth = subDays(firstDayOfThisMonth, 1);
  const firstDayOfThisYear = subMonths(firstDayOfThisMonth, now.getUTCMonth());
  const lastDayOfThisYear = subDays(addYears(firstDayOfThisYear, 1), 1);
  const firstDayOfLastYear = subYears(firstDayOfThisYear, 1);
  const lastDayOfLastYear = subYears(lastDayOfThisYear, 1);

  const staticRanges: Record<ReportsFilters, StaticRange> = {
    today: {
      title: 'today',
      description: 'today',
      startDatetime: now,
      endDatetime: now,
    },
    yesterday: {
      title: 'yesterday',
      description: 'yesterday',
      startDatetime: subDays(now, 1),
      endDatetime: subDays(now, 1),
    },
    last7days: {
      title: 'last7days',
      description: 'the last 7 days',
      startDatetime: subDays(now, 7),
      endDatetime: now,
    },
    last30days: {
      title: 'last30days',
      description: 'the last 30 days',
      startDatetime: subDays(now, 30),
      endDatetime: now,
    },
    thisMonth: {
      title: 'thisMonth',
      description: 'this month',
      startDatetime: firstDayOfThisMonth,
      endDatetime: lastDayOfThisMonth,
    },
    lastMonth: {
      title: 'lastMonth',
      description: 'last month',
      startDatetime: firstDayOfLastMonth,
      endDatetime: lastDayOfLastMonth,
    },
    last8Weeks: {
      title: 'last8Weeks',
      description: 'the last 8 Weeks',
      startDatetime: subWeeks(now, 8),
      endDatetime: now,
    },
    currentYear: {
      title: 'currentYear',
      description: 'current year',
      startDatetime: firstDayOfThisYear,
      endDatetime: lastDayOfThisYear,
    },
    lastYear: {
      title: 'lastYear',
      description: 'last year',
      startDatetime: firstDayOfLastYear,
      endDatetime: lastDayOfLastYear,
    },
    allTime: {
      title: 'allTime',
      description: 'all time',
      startDatetime: createDate('2014-01-01'),
      endDatetime: now,
    },
    customRange: {
      title: 'customRange',
      description: 'custom range',
      startDatetime: now,
      endDatetime: now,
    },
  };

  const getFilter = (f: ReportsFilters) => staticRanges[f];

  const getStorageFilter = (): StaticRange | null => {
    const filter = getItem(STORAGE_KEYS.reportOverviewDatetimeRangeFilterStorageKey);
    return filter;
  };

  const setStorageFilter = (
    filterTitle: ReportsFilters,
    startDatetime?: Date,
    endDatetime?: Date,
  ) => {
    if (filterTitle === 'customRange') {
      setItem(STORAGE_KEYS.reportOverviewDatetimeRangeFilterStorageKey, {
        title: filterTitle,
        description: 'custom range',
        startDatetime: startDatetime ?? subDays(now, 30),
        endDatetime: endDatetime ?? now,
      });
    } else {
      setItem(STORAGE_KEYS.reportOverviewDatetimeRangeFilterStorageKey, getFilter(filterTitle));
    }
  };

  return {
    staticRanges,
    now,
    setStorageFilter,
    getStorageFilter,
    getFilter,
  };
}
