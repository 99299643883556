import { RateGroup, RateGroupsSubformValues } from '../../components/subforms/RateGroupsSubform';
import { RateGroupSortOrder } from '../../gql/graphql';
import filterRateSummaries from '../../utils/filterRateSummaries';
import isUpsRateLimited from '../../utils/isUpsRateLimited';
import sortRateSummaries from '../../utils/sortRateSummaries';

const getRateGroupsInitialState = (
  rateGroups: readonly RateGroup[],
  sortOrder: RateGroupSortOrder,
) => {
  const initialState: RateGroupsSubformValues = {};

  rateGroups.forEach((rateGroup) => {
    const filteredRateSummaries = filterRateSummaries(rateGroup);
    const sortedAndFilteredRateSummaries = sortRateSummaries(filteredRateSummaries, sortOrder);

    const firstAvailableRate = sortedAndFilteredRateSummaries.find(
      (summary) =>
        summary.errorMessage === null &&
        (!isUpsRateLimited(sortedAndFilteredRateSummaries) || summary.carrier.carrierKey !== 'ups'),
    );

    initialState[rateGroup.groupKey.string] = firstAvailableRate?.uniqueId || '';
  });

  return initialState;
};

export default getRateGroupsInitialState;
