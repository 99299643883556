import styled from '@emotion/styled';
import { ReactElement, ReactNode } from 'react';
import { MEDIA_QUERY } from '../../styles/breakpoints';
import PageTitle from './PageTitle';
import { SPACING } from '../../styles/spacing';

const Styled = {
  PageHeader: styled.header<{ actionsTopOnMobile: boolean; noPaddingBelow?: boolean }>`
    display: flex;
    gap: ${SPACING.md};
    justify-content: space-between;
    align-items: start;
    padding-bottom: ${({ noPaddingBelow }) => (noPaddingBelow ? SPACING.none : SPACING.xxl)};

    @media (max-width: ${MEDIA_QUERY.mdMin}) {
      flex-direction: ${({ actionsTopOnMobile }) =>
        actionsTopOnMobile ? 'column-reverse' : 'column'};
    }

    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      align-items: normal;
    }
  `,
  TooltipWrapper: styled.div`
    display: flex;
    padding-left: ${SPACING.sm};
  `,
  LeftSide: styled.div`
    display: flex;
    flex: 1;
    align-items: baseline;
  `,
  RightSide: styled.div`
    display: flex;
    gap: ${SPACING.md};

    @media (max-width: ${MEDIA_QUERY.mdMin}) {
      width: 100%;
      > button {
        flex: 1;
      }
    }

    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      flex-wrap: nowrap;
      flex-direction: column;
      > button {
        flex: none;
      }
    }
  `,
};

export type PageHeaderProps = {
  title: string | ReactElement;
  tooltip?: ReactNode;
  rightSideActions?: ReactNode[];
  actionsTopOnMobile?: boolean;
  noPaddingBelow?: boolean;
};

export default function PageHeader({
  title,
  tooltip,
  rightSideActions,
  actionsTopOnMobile = false,
  noPaddingBelow = false,
}: PageHeaderProps) {
  return (
    <Styled.PageHeader actionsTopOnMobile={actionsTopOnMobile} noPaddingBelow={noPaddingBelow}>
      <Styled.LeftSide>
        {typeof title === 'string' ? <PageTitle>{title}</PageTitle> : title}
        {tooltip && <Styled.TooltipWrapper>{tooltip}</Styled.TooltipWrapper>}
      </Styled.LeftSide>
      {rightSideActions && <Styled.RightSide>{rightSideActions}</Styled.RightSide>}
    </Styled.PageHeader>
  );
}
