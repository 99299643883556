import styled from '@emotion/styled';
import { getIn, useFormikContext } from 'formik';
import { useId } from 'react';
import { Hidden } from 'react-grid-system';
import packageTypeIcons, { PackageTypeIconKeys } from '../../assets/packageTypeIcons';
import { GREYSCALE } from '../../styles/colors';
import { Fieldset, Legend } from '../Fieldset';
import DropdownSelect from '../form/DropdownSelect';
import { MultiFieldErrorMessage } from '../form/ErrorMessage';
import FormControl from '../form/FormControl';
import Label from '../form/Label';
import TextField from '../form/TextField';
import { Col, Row } from '../layout/Grid';
import { NamespacedSubform } from './types';

const Styled = {
  Operator: styled.p`
    margin: 0;
    padding: 15px 0;
    line-height: 20px;
    text-align: center;
    color: ${GREYSCALE.grey50};
  `,
};

export type PackageType = {
  readonly id: string;
  readonly title: string;
  readonly description: string;
  readonly packageTypeKey: string;
  readonly heightRequired: boolean;
  readonly weightRequired: boolean;
  readonly dimensionsRequired: boolean;
};

export type PackageSubformProps = {
  packageTypes: readonly PackageType[];
};

export type PackageSubformValues = {
  packageTypeKey: string;
  dimensionX?: string; // strings because we allow fractions, e.g. 1 1/2
  dimensionY?: string;
  dimensionZ?: string;
  weightPounds: number | '';
  weightOunces: number | '';
  // fake fields that do not contain a value but are used for multi-field validation
  combinedDimensions?: unknown;
  combinedWeight?: unknown;
};

export default function PackageSubform<NS extends string>({
  namespace,
  packageTypes,
}: NamespacedSubform<NS> & PackageSubformProps) {
  const { values, touched, errors } = useFormikContext<{
    package: PackageSubformValues;
  }>();
  const { packageTypeKey } = getIn(values, namespace) satisfies PackageSubformValues;
  const packageErrors = getIn(errors, namespace) satisfies PackageSubformValues | undefined;
  const packageTouched = getIn(touched, namespace) satisfies PackageSubformValues | undefined;
  const packageTypeKeyId = useId();

  const packageTypeOptions =
    packageTypes?.map((packageType) => ({
      value: packageType.packageTypeKey,
      title: packageType.title,
      description: packageType.description,
      iconUrl: packageTypeIcons[packageType.packageTypeKey as PackageTypeIconKeys],
    })) || [];

  const { heightRequired, dimensionsRequired, weightRequired } = (() => {
    const thisPackageType = packageTypes?.find(
      (packageType) => packageType.packageTypeKey === packageTypeKey,
    );

    const parcelPackageType = packageTypes?.find(
      (packageType) => packageType.packageTypeKey === 'Parcel',
    ); // if package type is not found, reset to standard parcel

    return (
      thisPackageType ??
      parcelPackageType ?? { heightRequired: true, dimensionsRequired: true, weightRequired: true } // if the requirement options for the standard parcel have not been given via props, use this sane local default
    );
  })();

  // Show form-level error only if all fields were touched and don't have
  // field-level errors themselves
  const showDimensionsError =
    !!packageErrors?.combinedDimensions &&
    !!packageTouched?.dimensionX &&
    !!packageTouched?.dimensionY &&
    (heightRequired || !!packageTouched?.dimensionZ) &&
    !packageErrors?.dimensionX &&
    !packageErrors?.dimensionY &&
    !packageErrors?.dimensionZ;
  const showWeightError =
    !!packageErrors?.combinedWeight &&
    !!packageTouched?.weightPounds &&
    !!packageTouched?.weightOunces &&
    !packageErrors?.weightPounds &&
    !packageErrors?.weightOunces;

  return (
    <>
      <Row>
        <Col spaceBelow>
          <Label htmlFor={packageTypeKeyId}>Type of Packaging</Label>
          <FormControl
            name={`${namespace}.packageTypeKey`}
            as={DropdownSelect}
            options={packageTypeOptions}
            id={packageTypeKeyId}
            loading={!packageTypes}
            loadingHeight={158}
            aria-selected
          />
        </Col>
      </Row>

      {/* treat this like a subform */}
      {dimensionsRequired && (
        <Fieldset>
          <Legend>Package Dimensions (Inches)</Legend>
          <Row>
            <Col spaceBelow xs={heightRequired ? 4 : 6} md={3}>
              <FormControl
                name={`${namespace}.dimensionX`}
                as={TextField}
                label="Length"
                center
                // We override error here to incorporate the form-level error
                error={
                  (packageTouched?.dimensionX && packageErrors?.dimensionX !== undefined) ||
                  showDimensionsError
                }
              />
            </Col>
            <Hidden xs sm>
              <Col spaceBelow>
                <Styled.Operator>&times;</Styled.Operator>
              </Col>
            </Hidden>
            <Col spaceBelow xs={heightRequired ? 4 : 6} md={3}>
              <FormControl
                name={`${namespace}.dimensionY`}
                as={TextField}
                label="Width"
                center
                // We override error here to incorporate the form-level error
                error={
                  (packageTouched?.dimensionY && packageErrors?.dimensionY !== undefined) ||
                  showDimensionsError
                }
              />
            </Col>
            {heightRequired ? (
              <>
                <Hidden xs sm>
                  <Col spaceBelow>
                    <Styled.Operator>&times;</Styled.Operator>
                  </Col>
                </Hidden>
                <Col spaceBelow xs={heightRequired ? 4 : 6} md={3}>
                  <FormControl
                    name={`${namespace}.dimensionZ`}
                    as={TextField}
                    label="Height"
                    center
                    // We override error here to incorporate the form-level error
                    error={
                      (packageTouched?.dimensionZ && packageErrors?.dimensionZ !== undefined) ||
                      showDimensionsError
                    }
                  />
                </Col>
              </>
            ) : (
              <Hidden xs sm>
                <Col spaceBelow xs={6} md={4.5} />
              </Hidden>
            )}
            {showDimensionsError && (
              <Col spaceBelow md={heightRequired ? 12 : 7.5}>
                <MultiFieldErrorMessage>{packageErrors.combinedDimensions}</MultiFieldErrorMessage>
              </Col>
            )}
          </Row>
        </Fieldset>
      )}

      {/* treat this like a subform */}
      {weightRequired && (
        <Fieldset>
          <Legend>Package Weight</Legend>
          <Row>
            <Col spaceBelow xs={6} md={3}>
              <FormControl
                name={`${namespace}.weightPounds`}
                as={TextField}
                type="number"
                label="Pounds"
                center
                // We override error here to incorporate the form-level error
                error={
                  (packageTouched?.weightPounds && packageErrors?.weightPounds !== undefined) ||
                  showWeightError
                }
              />
            </Col>
            <Hidden xs sm>
              <Col spaceBelow>
                <Styled.Operator>+</Styled.Operator>
              </Col>
            </Hidden>
            <Col spaceBelow xs={6} md={3}>
              <FormControl
                name={`${namespace}.weightOunces`}
                as={TextField}
                type="number"
                label="Ounces"
                center
                // We override error here to incorporate the form-level error
                error={
                  (packageTouched?.weightOunces && packageErrors?.weightOunces !== undefined) ||
                  showWeightError
                }
              />
            </Col>
            {/* the following col aligns the weight fields with the dimension fields */}
            <Hidden xs sm>
              <Col spaceBelow xs={6} md={4.5} />
            </Hidden>
            {showWeightError && (
              <>
                <Col spaceBelow md={7.5}>
                  <MultiFieldErrorMessage>{packageErrors?.combinedWeight}</MultiFieldErrorMessage>
                </Col>
                {/* the following col aligns the weight error message with the weight fields */}
                <Hidden xs sm>
                  <Col spaceBelow md={4.5} />
                </Hidden>
              </>
            )}
          </Row>
        </Fieldset>
      )}
    </>
  );
}
