import { useState, RefObject } from 'react';
import convertObjectToUrlSearchParams from '../utils/convertObjectToUrlSearchParams';
import CloudflareMitigatedError from '../components/pages/loggedout/turnstile/CloudflareMitigatedError';
import { TurnstileWidgetRef } from '../components/pages/loggedout/turnstile/TurnstileWidget';

type XhrHandlerParams = {
  method: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';
  url: string;
  attrs: Record<string, unknown>;
};

export default function useCloudflareChallengeHandlingXhr<ResponseValues>(
  turnstileRef?: RefObject<TurnstileWidgetRef>,
) {
  const [loading, setLoading] = useState(false);

  const withToken = (attrs: Record<string, unknown>, token?: string) => {
    const turnstileToken = token ?? turnstileRef?.current?.currentToken();

    return turnstileToken ? { ...attrs, turnstileToken } : attrs;
  };

  async function doRequest({ method, url, attrs }: XhrHandlerParams) {
    return fetch(url, {
      method,
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: convertObjectToUrlSearchParams(attrs),
    });
  }

  async function handler({ method, url, attrs }: XhrHandlerParams) {
    setLoading(true);

    let response = await doRequest({ url, method, attrs: withToken(attrs) });

    try {
      if (response.headers.get('cf-mitigated') === 'challenge') {
        response = await new Promise((resolve) => {
          if (!turnstileRef?.current) {
            throw new CloudflareMitigatedError('Challenge must be solved');
          }

          turnstileRef.current.execute(
            (token: string) => {
              resolve(doRequest({ url, method, attrs: withToken(attrs, token) }));
            },
            (error: string) => {
              throw new Error(error);
            },
          );
        });
      }
    } finally {
      setLoading(false);
    }

    if (!response.ok) {
      throw new Error('Something went wrong!');
    }

    return (await response.json()) as ResponseValues;
  }

  return { handler, loading };
}
