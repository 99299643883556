import styled from '@emotion/styled';
import {
  AppearanceMode,
  Turnstile,
  TurnstileInstance,
  TurnstileProps,
  WidgetSize,
} from '@marsidev/react-turnstile';
import {
  CSSProperties,
  forwardRef,
  Ref,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SPACING } from '../../../../styles/spacing';

const Styled = {
  Widget: styled.div<{ visible: boolean }>`
    margin-bottom: ${({ visible }) => (!visible ? 'none' : SPACING.xl)};
    height: ${({ visible }) => (!visible ? '0' : 'auto')};
  `,
};

export type TurnstileWidgetRef = {
  execute: (resolve?: TurnstileProps['onSuccess'], reject?: TurnstileProps['onError']) => void;
  currentToken: () => string | undefined;
};

export type TurnstileWidgetProps = Pick<
  TurnstileProps,
  'onWidgetLoad' | 'onSuccess' | 'onError'
> & {
  siteKey?: string;
  action?: string;
  appearance?: AppearanceMode;
  size?: WidgetSize;
  autoExecute?: boolean;
  onChallenge?: () => void;
};

function TurnstileWidgetComponent(
  {
    siteKey,
    action,
    appearance = 'interaction-only',
    size = 'flexible',
    autoExecute,
    onWidgetLoad,
    onSuccess,
    onError,
    onChallenge,
  }: TurnstileWidgetProps,
  ref?: Ref<TurnstileWidgetRef>,
) {
  const [visible, setVisible] = useState(appearance !== 'interaction-only' && !!autoExecute);
  const turnstileRef = useRef<TurnstileInstance>(null);
  const onSuccessRef = useRef<TurnstileProps['onSuccess']>(onSuccess);
  const onErrorRef = useRef<TurnstileProps['onError']>(onError);

  const style = useMemo<CSSProperties | undefined>(() => {
    // also fill horizontal space in interaction only appearance
    if (appearance === 'interaction-only' && size === 'flexible') {
      return {
        width: '100%',
        display: 'inherit',
        height: 65,
      };
    }

    return undefined;
  }, [appearance, size]);

  const handleWidgetLoad = useCallback(
    (widgetId: string) => {
      onWidgetLoad?.(widgetId);
      if (autoExecute) {
        onChallenge?.();
      }
    },
    [onWidgetLoad, autoExecute, onChallenge],
  );

  const handleSuccess = useCallback(
    (token: string) => {
      onSuccessRef.current?.(token);
      onSuccessRef.current = onSuccess;
    },
    [onSuccess],
  );

  const handleError = useCallback(
    (error: string) => {
      onErrorRef.current?.(error);
      onErrorRef.current = onError;
    },
    [onError],
  );

  useImperativeHandle(
    ref,
    () => ({
      execute: (resolve, reject) => {
        onSuccessRef.current = resolve ?? onSuccess;
        onErrorRef.current = reject ?? onError;

        if (!turnstileRef.current) {
          throw new Error('Could not find Turnstile instance');
        }

        if (appearance !== 'interaction-only') {
          setVisible(true);
        }

        turnstileRef.current.reset();
        onChallenge?.();
        turnstileRef.current.execute();
      },
      currentToken: () => {
        if (!turnstileRef.current) {
          return undefined;
        }

        return turnstileRef.current.getResponse();
      },
    }),
    [onSuccess, onError, appearance, setVisible],
  );

  return (
    <Styled.Widget visible={visible}>
      <Turnstile
        ref={turnstileRef}
        siteKey={siteKey ?? import.meta.env.REACT_APP_CF_TURNSTILE_PUBLIC_KEY}
        options={{
          size,
          theme: 'light',
          appearance,
          action,
          responseField: false,
          execution: autoExecute ? 'render' : 'execute',
        }}
        onWidgetLoad={handleWidgetLoad}
        onSuccess={handleSuccess}
        onError={handleError}
        onBeforeInteractive={() => appearance === 'interaction-only' && setVisible(true)}
        style={style}
      />
    </Styled.Widget>
  );
}

const TurnstileWidget = forwardRef(TurnstileWidgetComponent);
export default TurnstileWidget;
