import { ButtonHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import withOpacity from '../../utils/withOpacity';
import { COLOR, GREYSCALE } from '../../styles/colors';
import { TYPOGRAPHY } from '../../styles/typography';
import { MEDIA_QUERY } from '../../styles/breakpoints';
import { BORDER_WIDTH, BORDER_RADIUS } from '../../styles/borders';
import { SPACING } from '../../styles/spacing';
import { OPACITY } from '../../styles/opacities';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'danger'
  | 'dark';
export type ButtonSize = 'small' | 'medium' | 'large' | 'xLarge';

// props needed for any elements that look like buttons (e.g. buttons, but also links in buttons' clothing)
export type ButtonStyleProps = {
  size?: ButtonSize;
  variant?: ButtonVariant;
  fullWidth?: boolean;
  noWrap?: boolean;
  additionalLabel?: string;
  hideForPrint?: boolean;
};

// props needed for our standard button
export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & ButtonStyleProps;

const Button = styled.button<ButtonProps>`
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
  width: ${(props) => (props.fullWidth ? '100%' : '')};
  margin: ${SPACING.none};
  border: ${BORDER_WIDTH.sm} solid transparent;
  text-align: center;
  vertical-align: middle;
  white-space: ${(props) => (props.noWrap ? 'nowrap' : 'normal')};
  text-overflow: ellipsis;
  user-select: none;
  cursor: pointer;
  @media print {
    display: ${({ hideForPrint }) => (hideForPrint ? 'none' : 'inline')};
  }
  :focus-within {
    outline-color: ${COLOR.blue};
  }
  /* Button sizes */
  ${({ size = 'medium' }) => {
    switch (size) {
      case 'small':
        return css`
          height: 28px;
          font-size: ${TYPOGRAPHY.fontSize.sm};
          font-weight: ${TYPOGRAPHY.fontWeight.regular};
          padding: 0 10px;
          border-width: ${BORDER_WIDTH.xs};
          border-radius: ${BORDER_RADIUS.xs};
        `;
      case 'medium':
        return css`
          height: 50px;
          font-size: ${TYPOGRAPHY.fontSize.md};
          font-weight: ${TYPOGRAPHY.fontWeight.regular};
          padding: 2px 9px ${SPACING.none};
          border-width: ${BORDER_WIDTH.sm};
          border-radius: ${BORDER_RADIUS.sm};
        `;
      case 'large':
        return css`
          height: 83px;
          font-size: ${TYPOGRAPHY.fontSize.xl};
          font-weight: ${TYPOGRAPHY.fontWeight.medium};
          padding: ${SPACING.none} 25px;
          border-width: ${BORDER_WIDTH.lg};
          border-radius: ${BORDER_RADIUS.lg};

          @media (min-width: ${MEDIA_QUERY.smMin}) {
            min-width: 218px;
          }
        `;
      case 'xLarge':
        return css`
          height: 100px;
          font-size: ${TYPOGRAPHY.fontSize.xxl};
          font-weight: ${TYPOGRAPHY.fontWeight.bold};
          padding: ${SPACING.none} 36px;
          border-width: ${BORDER_WIDTH.lg};
          border-radius: ${BORDER_RADIUS.lg};

          @media (min-width: ${MEDIA_QUERY.smMin}) {
            min-width: 320px;
          }
        `;
      default:
        // If the exhaustiveCheck line shows an error, the switch is missing a case
        // eslint-disable-next-line no-case-declarations
        const exhaustiveCheck: string = size;

        // This should never happen
        throw new Error(`Unhandled size case ${exhaustiveCheck}`);
    }
  }}

  /* Button variants */
  ${({ variant = 'primary' }) => {
    switch (variant) {
      case 'primary':
      case 'info':
        return css`
          background-color: ${COLOR.blue};
          border-color: ${COLOR.blueButtonAccent};
          color: ${GREYSCALE.white};
          :hover,
          :active,
          :focus {
            background-color: ${COLOR.blueButtonAccent};
          }
        `;
      case 'secondary':
        return css`
          background-color: ${GREYSCALE.grey20};
          border-color: ${COLOR.greyButtonAccent};
          color: ${GREYSCALE.grey60};

          :hover,
          :active,
          :focus {
            background-color: ${COLOR.greyButtonAccent};
          }
        `;
      case 'success':
        return css`
          background-color: ${COLOR.green};
          border-color: ${COLOR.greenButtonAccent};
          color: ${GREYSCALE.white};

          :hover,
          :active,
          :focus {
            background-color: ${COLOR.greenButtonAccent};
          }
        `;
      case 'warning':
        return css`
          background-color: ${COLOR.yellow};
          border-color: ${COLOR.yellowButtonAccent};
          color: ${GREYSCALE.white};

          :hover,
          :active,
          :focus {
            background-color: ${COLOR.yellowButtonAccent};
          }
        `;
      case 'danger':
        return css`
          background-color: ${COLOR.red};
          border-color: ${COLOR.redButtonAccent};
          color: ${GREYSCALE.white};

          :hover,
          :active,
          :focus {
            background-color: ${COLOR.redButtonAccent};
          }
        `;
      case 'dark':
        return css`
          background-color: ${GREYSCALE.grey80};
          border-color: ${GREYSCALE.black};
          color: ${GREYSCALE.grey10};

          :hover,
          :active,
          :focus {
            background-color: ${GREYSCALE.grey60};
          }
        `;
      default:
        // If the exhaustiveCheck line shows an error, the switch is missing a case
        // eslint-disable-next-line no-case-declarations
        const exhaustiveCheck: string = variant;

        // This should never happen
        throw new Error(`Unhandled variant case ${exhaustiveCheck}`);
    }
  }}

  :active {
    box-shadow: inset ${SPACING.none} ${SPACING.xs} ${SPACING.sm}
      ${withOpacity(GREYSCALE.black, 0.125)};
  }

  :disabled {
    opacity: ${OPACITY.disabled};
    cursor: not-allowed;
  }

  ${({ additionalLabel }) => {
    if (additionalLabel) {
      return css`
        ::after {
          content: '${additionalLabel}';
          position: absolute;
          top: -${SPACING.md};
          right: ${SPACING.xl};
          padding: ${SPACING.xs} ${SPACING.sm};
          border-radius: ${BORDER_RADIUS.xxs};
          background: ${GREYSCALE.black};
          color: ${GREYSCALE.white};
          text-transform: uppercase;
          font-size: ${TYPOGRAPHY.fontSize.xs};
          font-weight: ${TYPOGRAPHY.fontWeight.medium};
        }
      `;
    }

    return undefined;
  }}
`;

export default Button;
