import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { SPACING } from '../../../styles/spacing';
import LandlubberContent from '../../layout/LandlubberContent';
import FormControl from '../../form/FormControl';
import OneTimePassword from '../../form/OneTimePassword';
import Label from '../../form/Label';
import { Col, Row } from '../../layout/Grid';
import PageTitle from '../../layout/PageTitle';
import ProgressButton from '../../form/ProgressButton';
import convertObjectToUrlSearchParams from '../../../utils/convertObjectToUrlSearchParams';
import useNavigateOrHref from '../../../hooks/useNavigateOrHref';
import useVerificationCodeErrorHandler from '../../../hooks/useVerificationCodeErrorHandler';
import OneTimePasswordSchema from '../../../validation/OneTimePasswordSchema';
import StaySignedInCheckbox from './login/StaySignedInCheckbox';
import { LinkButton } from '../../Link';

type TwoFactorAuthenticationChallengePageProps = {
  provider: 'APP' | 'EMAIL';
  rememberLogin?: boolean;
  hasMultipleProviders?: boolean;
};

export default function TwoFactorAuthenticationChallengePage({
  provider: initialProvider,
  rememberLogin,
  hasMultipleProviders = false,
}: TwoFactorAuthenticationChallengePageProps) {
  const form = useRef<HTMLFormElement | null>(null);
  const [loading, setLoading] = useState(false);
  const navigateOrHref = useNavigateOrHref();
  const { setError, clearError } = useVerificationCodeErrorHandler();
  const [provider, setProvider] = useState(initialProvider);
  const [providingCode, setProvidingCode] = useState(false);

  const Styled = {
    PageTitle: styled(PageTitle)`
      margin-bottom: ${SPACING.xl};
    `,
  };

  return (
    <LandlubberContent>
      <Formik
        initialValues={{ code: '', remember: rememberLogin ?? false }}
        validationSchema={() =>
          yup.object({
            code: OneTimePasswordSchema,
            remember: yup.boolean().defined(),
          })
        }
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={async ({ code, remember }) => {
          clearError();
          setLoading(true);
          const response = await fetch('/user/two-factor-authentication/confirm', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'X-Requested-With': 'XMLHttpRequest', // make it an ajax request
            },
            body: convertObjectToUrlSearchParams({ code, remember, provider }),
          });
          setLoading(false);

          if (!response.ok) {
            setError('general_error');
            return;
          }

          const responseData = (await response.json()) as { redirect?: string; error?: string };

          if (responseData.redirect) {
            navigateOrHref(responseData.redirect);
          } else {
            setError(responseData.error ?? 'general_error');
          }
        }}
      >
        <Form method="POST" action="/user/two-factor-authentication/confirm" ref={form} noValidate>
          <Row spaceBelow>
            <Col>
              <Styled.PageTitle>
                {provider === 'APP' && 'Enter authentication app code'}
                {provider === 'EMAIL' && 'Check your email'}
              </Styled.PageTitle>
              <p>
                {provider === 'APP' &&
                  'Please confirm access to your account by entering the verification code provided by your authentication app.'}
                {provider === 'EMAIL' &&
                  'Please confirm access to your account by entering the verification code emailed to you.'}
              </p>
            </Col>
          </Row>
          <Row spaceBelow justify="center">
            <Col>
              <Label htmlFor="one-time-password-field">Verification Code</Label>
              <FormControl id="one-time-password-field" name="code" as={OneTimePassword} />
            </Col>
          </Row>
          <Row spaceBelow>
            <Col>
              <ProgressButton
                type="submit"
                fullWidth
                noWrap
                size="xLarge"
                progress={loading}
                disabled={loading || providingCode}
              >
                Yarrrr, log me in
              </ProgressButton>
            </Col>
          </Row>
          <Row spaceBelow>
            <Col xs={12}>
              <StaySignedInCheckbox />
            </Col>
          </Row>
          {hasMultipleProviders && (
            <Row>
              <Col xs={12}>
                <LinkButton
                  disabled={providingCode}
                  data-testid="2fa-toggle-provider"
                  onClick={async () => {
                    const fallbackProvider = provider === 'APP' ? 'EMAIL' : 'APP';
                    clearError();
                    setProvidingCode(true);
                    const response = await fetch('/user/two-factor-authentication/providecode', {
                      method: 'POST',
                      cache: 'no-cache',
                      headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'X-Requested-With': 'XMLHttpRequest', // make it an ajax request
                      },
                      body: convertObjectToUrlSearchParams({ provider: fallbackProvider }),
                    });
                    setProvidingCode(false);

                    setProvider(fallbackProvider);

                    if (!response.ok) {
                      setError('general_error');
                    }
                  }}
                >
                  Use {provider === 'EMAIL' ? 'authentication app' : 'email'} verification instead.
                </LinkButton>
              </Col>
            </Row>
          )}
        </Form>
      </Formik>
    </LandlubberContent>
  );
}
