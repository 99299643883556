import add from 'date-fns/add';
import * as yup from 'yup';
import { BatchQuery } from '../../../gql/graphql';
import delayStringToDuration from '../../../utils/delayStringToDuration';
import newDate from '../../../utils/newDate';
import roundToQuarterHour from '../../../utils/roundToQuarterHour';
import { NotifyRecipientsSettingRowValues } from './NotifyRecipientsSettingRow';

export const notifyRecipientsSettingRowValidationSchema = () =>
  // emailTemplate is only required, when sending is enabled See performBuy
  yup.object<NotifyRecipientsSettingRowValues>({
    enabled: yup.boolean().required(),
    date: yup.date(),
  });

export const notifyRecipientsSettingRowInitialValues = ({
  defaultTrackingEmailsEnabled,
  defaultTrackingEmailsDelay,
  defaultEmailTemplateId,
  userTimezoneMinutesOffset,
  mailTemplates,
}: {
  defaultTrackingEmailsEnabled: boolean;
  defaultTrackingEmailsDelay: string;
  defaultEmailTemplateId: string;
  userTimezoneMinutesOffset: number;
  mailTemplates: BatchQuery['company']['mailTemplates'];
}) => ({
  enabled: defaultTrackingEmailsEnabled,
  emailTemplate: (() => {
    // Make sure we have a usable default email template
    const defaultEmailTemplate = mailTemplates.find(({ id }) => id === defaultEmailTemplateId);
    if (defaultEmailTemplate === undefined) {
      return mailTemplates[0]?.id ?? '';
    }

    return defaultEmailTemplateId;
  })(),
  date: (() => {
    const trackingEmailsDelay = delayStringToDuration(defaultTrackingEmailsDelay);
    const todayLocalNow = add(newDate('now'), {
      minutes: userTimezoneMinutesOffset,
    });
    return roundToQuarterHour(add(todayLocalNow, trackingEmailsDelay));
  })(),
});
