import { useState, useEffect, useRef, useId, useCallback } from 'react';
import styled from '@emotion/styled';
import { COLOR, GREYSCALE } from '../../../../styles/colors';
import { TYPOGRAPHY } from '../../../../styles/typography';
import useOutsideClickEffect from '../../../../hooks/useOutsideClickEffect';
import DatetimeRange from '../../../DatetimeRange';
import Shimmer from '../../../loading/Shimmer';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../../../styles/borders';
import { DATE_FORMAT } from '../../../../constants';
import useDateInUserTimezone from '../../../../hooks/useDateInUserTimezone';
import Icon from '../../../Icon';
import useStaticRangesInUserTimezone, {
  StaticRange,
  ReportsFilters,
} from '../../../../hooks/useStaticRangesInUserTimezone';
import { SPACING } from '../../../../styles/spacing';

export type ReportsFilterProps = {
  loading?: boolean;
  accountCreated?: Date;
  filterInUse: ReportsFilters;
  setFilterInUse: (title: ReportsFilters) => void;
  customRangeStartDate: Date;
  setCustomRangeStartDate: (startDatetime: Date) => void;
  customRangeEndDate: Date;
  setCustomRangeEndDate: (endDatetime: Date) => void;
};

type FilterWrapperProps = {
  customRange: boolean;
};

const Styled = {
  ReportHeaderWrapper: styled.div`
    display: flex;
    align-items: baseline;
  `,
  ReportFilterWrapper: styled.div`
    position: relative;
    display: flex;
    padding-left: 10px;
    color: ${COLOR.blue};
    font-size: ${TYPOGRAPHY.fontSize.xl};
  `,
  FilterToggle: styled.button`
    color: inherit;
    font-size: inherit;
    background: none;
    padding: 0 0 3px;
    cursor: pointer;
    border: none;
    &:focus-visible {
      outline: 2px solid ${COLOR.blue};
      outline-offset: 4px;
      border-radius: ${BORDER_RADIUS.xxs};
    }
  `,
  FilterWrapper: styled('div')<FilterWrapperProps>`
    position: absolute;
    z-index: 20;
    left: ${(props) => (props.customRange ? '-200px' : '20px')};
    top: 30px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
  `,
  CustomRangeWrapper: styled.div`
    display: flex;
    width: 500px;
    background-color: ${GREYSCALE.white};
    border-radius: ${BORDER_RADIUS.none};
    border-top: ${BORDER_WIDTH.xs} solid ${GREYSCALE.grey30};
    border-bottom: ${BORDER_WIDTH.xs} solid ${GREYSCALE.grey30};
    border-right: ${BORDER_WIDTH.xs} solid ${GREYSCALE.grey30};
    background-color: ${GREYSCALE.white};
    border-top-right-radius: ${BORDER_RADIUS.xs};
    border-bottom-right-radius: ${BORDER_RADIUS.xs};
  `,
  FilterItem: styled('button')`
    box-sizing: border-box;
    border: none;
    display: block;
    width: 100%;
    text-align: left;
    font-size: ${TYPOGRAPHY.fontSize.xs};
    line-height: 1em;
    padding: 8px 12px;
    color: ${(props) => (props['aria-selected'] ? GREYSCALE.white : GREYSCALE.black)};
    background-color: ${(props) => (props['aria-selected'] ? COLOR.blue : 'transparent')};
    cursor: pointer;
    &:hover,
    &:focus-visible {
      background-color: ${(props) => (props['aria-selected'] ? COLOR.blue : GREYSCALE.grey20)};
    }
    &:focus-visible {
      outline: 2px solid ${(props) => (props['aria-selected'] ? GREYSCALE.white : COLOR.blue)};
      outline-offset: ${(props) => (props['aria-selected'] ? '-4px' : '-2px')};
    }
  `,
  FilterIconCalendar: styled(Icon)`
    padding-right: 10px;
  `,
  FilterIconCaretDown: styled(Icon)`
    padding-left: 5px;
  `,
  FilterItemsWrapper: styled('div')<FilterWrapperProps>`
    width: 140px;
    border-radius: ${(props) =>
      props.customRange
        ? `${BORDER_RADIUS.sm} ${BORDER_RADIUS.none} ${BORDER_RADIUS.none} ${BORDER_RADIUS.sm}`
        : `${BORDER_RADIUS.sm}`};
    border: ${BORDER_WIDTH.xs} solid ${GREYSCALE.grey30};
    background-color: ${GREYSCALE.white};
    &::before {
      position: absolute;
      display: inline-block;
      top: -7px;
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
      border-bottom: 7px solid ${GREYSCALE.grey30};
    }
    padding-inline-start: ${SPACING.none};
    margin-block: ${SPACING.none};
    li {
      list-style-type: '';
    }
  `,
};

export default function ReportsFilter({
  filterInUse,
  setFilterInUse,
  customRangeStartDate,
  setCustomRangeStartDate,
  customRangeEndDate,
  setCustomRangeEndDate,
  accountCreated,
  loading = false,
}: ReportsFilterProps) {
  const { setStorageFilter, staticRanges: sr, getFilter } = useStaticRangesInUserTimezone();
  const staticRanges = Object.values(sr);
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showCustomRange, setShowCustomRange] = useState(false);
  // Close filter menu on outside click
  const openReportFilterWrapperRef = useRef<HTMLDivElement>(null);
  useOutsideClickEffect([openReportFilterWrapperRef], () => setOpen(false));
  const { createDate, formatDate } = useDateInUserTimezone();
  const filterOpener = useId();
  const filterMenu = useId();
  const customRangePicker = useId();
  const filterButton = document.getElementById(filterOpener);
  const customRangeCalendar = document.getElementById(customRangePicker);

  useEffect(() => {
    setShowCustomRange(filterInUse === 'customRange');

    if (filterInUse === 'customRange') {
      setStorageFilter(filterInUse, customRangeStartDate, customRangeEndDate);
    } else {
      setStorageFilter(filterInUse);
    }
  }, [customRangeEndDate, customRangeStartDate, filterInUse, setStorageFilter]);

  // captures up/down/enter/escape keys
  const handleSelection = useCallback(
    (event: KeyboardEvent) => {
      // prevent key presses from being captured if the filters are hidden
      if (!open) return;

      const { key } = event;
      const arrowUpPressed = key === 'ArrowUp';
      const arrowDownPressed = key === 'ArrowDown';
      const escapePressed = key === 'Escape' || key === 'Esc';

      const staticRangesLength = staticRanges.length - 1;

      // increments active index (wraps around when at top)
      if (arrowUpPressed) {
        event.preventDefault();
        setActiveIndex((currentIndex) =>
          currentIndex - 1 >= 0 ? currentIndex - 1 : staticRangesLength,
        );
        return;
      }

      // decrements active index (wraps around when at bottom)
      if (arrowDownPressed) {
        event.preventDefault();
        setActiveIndex((currentIndex) =>
          currentIndex + 1 <= staticRangesLength ? currentIndex + 1 : 0,
        );
        return;
      }

      // closes results
      if (escapePressed) {
        event.preventDefault();
        // setActiveIndex(0);
        setOpen(false);
        filterButton?.focus();
      }
    },
    [filterButton, open, staticRanges.length],
  );

  // globally listens for key presses
  useEffect(() => {
    document.addEventListener('keydown', handleSelection);

    return () => {
      document.removeEventListener('keydown', handleSelection);
    };
  }, [handleSelection]);

  // when the active selection changes, it focuses on the active element
  useEffect(() => {
    if (open) {
      const activeElement = document.querySelector(`[data-index='${activeIndex}']`) as HTMLElement;

      if (activeElement) activeElement.focus();
    }
  }, [open, activeIndex]);

  if (loading) {
    return <Shimmer.RoundedRectangle style={{ marginTop: '-0.5em' }} width={200} />;
  }

  return (
    <Styled.ReportHeaderWrapper>
      <Styled.ReportFilterWrapper ref={openReportFilterWrapperRef}>
        <Styled.FilterIconCalendar icon="calendar" />
        <Styled.FilterToggle
          role="combobox"
          id={filterOpener}
          onClick={() => {
            setOpen(!open);
            setActiveIndex(staticRanges.findIndex((range) => range.title === filterInUse));
          }}
          aria-expanded={open}
          aria-controls={filterMenu}
        >
          {filterInUse === 'customRange'
            ? `${formatDate(
                'local',
                customRangeStartDate,
                DATE_FORMAT.usSemiVerboseDate,
              )} - ${formatDate('local', customRangeEndDate, DATE_FORMAT.usSemiVerboseDate)}`
            : getFilter(filterInUse).description}
          <Styled.FilterIconCaretDown icon="caret-down" />
        </Styled.FilterToggle>
        {open && (
          <Styled.FilterWrapper id={filterMenu} customRange={showCustomRange}>
            <Styled.FilterItemsWrapper role="listbox" customRange={showCustomRange}>
              {staticRanges.map((filter: StaticRange, index) => (
                <Styled.FilterItem
                  key={filter.title}
                  role="option"
                  aria-selected={filterInUse === filter.title}
                  data-index={index}
                  tabIndex={activeIndex === index ? 0 : -1}
                  onClick={() => {
                    const { title } = filter;
                    setOpen(title === 'customRange');
                    setFilterInUse(title);
                    if (title === 'allTime') {
                      setCustomRangeStartDate(accountCreated ?? createDate('2014-01-01'));
                    } else {
                      setCustomRangeStartDate(getFilter(filterInUse).startDatetime);
                    }
                    setCustomRangeEndDate(getFilter(filterInUse).endDatetime);

                    if (title !== 'customRange') {
                      filterButton?.focus();
                    } else {
                      customRangeCalendar?.focus();
                    }
                  }}
                  aria-expanded={filter.title === 'customRange' ? showCustomRange : undefined}
                  aria-controls={filter.title === 'customRange' ? customRangePicker : undefined}
                >
                  {filter.description}
                </Styled.FilterItem>
              ))}
            </Styled.FilterItemsWrapper>
            {showCustomRange && (
              <Styled.CustomRangeWrapper id={customRangePicker} tabIndex={-1}>
                <DatetimeRange
                  startDate={customRangeStartDate}
                  endDate={customRangeEndDate}
                  setStartDatetime={(newStartDatetime: Date) => {
                    setCustomRangeStartDate(newStartDatetime);
                  }}
                  setEndDatetime={(newEndDatetime: Date) => {
                    setCustomRangeEndDate(newEndDatetime);
                  }}
                  localOrUTC="local"
                />
              </Styled.CustomRangeWrapper>
            )}
          </Styled.FilterWrapper>
        )}
      </Styled.ReportFilterWrapper>
    </Styled.ReportHeaderWrapper>
  );
}
