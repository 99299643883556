import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const createScanformByShareToken = gql(/* GraphQL */ `
  mutation CreateScanformByShareToken($shareToken: String!) {
    createScanformByShareToken(shareToken: $shareToken) {
      id
      canCreateScanform
      isScanformCreated
      scanformUrl
    }
  }
`);

export const useCreateScanformByShareToken = () => useMutation(createScanformByShareToken);
