import { getIn, useFormikContext } from 'formik';
import Checkbox from '../form/Checkbox';
import FormControl from '../form/FormControl';
import TextField from '../form/TextField';
import { Col, Row } from '../layout/Grid';
import { NamespacedSubform } from './types';

type SaveOrUpdatePackageSubformProps = {
  isNewPreset: boolean;
};

export type SaveOrUpdatePackageSubformValues = {
  updatePreset: boolean;
  createNewPreset: boolean;
  newPresetTitle: string | undefined;
};

export default function SaveOrUpdatePackageSubform<NS extends string>({
  namespace,
  isNewPreset,
}: NamespacedSubform<NS> & SaveOrUpdatePackageSubformProps) {
  const { values } = useFormikContext<Record<string, unknown>>();
  const { createNewPreset } = getIn(values, namespace);

  return isNewPreset ? (
    <>
      <Row>
        <Col md={12}>
          <FormControl
            name={`${namespace}.createNewPreset`}
            label="Save Package"
            type="checkbox"
            as={Checkbox}
            secondaryText="Save your settings for repeated use."
          />
        </Col>
      </Row>
      {createNewPreset ? (
        <Row>
          <Col>
            <FormControl
              as={TextField}
              name={`${namespace}.newPresetTitle`}
              placeholder="Enter a nickname for your Saved Package"
            />
          </Col>
        </Row>
      ) : null}
    </>
  ) : (
    <Row>
      <Col md={12}>
        <FormControl
          name={`${namespace}.updatePreset`}
          label="Update Saved Package"
          type="checkbox"
          as={Checkbox}
          secondaryText="Update your saved settings"
        />
      </Col>
    </Row>
  );
}
