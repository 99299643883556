import { useId, PropsWithChildren } from 'react';
import { TooltipIcon } from './Icon';
import Tooltip from './form/Tooltip';

type QuestionIconTooltipProps = PropsWithChildren<object>;

export default function QuestionIconTooltip({ children }: QuestionIconTooltipProps) {
  const tooltipId = useId();

  return (
    <>
      <TooltipIcon id={tooltipId} />
      <Tooltip anchorSelector={`#${CSS.escape(tooltipId)}`}>{children}</Tooltip>
    </>
  );
}
