import styled from '@emotion/styled';
import { ReactElement } from 'react';
import PageTitle from './PageTitle';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';
import { ReportsFilterProps } from '../pages/reports/overview/ReportsFilter';
import PageHeader, { PageHeaderProps } from './PageHeader';

const Styled = {
  For: styled.div`
    font-size: ${TYPOGRAPHY.fontSize.xl};
    padding: ${SPACING.none} ${SPACING.sm} ${SPACING.none} ${SPACING.md};
  `,
  FilterWrapper: styled.div`
    font-size: ${TYPOGRAPHY.fontSize.lg};
    padding-top: ${SPACING.xs};
  `,
  Rest: styled.div`
    display: flex;
  `,
};
type PageHeaderWithFilterProps = {
  filter: ReactElement<ReportsFilterProps>;
} & PageHeaderProps;

export default function PageHeaderWithFilter({
  title,
  tooltip,
  filter,
  rightSideActions,
}: PageHeaderWithFilterProps) {
  return (
    <PageHeader
      title={
        <>
          <PageTitle>{title}</PageTitle>
          <Styled.Rest>
            <Styled.For>for</Styled.For>
            <Styled.FilterWrapper>{filter}</Styled.FilterWrapper>
          </Styled.Rest>
        </>
      }
      tooltip={tooltip}
      rightSideActions={rightSideActions}
    />
  );
}
