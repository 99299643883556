import uspsMiniLogoUrl from '../images/usps-mini-logo.svg';
import upsLogoUrl from '../images/ups-logo.svg';
import fedexExpressLogoUrl from '../images/fedex-express-logo.svg';
import fedexGroundLogoUrl from '../images/fedex-ground-logo.svg';
import psCarrierLogoUrl from '../images/ps-carrier-logo.svg';

export default function carrierKeyToIconUrl(
  carrierKey: string,
  mailClassKey: string | null = '',
): string {
  // this keeps the function robust from breaking when using string methods later
  if (!carrierKey) return '';

  // we show the mail class key as a carrier to the user WHEN it is the Pirate Ship
  // Simple Export Rate, whose key is "FirstGlobalRate" in the DB.
  if (mailClassKey === 'FirstGlobalRate' || mailClassKey === 'SimpleExportRate') {
    return psCarrierLogoUrl;
  }

  switch (carrierKey.toLocaleLowerCase()) {
    case 'usps':
      return uspsMiniLogoUrl;
    case 'ups':
      return upsLogoUrl;
    // We keep the fedex case here to show the logo next to the tracking link and next to the service name
    case 'fedex':
      return mailClassKey === 'FEDEX_GROUND' ? fedexGroundLogoUrl : fedexExpressLogoUrl;
    // note: the following case (ps as a carrier) should never happen! Perhaps we can delete this.
    case 'ps':
      return psCarrierLogoUrl;
    default:
      return '';
  }
}
