import * as yup from 'yup';
import { WarehouseSubformValues } from './WarehouseSubform';
import { addressValidationSchema, INITIAL_ADDRESS_SUBFORM_VALUES } from './addressSubformUtils';

export const INITIAL_WAREHOUSE_SUBFORM_VALUES: WarehouseSubformValues = {
  title: '',
  originAddress: { ...INITIAL_ADDRESS_SUBFORM_VALUES },
  useOriginAsReturnAddress: true,
  returnAddress: { ...INITIAL_ADDRESS_SUBFORM_VALUES },
  saveAddressForReuse: false,
};

export const warehouseValidationSchema = () =>
  yup.object<WarehouseSubformValues>({
    originAddress: addressValidationSchema({ phone: 'US' }).required(),
    useOriginAsReturnAddress: yup.boolean().required(),
    returnAddress: addressValidationSchema()
      .defined()
      .when(
        'useOriginAsReturnAddress',
        (useOriginAsReturnAddress: boolean, schema: yup.MixedSchema) =>
          useOriginAsReturnAddress ? yup.object() : schema.required(),
      ),
    saveAddressForReuse: yup.boolean().required(),
    title: yup
      .string()
      .defined()
      .default('')
      .when('saveAddressForReuse', (saveAddressForReuse: boolean, schema: yup.StringSchema) =>
        saveAddressForReuse ? schema.required() : schema,
      ),
  });
