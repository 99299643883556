import { PayPalCheckout } from 'braintree-web';
import { useRef, useEffect, useState, useCallback } from 'react';
import { useBraintreeClientTokenQuery } from '../operations/queries/braintreeToken';
import useObfuscatedErrorLogger from './useObfuscatedErrorLogger';

type UsePayPalValue = {
  paypalCheckoutInstance: PayPalCheckout;
  // unfortunately, it doesn't seem that braintree exposes the type of this papal instance.
  // additionally, it can't be manually typed as it depends on enums that are not exposed and therefore never overlappable with any other type.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payPalInstance: any;
};

const BRAINTREE_CLIENT_URL = 'https://js.braintreegateway.com/web/3.83.0/js/client.min.js';
const BRAINTREE_CHECKOUT_URL =
  'https://js.braintreegateway.com/web/3.83.0/js/paypal-checkout.min.js';
const BRAINTREE_DATA_COLLECTOR_URL =
  'https://js.braintreegateway.com/web/3.83.0/js/data-collector.min.js';

// these are the steps that sequentially need to happen in order to use PayPal
type LoadStep =
  | 'begin'
  | 'tokenCollected'
  | 'braintreeClientLoaded'
  | 'braintreeCheckoutLoaded'
  | 'braintreeDataCollectorLoaded'
  | 'payPalInstanceLoaded'
  | 'payPalSDKLoaded'
  | 'instanceRefSet';

export default function usePayPal(tokenizationKey?: string | null) {
  const logError = useObfuscatedErrorLogger();
  const instanceRef = useRef<UsePayPalValue | null>(null);

  const loadScript = useCallback(
    (callback: () => void, url: string) => {
      const script = document.createElement('script');
      script.async = true;
      script.onload = callback;
      script.onerror = () => logError(`Could not load Braintree script: ${url}`);
      script.src = url;
      document.body.appendChild(script);
    },
    [logError],
  );

  const { data: token } = useBraintreeClientTokenQuery();
  const [payPalCheckoutInstance, setPayPalCheckoutInstance] = useState<PayPalCheckout>();
  // see above comment as to why we disable the no-any rule here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [payPalInstance, setPayPalInstance] = useState<any>();
  const [loadStep, setLoadStep] = useState<LoadStep>('begin');

  // (0) load token from backend
  useEffect(() => {
    if (loadStep === 'begin' && token && !!tokenizationKey) {
      setLoadStep('tokenCollected');
    }
  }, [loadStep, token, tokenizationKey]);

  // (1) load braintree client
  useEffect(() => {
    if (loadStep === 'tokenCollected') {
      loadScript(() => setLoadStep('braintreeClientLoaded'), BRAINTREE_CLIENT_URL);
    }
  }, [loadScript, loadStep]);

  // (2) load braintree checkout
  useEffect(() => {
    if (loadStep === 'braintreeClientLoaded') {
      loadScript(() => setLoadStep('braintreeCheckoutLoaded'), BRAINTREE_CHECKOUT_URL);
    }
  }, [loadScript, loadStep]);

  // (3) load braintree data collector -> not needed now
  useEffect(() => {
    if (loadStep === 'braintreeCheckoutLoaded') {
      loadScript(() => setLoadStep('braintreeDataCollectorLoaded'), BRAINTREE_DATA_COLLECTOR_URL);
    }
  }, [loadScript, loadStep]);

  // (4) create braintree instance
  useEffect(() => {
    (async () => {
      if (loadStep === 'braintreeDataCollectorLoaded' && !!tokenizationKey) {
        try {
          const clientInstance = await window.braintree.client.create({
            authorization: tokenizationKey,
          });

          if (!payPalCheckoutInstance) {
            setPayPalCheckoutInstance(
              await window.braintree.paypalCheckout.create({
                client: clientInstance,
              }),
            );
            setLoadStep('payPalInstanceLoaded');
          }
        } catch (error) {
          logError('Could not create Braintree instance', error as Error);
        }
      }
    })();
  }, [loadStep, logError, payPalCheckoutInstance, tokenizationKey]);

  // (5) load PayPal SDK
  useEffect(() => {
    (async () => {
      if (loadStep === 'payPalInstanceLoaded') {
        try {
          setPayPalCheckoutInstance(
            await payPalCheckoutInstance!.loadPayPalSDK({
              vault: true,
            }),
          );
          setPayPalInstance(window.paypal);
          setLoadStep('payPalSDKLoaded');
        } catch (error) {
          logError('Could not load PayPal SDK', error as Error);
        }
      }
    })();
  }, [loadStep, logError, payPalCheckoutInstance]);

  // (6) add instances to the window object
  useEffect(() => {
    (() => {
      if (loadStep === 'payPalSDKLoaded') {
        instanceRef.current = {
          paypalCheckoutInstance: payPalCheckoutInstance!,
          payPalInstance,
        };
        // retrigger the hook to get the ref to the paypal button
        setLoadStep('instanceRefSet');
      }
    })();
  }, [loadStep, logError, payPalCheckoutInstance, payPalInstance]);

  return instanceRef.current;
}
