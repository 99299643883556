import { Row, Col } from '../../../layout/Grid';
import Alert from '../../../Alert';
import Link from '../../../Link';

export default function SignupError({ error }: { error?: string }) {
  if (!error) {
    return null;
  }

  return (
    <Row spaceBelow>
      <Col xs={12}>
        <Alert variant="danger" data-testid="signup-error">
          {(() => {
            switch (error) {
              case 'email_exists':
                return (
                  <>
                    An account with this email address already exists! You can either{' '}
                    <Link to="/" bridgeHref="/">
                      login
                    </Link>{' '}
                    or if you forgot your password,{' '}
                    <Link to="/password" bridgeHref="/password/reset">
                      reset your password
                    </Link>
                    .
                  </>
                );

              case 'captcha_verification_failed':
                return "You didn't pass the robot test! Make sure to follow the instructions carefully.";
              case 'verification_failed':
                return "You didn't pass the robot test! Please contact customer support.";
              case 'response_not_ok': // legacy
              case 'no_response_data': // legacy
              case 'missing_response':
              case 'general_error': // legacy
              case 'graphql_error':
              default:
                return 'Could not complete signup process. Please contact customer support.';
            }
          })()}
        </Alert>
      </Col>
    </Row>
  );
}
