import { gql } from '../../gql';

const createScanformByBatchMutation = gql(/* GraphQL */ `
  mutation CreateScanformByBatchMutation($batchId: ID!) {
    createScanformByBatch(batchId: $batchId) {
      batchId
      canCreateScanform
      scanformUrl
    }
  }
`);

export default createScanformByBatchMutation;
