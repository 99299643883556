import { RateGroup, RateGroupsSubformValues } from '../../components/subforms/RateGroupsSubform';
import { BatchQuery, RateGroupTrait, RateSelectionInput } from '../../gql/graphql';
import notNullish from '../../utils/notNullish';

type Batch = BatchQuery['batch'];

/**
 * Transforms the magic value returned by a RateGroupSelect
 * into a value that GraphQL can digest.
 *
 * @param key - A trait for each of the rate group layers, for example [('address', 'domestic'), ('measurement', 'small_and_light')]
 * @param value - A magic string combined of 3 values, format: '<mailClassId>-<packageType>-<isSaturdayDelivery>' => '30-1-0'
 * @param batch - The batch object containing rate groups
 * @returns A RateSelectionInput required by GraphQL
 */
function transformRateSummaryKeyValue(
  key: string,
  value: string,
  batch: Batch,
): RateSelectionInput {
  const regEx = /^\d{1,}-\d{1,}-\d{1,}$/;

  if (!regEx.test(value)) {
    throw new Error('Wrong format of RateGroupSelect value!');
  }

  const [mailClassId, packageTypeId, isSaturdayDelivery] = value.split('-');
  const rateGroup = batch.rateGroups.find((group: RateGroup) => group.groupKey.string === key);

  if (!rateGroup) {
    throw new Error('Incorrect RateGroup key!');
  }

  const rateGroupKey = {
    traits: rateGroup.groupKey.traits.map((trait: RateGroupTrait) => ({
      layer: trait.layer,
      value: trait.value,
    })),
  };

  return {
    groupKeyInput: rateGroupKey,
    mailClassId,
    packageTypeId,
    isSaturdayDelivery: isSaturdayDelivery === '1',
  };
}

/**
 * Gets the formatted rate selection input for the given summary IDs.
 *
 * @param summaryIds - The summary IDs containing rate group keys and values
 * @param batch - The batch object containing rate groups
 * @returns An array of RateSelectionInput required by GraphQL
 */
export default function getFormattedRateSelectionInput(
  summaryIds: RateGroupsSubformValues,
  batch: Batch,
): RateSelectionInput[] {
  return Object.entries(summaryIds)
    .map(([item, value]) => transformRateSummaryKeyValue(item, value, batch))
    .filter(notNullish);
}
