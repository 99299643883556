import intersection from 'lodash.intersection';
import { BatchQuery } from '../../gql/graphql';

export default function getAvailableShipDates(rateGroups: BatchQuery['batch']['rateGroups']) {
  // Get the intersection of all available ship dates
  return intersection(
    ...rateGroups
      // Get all rate summaries
      .map((rateGroup) => rateGroup.rateSummaries)
      .flat()
      // Only include rate summaries that are available
      .filter((rateSummary) => rateSummary.shipmentCount > 0)
      // Get all available ship dates
      .map((rateSummary) => rateSummary.availableShipDates),
  ).sort();
}
