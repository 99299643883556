import type { Column, ColumnFiltersState } from '@tanstack/react-table';
import newDate from '../../../utils/newDate';

type MappedFilterForQuery = {
  field: string;
  operator: 'eq' | 'neq' | 'gte' | 'lte' | 'gt' | 'lt' | 'in' | 'not_in' | 'like' | 'null';
  values: string[];
};

export default function convertFiltersForQuery(
  filters: ColumnFiltersState,
  columns: Column<{ id: string }, unknown>[],
): MappedFilterForQuery[] {
  const mappedFilters: MappedFilterForQuery[] = [];

  filters.forEach((filter) => {
    // get the column's filter type
    const filterType =
      columns.find((column) => column.id === filter.id)?.columnDef.meta?.filterType || 'exact';

    switch (filterType) {
      case 'between': {
        const filterValueArray = filter.value as number[];
        if (filterValueArray[0]) {
          mappedFilters.push({
            field: filter.id,
            operator: 'gte',
            values: [String(filterValueArray[0])],
          });
        }
        if (filterValueArray[1]) {
          mappedFilters.push({
            field: filter.id,
            operator: 'lte',
            values: [String(filterValueArray[1])],
          });
        }
        break;
      }
      case 'betweenDates': {
        const filterValueArray = filter.value as string[];
        if (filterValueArray[0]) {
          mappedFilters.push({
            field: filter.id,
            operator: 'gte',
            values: [newDate(filterValueArray[0]).toISOString()],
          });
        }
        if (filterValueArray[1]) {
          mappedFilters.push({
            field: filter.id,
            operator: 'lte',
            values: [newDate(filterValueArray[1]).toISOString()],
          });
        }
        break;
      }
      case 'includes':
        mappedFilters.push({
          field: filter.id,
          operator: 'in',
          values: (filter.value as unknown[]).map((valueItem) => String(valueItem)),
        });
        break;

      case 'exact':
      case 'exactText':
      case 'exactTextCase':
        mappedFilters.push({
          field: filter.id,
          operator: 'eq',
          values: [String(filter.value)],
        });
        break;

      default:
        mappedFilters.push({
          field: filter.id,
          operator: 'like',
          values: [String(filter.value)],
        });
    }
  });

  return mappedFilters;
}
