import { flexRender, Renderable } from '@tanstack/react-table';
import { FilterTagContext, FilterTagSettings } from '../types';

export type RangeFilterTagSettings<TData, TValue> = {
  filterTag: Renderable<FilterTagContext<TData, TValue>>;
  filterTagSettings?: FilterTagSettings<TData, TValue>;
  lowerLimitOnlyPrefix?: string;
  upperLimitOnlyPrefix?: string;
};

export default function RangeFilterTag<TData, TValue>(
  filterTagContext: FilterTagContext<TData, TValue>,
) {
  const { table, filterValue, settings } = filterTagContext;
  // avoid error if filterValue is not an array. in types.ts it is said,
  // that filterValue "can be any value we set in the filter", so we need this check
  if (!filterValue?.length) {
    return null;
  }
  const [startValue = null, endValue = null] = filterValue as [unknown, unknown];

  const filterTagSettings = settings as RangeFilterTagSettings<TData, TValue>;
  const { filterTag } = filterTagSettings;
  const lowerLimitOnlyPrefix = filterTagSettings.lowerLimitOnlyPrefix ?? '>=';
  const upperLimitOnlyPrefix = filterTagSettings.upperLimitOnlyPrefix ?? '<=';

  const startValueTag = flexRender(filterTag, {
    table,
    filterValue: startValue,
    settings: filterTagSettings,
  });

  const endValueTag = flexRender(filterTag, {
    table,
    filterValue: endValue,
    settings: filterTagSettings,
  });

  // No values
  if (startValue === null && endValue === null) {
    return null;
  }

  // Lower limit only
  if (endValue === null) {
    return (
      <>
        {lowerLimitOnlyPrefix} {startValueTag}
      </>
    );
  }

  // Upper limit only
  if (startValue === null) {
    return (
      <>
        {upperLimitOnlyPrefix} {endValueTag}
      </>
    );
  }

  // Value range
  return (
    <>
      {startValueTag} — {endValueTag}
    </>
  );
}
