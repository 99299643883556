type RefundCopy = {
  title: string;
  description: string;
  success: string;
};

type ShipmentRefundCopys = {
  instant: RefundCopy;
  ups: RefundCopy;
  usps: RefundCopy;
};

export const SHIPMENT_REFUND_COPY: ShipmentRefundCopys = {
  instant: {
    title: 'THIS LABEL QUALIFIES FOR AN INSTANT REFUND',
    description:
      'Since you have not clicked the [b]Print Label[/b] button yet, it will instantly be refunded. The credit will immediately be returned to your Pirate Ship account balance.',
    success:
      'This label was refunded instantly. Your Pirate Ship account balance has been credited, which you can see in your Reports page.',
  },
  ups: {
    title: 'UPS REFUNDS TAKE UP TO 1 HOUR',
    description:
      'This label will be submitted for a refund and cannot be used. The refund will be returned to your Pirate Ship account balance within one hour. If you use this label, you will be charged.',
    success:
      'This label was submitted for a refund. Your Pirate Ship account balance will be credited within 1 hour.',
  },
  usps: {
    title: 'USPS REFUNDS TAKE UP TO 30 DAYS',
    description:
      "This label will be submitted for a refund and cannot be used. USPS waits up to 30 days to make sure the label is not used before they'll credit your Pirate Ship account balance.",
    success:
      'This label was submitted for a refund. Your Pirate Ship account balance will be credited as soon as USPS approves the refund.',
  },
} as const;

export const BATCH_REFUND_COPY: ShipmentRefundCopys & { default: RefundCopy } = {
  instant: {
    title: 'THESE LABELS QUALIFY FOR INSTANT REFUNDS',
    description:
      'Since you have not clicked the [b]Print Labels[/b] button yet, they will instantly be refunded. The credit will immediately be returned to your Pirate Ship account balance.',
    success:
      'These labels were refunded instantly. Your Pirate Ship account balance has been credited, which you can see in your Reports page.',
  },
  ups: {
    title: 'UPS REFUNDS TAKE UP TO 1 HOUR',
    description:
      'These labels will be submitted for a refund and cannot be used. The refund will be returned to your Pirate Ship account balance within one hour. If you use these labels, you will be charged.',
    success:
      'These labels were submitted for a refund. Your Pirate Ship account balance will be credited within 1 hour.',
  },
  usps: {
    title: 'USPS REFUNDS TAKE UP TO 30 DAYS',
    description:
      "These labels will be submitted for a refund and cannot be used. USPS waits up to 30 days to make sure the labels are not used before they'll credit your Pirate Ship account balance.",
    success:
      'These labels were submitted for a refund. Your Pirate Ship account balance will be credited as soon as USPS approves the refund.',
  },
  default: {
    title: 'REFUND LABELS FROM MULTIPLE CARRIERS',
    description: '',
    success:
      'These labels were submitted for a refund. Your Pirate Ship account balance will be credited as soon as USPS approves the refund and for UPS it will be credited within 1 hour.',
  },
} as const;
