import { useState, useRef, useEffect, useCallback } from 'react';
import type { TooltipFormatterCallbackFunction } from 'highcharts';
import styled from '@emotion/styled';
import { useReportsPageLazyQuery } from '../../../operations/queries/reportsPage';
import { useNumbersLazyQuery } from '../../../operations/queries/numbers';
import { useShipmentTrackingNumbersLazyQuery } from '../../../operations/queries/shipmentTrackingNumbers';
import { DATE_FORMAT, REPORT_TILE_SIZE } from '../../../constants';
import { PageContainer, Row, Col } from '../../layout/Grid';
import TransactionsGrid from './TransactionsGrid';
import PageHeaderWithFilter from '../../layout/PageHeaderWithFilter';
import { SPACING } from '../../../styles/spacing';
import useDateInUserTimezone from '../../../hooks/useDateInUserTimezone';
import useStaticRangesInUserTimezone, {
  ReportsFilters,
} from '../../../hooks/useStaticRangesInUserTimezone';
import ReportFilter from './overview/ReportsFilter';
import ReportsTile, { ReportsTitle } from './overview/ReportsTile';
import ReportsStateDistribution from './overview/ReportsStateDistribution';
import ReportsCompanySavings from './overview/ReportsCompanySavings';
import ReportsShipmentChart from './overview/ReportsShipmentChart';
import ReportsCountryDistribution from './overview/ReportsCountryDistribution';
import ReportsAverageCostChart from './overview/ReportsAverageCostChart';
import ReportsTotalPayments from './overview/ReportsTotalPayments';
import ReportsServicesChart from './overview/ReportsServicesChart';
import ReportsRecipientZoneChart from './overview/ReportsRecipientZoneChart';
import environment from '../../../utils/environment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LinkedIconTooltip from '../../LinkedIconTooltip';

// eslint-disable-next-line react-refresh/only-export-components
export const ReportsOverviewStyles = {
  // to overcome legacy print styles in bridge
  PageContainer: styled(PageContainer)`
    .print-chart {
      display: none;
    }
    @media print {
      .print-chart {
        display: block;
      }
      .screen-chart {
        display: none;
      }
      @page {
        size: letter landscape;
        margin: 0.5in;
      }
    }
  `,
  TallChart: styled(ReportsTile)`
    @media print {
      height: calc(3 * (${REPORT_TILE_SIZE.Print} + ${SPACING.lg})) !important;
    }
  `,
  ShortChart: styled(ReportsTile)`
    @media print {
      height: calc(2 * ${REPORT_TILE_SIZE.Print} + 1.5 * ${SPACING.lg}) !important;
    }
  `,
  Top5Tile: styled(ReportsTile)`
    @media print {
      height: calc(2.5 * ${REPORT_TILE_SIZE.Print}) !important;
    }
  `,
  TransactionTitle: styled(ReportsTitle)`
    text-align: left;
    @media print {
      margin-block: ${SPACING.lg} -${SPACING.md};
    }
  `,
};

// eslint-disable-next-line react-refresh/only-export-components
export const percentFormatter: TooltipFormatterCallbackFunction = function percentFormatter() {
  return `${this.x}: ${this.y?.toPrecision(3)}%`;
};

// eslint-disable-next-line react-refresh/only-export-components
export const dollarFormatter: TooltipFormatterCallbackFunction = function dollarFormatter() {
  return `${this.x}: $${this.y?.toFixed(2)}`;
};

function ReportsOverviewPage() {
  const transactionHistoryRef = useRef<HTMLDivElement>(null);
  const { getStorageFilter, getFilter, now } = useStaticRangesInUserTimezone();
  const storageFilter = getStorageFilter();

  const { createDate, formatDate } = useDateInUserTimezone();
  const [filterInUse, setFilterInUse] = useState<ReportsFilters>(
    storageFilter?.title ?? 'last30days',
  );

  const [customRangeStartDate, setCustomRangeStartDate] = useState<Date>(
    storageFilter?.startDatetime ?? createDate('2024-01-01'),
  );
  const [customRangeEndDate, setCustomRangeEndDate] = useState<Date>(
    storageFilter?.endDatetime ?? now,
  );

  const [accountCreated, setAccountCreated] = useState<Date>(createDate('2024-01-01'));

  const getFormattedDatetimeRange = useCallback(() => {
    if (filterInUse === 'customRange') {
      return {
        startDatetime: formatDate('local', customRangeStartDate, DATE_FORMAT.date),
        endDatetime: formatDate('local', customRangeEndDate, DATE_FORMAT.date),
      };
    }
    if (filterInUse === 'allTime') {
      return {
        startDatetime: formatDate('local', accountCreated, DATE_FORMAT.date),
        endDatetime: formatDate('local', getFilter(filterInUse).endDatetime, DATE_FORMAT.date),
      };
    }
    return {
      startDatetime: formatDate('local', getFilter(filterInUse).startDatetime, DATE_FORMAT.date),
      endDatetime: formatDate('local', getFilter(filterInUse).endDatetime, DATE_FORMAT.date),
    };
    // allowing getFilter into the dependencies causes loops:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInUse, formatDate, customRangeStartDate, customRangeEndDate, accountCreated]);

  const [loadReportsPage, { data: reportsPageData }] = useReportsPageLazyQuery();

  const [loadNumbers, { data: numbersData }] = useNumbersLazyQuery({
    variables: {
      datetimeRange: getFormattedDatetimeRange(),
    },
  });

  const [loadShipmentTrackingNumbers, { data: shipmentTrackingNumbersData }] =
    useShipmentTrackingNumbersLazyQuery({
      variables: {
        datetimeRange: getFormattedDatetimeRange(),
      },
    });

  useEffect(() => {
    loadReportsPage();
    loadNumbers({
      variables: {
        datetimeRange: getFormattedDatetimeRange(),
      },
    });
    loadShipmentTrackingNumbers({
      variables: {
        datetimeRange: getFormattedDatetimeRange(),
      },
    });
  }, [loadShipmentTrackingNumbers, loadReportsPage, loadNumbers, getFormattedDatetimeRange]);

  const hasNoPaymentMethod = () =>
    reportsPageData?.company.paymentSources.length === 0 ||
    (reportsPageData?.company.paymentSources.length === 1 &&
      reportsPageData?.company.paymentSources[0].paymentMethodType === 'manual');
  const creditZeroOrNegative = () =>
    reportsPageData?.company.accountBalance === undefined ||
    reportsPageData?.company.accountBalance <= 0;

  useEffect(() => {
    setAccountCreated(createDate(reportsPageData?.company.createdAt ?? createDate('2024-01-01')));
  }, [createDate, reportsPageData?.company.createdAt]);

  return (
    <ReportsOverviewStyles.PageContainer>
      <PageHeaderWithFilter
        title="Shipping Report"
        filter={
          <ReportFilter
            loading={!reportsPageData}
            accountCreated={accountCreated}
            customRangeStartDate={customRangeStartDate}
            setCustomRangeStartDate={setCustomRangeStartDate}
            customRangeEndDate={customRangeEndDate}
            setCustomRangeEndDate={setCustomRangeEndDate}
            setFilterInUse={setFilterInUse}
            filterInUse={filterInUse}
          />
        }
        tooltip={
          <LinkedIconTooltip href="https://support.pirateship.com/en/articles/5445784-how-to-read-your-reports-page">
            <span>
              Need a guide to understanding your Reports page? Click the{' '}
              <FontAwesomeIcon icon="question-circle" /> above to learn more 👍
            </span>
          </LinkedIconTooltip>
        }
      />
      <Row>
        <Col md={4}>
          <ReportsCompanySavings dateFormatter={getFormattedDatetimeRange} />
        </Col>
        <Col md={4}>
          <ReportsTile
            title="Pending Returns"
            loading={!numbersData}
            link="View Return Labels"
            linkTo="/reports/return"
            labelCount={numbersData?.numbers.pendingNumbers.returns.count}
            amount={numbersData?.numbers.pendingNumbers.returns.amount}
          />
        </Col>
        <Col md={4}>
          <ReportsTile
            title="Pending Refunds"
            loading={!numbersData}
            link="View Refund History"
            linkTo="/reports/refund"
            labelCount={numbersData?.numbers.pendingNumbers.refunds.count}
            amount={numbersData?.numbers.pendingNumbers.refunds.amount}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <ReportsTile
            title="Account Balance"
            loading={!reportsPageData}
            {...(reportsPageData && {
              link: hasNoPaymentMethod() ? 'Manage Payment Sources' : 'Add Credit',
              linkTo: hasNoPaymentMethod() ? '/settings/billing' : '/reports/addcredit',
              link2: !hasNoPaymentMethod() && !creditZeroOrNegative() ? 'Refund Credit' : '',
              link2To:
                !hasNoPaymentMethod() && !creditZeroOrNegative() ? '/reports/refundcredit' : '',
              // jumping from one module to another seems to be a problem in SPA routing
              // PP-14742 Reports page "Manage Payment Sources" link navigates to a blank page
              onLinkClick: () => {
                if (environment.isBridge() && hasNoPaymentMethod()) {
                  window.location.href = '/settings/billing';
                }
              },
            })}
            amount={reportsPageData?.company.accountBalance}
            valueId="acc_balance"
          />
          <ReportsTile
            title="Total Shipping Costs"
            loading={!numbersData}
            link="View Transactions"
            linkTo="/reports#"
            onLinkClick={(event) => {
              event.preventDefault();
              transactionHistoryRef.current?.scrollIntoView({
                behavior: 'smooth',
              });
            }}
            amount={numbersData?.numbers.netLabelTotal}
          />
          <ReportsTile
            title="Carrier Adjustments"
            loading={!numbersData}
            link="View All Adjustments"
            linkTo="/reports/carrieradjustment"
            amount={numbersData?.numbers.carrierAdjustmentsAmount}
          />
        </Col>
        <ReportsTotalPayments
          currentFilter={getFilter(filterInUse).description}
          dateFormatter={getFormattedDatetimeRange}
          dollarFormatter={dollarFormatter}
          paymentsAmount={numbersData?.numbers.payments}
          paymentsTotalAmount={numbersData?.numbers.pendingNumbers.paymentsTotalAmount}
        />
      </Row>
      <Row>
        <ReportsAverageCostChart
          currentFilter={getFilter(filterInUse).description}
          dateFormatter={getFormattedDatetimeRange}
          dollarFormatter={dollarFormatter}
          netLabelAverage={numbersData?.numbers.netLabelAverage}
        />
        <Col md={4}>
          <ReportsTile
            title="Avg. Domestic"
            loading={!numbersData}
            link="View Shipments"
            linkTo="/reports/shipment"
            amount={numbersData?.numbers.netLabelAverageDom}
          />
          <ReportsTile
            title="Avg. International"
            loading={!numbersData}
            link="View Shipments"
            linkTo="/reports/shipment"
            amount={numbersData?.numbers.netLabelAverageInt}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <ReportsTile
            title="New Shipments"
            loading={!shipmentTrackingNumbersData}
            link="View New Shipments"
            linkTo="/reports/shipment/new"
            shipments={shipmentTrackingNumbersData?.shipmentTrackingNumbers.shipmentsNew}
          />

          <ReportsTile
            title="Delivery Issues"
            loading={!shipmentTrackingNumbersData}
            link="View Undeliverable Shipments"
            linkTo="/reports/shipment/delivery_issue"
            shipments={shipmentTrackingNumbersData?.shipmentTrackingNumbers.shipmentsDeliveryIssue}
          />
        </Col>
        <ReportsShipmentChart
          currentFilter={getFilter(filterInUse).description}
          netLabelCount={numbersData?.numbers.netLabelCount}
          dateFormatter={getFormattedDatetimeRange}
        />
      </Row>
      <Row>
        <ReportsServicesChart dateFormatter={getFormattedDatetimeRange} />
        <ReportsRecipientZoneChart
          dateFormatter={getFormattedDatetimeRange}
          percentFormatter={percentFormatter}
        />
      </Row>
      <Row>
        <ReportsStateDistribution dateFormatter={getFormattedDatetimeRange} />
      </Row>
      <Row>
        <ReportsCountryDistribution dateFormatter={getFormattedDatetimeRange} />
      </Row>
      <Row>
        <Col md={12} spaceBelow>
          <ReportsOverviewStyles.TransactionTitle ref={transactionHistoryRef}>
            Transaction History
          </ReportsOverviewStyles.TransactionTitle>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <TransactionsGrid />
        </Col>
      </Row>
    </ReportsOverviewStyles.PageContainer>
  );
}

export default ReportsOverviewPage;
