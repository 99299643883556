import { IntlCurrencyFormatFn, IntlCurrencyFormatProps } from './types';

const createIntlCurrencyFormatInstance = ({
  fractionDigits = 2,
  locale = 'en-US',
  currency = 'USD',
}: IntlCurrencyFormatProps = {}) =>
  new Intl.NumberFormat(locale, {
    style: currency !== false ? 'currency' : undefined,
    currency: currency || undefined,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

// Let's create some global INTL instances to reduce instances over all in the app
export const formatCurrency: IntlCurrencyFormatFn = (v) =>
  createIntlCurrencyFormatInstance().format(v);
export const formatCurrencyNoUnit: IntlCurrencyFormatFn = (v) =>
  createIntlCurrencyFormatInstance({
    currency: false,
  }).format(v);
export const formatCurrencyNoFractionDigits: IntlCurrencyFormatFn = (v) =>
  createIntlCurrencyFormatInstance({
    fractionDigits: 0,
  }).format(v);
export const formatCurrencyNoFractionDigitsNorUnit: IntlCurrencyFormatFn = (v) =>
  createIntlCurrencyFormatInstance({ fractionDigits: 0, currency: false }).format(v);
