import styled from '@emotion/styled';
import { COLOR } from '../styles/colors';
import { SPACING } from '../styles/spacing';
import formatAddress, { Address, addressToLines } from '../utils/formatAddress';
import ClipboardButton from './form/ClipboardButton';
import { Col, Row } from './layout/Grid';
import PageTitle from './layout/PageTitle';
import Icon from './Icon';
import Tooltip from './form/Tooltip';
import { CountryMap } from '../utils/createCountryMap';

const Styled = {
  RecipientTitle: styled(PageTitle)`
    margin-bottom: ${SPACING.md};
  `,
  Icon: styled(Icon)`
    transform: scale(-1, 1);
    margin-left: ${SPACING.md};
  `,
};

type RecipientAddressProps = {
  isReturnLabel: boolean;
  recipientAddress: Address;
  countryMap: CountryMap;
};

export default function RecipientAddress({
  isReturnLabel,
  recipientAddress,
  countryMap,
}: RecipientAddressProps) {
  const lines = addressToLines(recipientAddress, countryMap);
  const clipboardValue = formatAddress(recipientAddress, countryMap, '\r\n');

  return (
    <Row>
      {lines.map((line: string, index: number, { length }: { length: number }) => {
        const last = index === length - 1;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Col md={12} key={index}>
            {index === 0 ? (
              <>
                <Styled.RecipientTitle>
                  {line}
                  {isReturnLabel && (
                    <Styled.Icon
                      icon="redo"
                      id="returnLabelIcon"
                      color={COLOR.blue}
                      aria-label="Return Label"
                    />
                  )}
                </Styled.RecipientTitle>
                <Tooltip anchorSelector={`#${CSS.escape('returnLabelIcon')}`} placement="bottom">
                  Return Label
                </Tooltip>
              </>
            ) : (
              <>
                {line}
                {last && <ClipboardButton value={clipboardValue} ariaTitle="address" />}
              </>
            )}
          </Col>
        );
      })}
    </Row>
  );
}
