import { useEffect, useRef, useState, forwardRef, Ref, SyntheticEvent } from 'react';

import useOutsideClickEffect from '../../hooks/useOutsideClickEffect';
import { RawCheckbox } from './Checkbox';
import useHighlightColor from '../../hooks/useHighlightColor';
import Icon from '../Icon';
import toggleInArray from '../../utils/toggleInArray';
import {
  DropdownSelectOption,
  DropdownSelectProps,
  StyledDropdownOption,
  StyledTitleWrapper,
  StyledDropdownSelectTitle,
  StyledDropdownIconWrapper,
  StyledCheckboxWrapper,
  StyledDropdownDescription,
  StyledDropdownSelectWrapper,
  StyledOptionsWrapper,
} from './DropdownSelect';

export type DropdownMultiSelectOption<D = unknown> = Omit<
  DropdownSelectOption<string, D>,
  'iconUrl'
>;

export type DropdownMultiSelectProps = Omit<
  DropdownSelectProps<string, DropdownMultiSelectOption>,
  'value' | 'defaultValue' | 'onChange'
> & {
  placeholder?: string; // used for multiselect if nothing is selected
  value?: string[];
  defaultValue?: string[];
  onChange?: (value: string[], event: SyntheticEvent<unknown, unknown>) => void;
};

function DropdownMultiSelectComponent(
  props: DropdownMultiSelectProps,
  ref: Ref<HTMLSelectElement>,
) {
  const {
    name,
    className,
    placeholder,
    options,
    value: values,
    defaultValue: defaultValues,
    disabled: selectDisabled,
    onChange,
    onRenderOption,
    error,
    prefill,
    id,
    ...others
  } = props;

  const [selectedValues, setSelectedValues] = useState(values ?? defaultValues);

  useEffect(() => {
    setSelectedValues(values ?? defaultValues);
  }, [values, defaultValues]);

  // map currentvalues to their options
  const selectedOptions = selectedValues?.flatMap(
    (value) => options.find((option) => option.value === value) ?? [],
  );

  const [open, setOpen] = useState(false);

  // Close dropdown menu on outside click
  const openDropdownMenuRef = useRef<HTMLDivElement>(null);
  useOutsideClickEffect([openDropdownMenuRef], () => setOpen(false));

  const renderStyledDropdownOpener = () => {
    const title = (() => {
      if (!selectedOptions || selectedOptions.length === 0) {
        return placeholder ?? '(None)';
      }
      const concatOptTitles = selectedOptions
        .map((opt) => (typeof opt.title === 'string' ? opt.title : ''))
        .join(', ');
      return `${concatOptTitles}${selectedOptions.length === options.length ? ' (All)' : ''}`;
    })();

    return (
      <StyledDropdownOption
        type="button"
        role="button"
        hasCaret
        open={open}
        disabled={!!selectDisabled}
        key={`${name}-opener`}
        id={id || `${name}-opener`}
        onClick={() => setOpen(!selectDisabled && !open)}
        {...others}
      >
        <StyledTitleWrapper>
          <StyledDropdownSelectTitle>{title}</StyledDropdownSelectTitle>
        </StyledTitleWrapper>

        <StyledDropdownIconWrapper>
          <Icon icon={open ? 'caret-up' : 'caret-down'} size="lg" />
        </StyledDropdownIconWrapper>
      </StyledDropdownOption>
    );
  };

  const renderStyledDropdownOption = (option: DropdownMultiSelectOption) => {
    const {
      value: optionValue,
      title = '',
      description,
      disabled: optionDisabled = false,
    } = option;

    return (
      <StyledDropdownOption
        type="button"
        role="option"
        aria-selected={
          selectDisabled || optionDisabled ? undefined : selectedValues?.includes(optionValue)
        }
        isSingleOption={options.length === 1}
        hasCaret={false}
        open={open}
        disabled={selectDisabled || optionDisabled}
        data-option-value={optionValue}
        key={`${name}-${optionValue}`}
        id={`${name}-${optionValue}`}
        onMouseLeave={({ currentTarget }) => currentTarget.blur()} // so the option does not remain blue in "mouse mode"
        onClick={(event) => {
          event.stopPropagation(); // dropdown will close otherwise
          if (optionDisabled) {
            return;
          }
          // uncontrolled DropdownSelect
          if (values === undefined) {
            setSelectedValues(toggleInArray(optionValue, selectedValues ?? []));
          }

          if (onChange) {
            const newSelectedValues = toggleInArray(optionValue, selectedValues ?? []);
            onChange(newSelectedValues, event);
          }
        }}
        {...others}
      >
        {
          <StyledCheckboxWrapper>
            <RawCheckbox
              type="checkbox"
              readOnly
              checked={selectedOptions?.includes(option)}
              tabIndex={-1} // usability: skip tabbing here s.t. the user tabs through just the options
            />
          </StyledCheckboxWrapper>
        }
        {onRenderOption ? (
          onRenderOption(option)
        ) : (
          <StyledTitleWrapper>
            <StyledDropdownSelectTitle>{title}</StyledDropdownSelectTitle>
            <StyledDropdownDescription>{description}</StyledDropdownDescription>
          </StyledTitleWrapper>
        )}
      </StyledDropdownOption>
    );
  };

  const highlightColor = useHighlightColor(Boolean(error), open);

  return (
    <>
      <select
        multiple
        hidden
        aria-hidden
        ref={ref}
        name={name}
        value={selectedValues}
        disabled={selectDisabled}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onChange={(event) => {
          onChange?.(
            Array.from(event.target.selectedOptions, (option) => option.value),
            event,
          );
        }}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value} disabled={option.disabled}>
            {option.title || option.value}
          </option>
        ))}
      </select>
      <StyledDropdownSelectWrapper
        role="listbox"
        aria-multiselectable
        ref={open ? openDropdownMenuRef : null}
        highlightColor={highlightColor}
        prefill={prefill}
        disabled={selectDisabled}
        className={className}
        open={open}
        onClick={() => {
          setOpen(!selectDisabled && !open);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Escape') {
            setOpen(false);
          }
        }}
      >
        {renderStyledDropdownOpener()}
        {open && (
          <StyledOptionsWrapper>{options.map(renderStyledDropdownOption)}</StyledOptionsWrapper>
        )}
      </StyledDropdownSelectWrapper>
    </>
  );
}

const DropdownMultiSelect = forwardRef(DropdownMultiSelectComponent);
export default DropdownMultiSelect;
