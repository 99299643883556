/**
 * Copies GraphQL responses recursively and optionally removes the __typename field
 * so the result can be used in mutations.
 */

export default function deepCopyGraphqlData<T>(value: T, removeTypenameField = true): T {
  if (typeof value !== 'object' || value === null) {
    return value;
  }

  if (Array.isArray(value)) {
    return value.map((val) => deepCopyGraphqlData(val, removeTypenameField)) as T;
  }

  const result: Record<string, unknown> = {};

  Object.entries(value as object).forEach(([key, val]) => {
    // Skip type info
    if (key === '__typename' && removeTypenameField) {
      return;
    }

    result[key] = deepCopyGraphqlData(val, removeTypenameField);
  });

  return result as T;
}
