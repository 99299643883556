import { Ref, RefObject, useState } from 'react';

/**
 * Take a forwarded callback ref and keep it in sync with the component it is forwarded to
 * This can also be used for taking a forwarded ref (callback type) and transforming it into a ref object
 */
export default function useForwardedRef<T>(
  ref: Ref<T> | undefined,
  initialValue: T | null = null,
): RefObject<T> {
  // Expose a ref-like object that wraps the given ref
  const [targetRef] = useState<RefObject<T>>(() => {
    let internalValue = initialValue;

    return Object.create(Object.prototype, {
      current: {
        enumerable: true,
        get() {
          return internalValue;
        },
        set(value: T) {
          internalValue = value;

          // Pass on our internal value to the given ref
          if (!ref) return;
          if (typeof ref === 'function') {
            ref(internalValue);
          } else {
            // @ts-expect-error TODO: explain why we are making this choice
            // eslint-disable-next-line no-param-reassign
            ref.current = internalValue;
          }
        },
      },
    });
  });

  return targetRef;
}
