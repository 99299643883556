/* eslint-disable @typescript-eslint/no-use-before-define */
import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client';
import type { StoreObject } from '@apollo/client/utilities';
import generatedIntrospection, {
  TypedTypePolicies,
  DataGridResult,
  TwoFactorProvider,
} from '../gql/graphql';
import { FlashMessage } from './cache/flashMessages';
import calculateDataGridCacheId from '../operations/queries/dataGrid';

const { possibleTypes } = generatedIntrospection;

// TODO there's probably a better way to solve this
type QueryFields = {
  Query: {
    fields: {
      currentUser: object;
      flashMessages: object;
      fatalError: object;
    };
  };
};

const typePolicies: TypedTypePolicies | QueryFields = {
  Query: {
    fields: {
      // Add root query fields that return reactive var values
      currentUser: {
        read() {
          return currentUserVar();
        },
      },
      flashMessages: {
        read() {
          return flashMessagesVar();
        },
      },
      fatalError: {
        read() {
          return fatalErrorVar();
        },
      },
    },
  },
  Company: {
    fields: {
      // Resolve objects when requesting with id
      mailTemplates: {
        read(existing, { args, toReference }) {
          if (args?.id) {
            return [
              toReference({
                __typename: 'MailTemplate',
                id: args.id,
              }),
            ];
          }

          return existing;
        },
      },
      shipmentPresets: {
        read(existing, { args, toReference }) {
          if (args?.id) {
            return [
              toReference({
                __typename: 'ShipmentPreset',
                id: args.id,
              }),
            ];
          }

          return existing;
        },
      },
      warehouses: {
        read(existing, { args, toReference }) {
          if (args?.id) {
            return [
              toReference({
                __typename: 'Warehouse',
                id: args.id,
              }),
            ];
          }

          return existing;
        },
      },
      paymentSources: {
        read(existing, { args, toReference }) {
          if (args?.id) {
            return [
              toReference({
                __typename: 'PaymentSource',
                id: args.id,
              }),
            ];
          }
          return existing;
        },
      },
    },
  },
  // The settings types have no id, but can safely be merged
  // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#configuring-merge-functions-for-types-rather-than-fields
  GlobalSettings: {
    merge: true,
  },
  CompanySettings: {
    merge: true,
  },
};

const dataIdFromObject = (object: Readonly<StoreObject>) => {
  if (object.__typename === 'DataGridResult') {
    return calculateDataGridCacheId(object as DataGridResult);
  }

  return defaultDataIdFromObject(object);
};

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies,
  possibleTypes,
  dataIdFromObject,
});

/**
 * Set initial values when we create cache variables.
 */
export const loggingOutVar = cache.makeVar<boolean>(false);
export const currentUserVar = cache.makeVar<CurrentUser | null>(null);
export const flashMessagesVar = cache.makeVar<Array<FlashMessage>>([]);
export const fatalErrorVar = cache.makeVar<string | null>(null);
export const appVersionVar = cache.makeVar<string | null>(null);

export type CurrentUser = Readonly<{
  id: string;
  createdAt: string | null;
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: boolean;
  twoFactorAuthenticationProviders: ReadonlyArray<Omit<TwoFactorProvider, '__typename'>>;
  userHash: string;
  timezone: string;
  roles: ReadonlyArray<string>;
  admin: boolean;
  company: {
    id: string;
    debuggingEnabled: boolean;
    features: ReadonlyArray<{
      key: string;
      value: boolean;
    }>;
  };
  challenges: ReadonlyArray<{
    key: string;
  }>;
  activePlatforms: ReadonlyArray<{
    id: string;
    platformKey: string;
    title: string;
  }>;
}>;
