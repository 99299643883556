type BatchContainingShipmentOrders = {
  shipments: ReadonlyArray<{
    order?: { platform: { additionalTrackingEmailCopy: string } } | null;
  }>;
};

function getAdditionalTrackingEmailCopies(batch: BatchContainingShipmentOrders) {
  const uniqueAdditionalTrackingEmailCopies: (string | undefined)[] = [
    ...new Set(
      batch.shipments.map((shipment) => shipment.order?.platform.additionalTrackingEmailCopy),
    ),
  ];
  const additionalTrackingEmailCopiesWithoutUndefined: string[] =
    uniqueAdditionalTrackingEmailCopies.filter(
      (item: string | undefined) => item !== undefined,
    ) as string[];
  return additionalTrackingEmailCopiesWithoutUndefined;
}

export default getAdditionalTrackingEmailCopies;
