import { downloadUrl } from '@ps/printLabels';
import Button from '../../components/form/Button';
import ProgressButton from '../../components/form/ProgressButton';

type ScanformButtonProps = {
  canCreateScanform: boolean;
  scanformUrl: string | null;
  loading: boolean;
  onCreateScanfrorm: () => void;
};

function ScanformButton({
  canCreateScanform,
  scanformUrl,
  loading,
  onCreateScanfrorm,
}: ScanformButtonProps) {
  if (canCreateScanform && !scanformUrl) {
    return (
      <ProgressButton
        variant="info"
        fullWidth
        progress={loading}
        data-testid="create-scanform-button"
        onClick={onCreateScanfrorm}
      >
        Create SCAN Form
      </ProgressButton>
    );
  }

  if (!canCreateScanform && scanformUrl) {
    return (
      <Button
        variant="info"
        fullWidth
        data-testid="download-scanform-button"
        onClick={(event) => {
          event.stopPropagation();
          downloadUrl(scanformUrl);
        }}
      >
        Download SCAN Form
      </Button>
    );
  }

  return null;
}

export default ScanformButton;
