import { addRumCustomAction } from '../../utils/addRumCustomAttributes';
import Button from '../form/Button';
import { Col, Row } from '../layout/Grid';
import { Styled } from './AddPaymentMethodModal';
import Modal from './Modal';

export type PlaidRelinkingHelpModalProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export default function PlaidRelinkingHelpModal({
  open,
  onClose,
  onSuccess,
}: PlaidRelinkingHelpModalProps) {
  return (
    <Modal
      title="Reconnect your Bank Account"
      width={600}
      open={open}
      onOpen={() => {
        addRumCustomAction('plaid_relinking_help_modal_open', {
          event: 'plaid_relinking_help_modal_open',
        });
      }}
      onClose={onClose}
      datadogActionClose="plaid_relinking_help_modal_cancel"
      datadogActionBackdrop="plaid_relinking_help_modal_cancel"
    >
      <Styled.ModalContainer id="modalBody">
        <Row>
          <Col>
            <p>
              Your bank needs to verify your account to continue. Please provide the required
              information to complete your purchase.
            </p>

            <Styled.Center style={{ height: '150px' }}>
              <Button
                size="xLarge"
                variant="success"
                onClick={() => {
                  onSuccess();
                }}
              >
                Reconnect Bank
              </Button>
            </Styled.Center>
          </Col>
        </Row>
      </Styled.ModalContainer>
    </Modal>
  );
}
