type PriceSummaryForCalculatingTotalCost = ReadonlyArray<{
  isRefunded: boolean;
  isReturnLabel: boolean;
  usedReturnPrice: number;
  totalPrice: number;
}>;

function calculateTotalCostFromPriceSummary(priceSummary: PriceSummaryForCalculatingTotalCost) {
  return priceSummary.reduce(
    (p, c) => p + (c.isRefunded || c.isReturnLabel ? c.usedReturnPrice : c.totalPrice), // if item is refunded it should not be added to or subtracted from the total cost, if it's a return label it should be added only if it's purchased
    0,
  );
}

export default calculateTotalCostFromPriceSummary;
