import { gql } from '../../gql';

const buyBatchMutation = gql(/* GraphQL */ `
  mutation BuyBatchMutation(
    $id: ID!
    $rateSelection: [RateSelectionInput!]!
    $paymentSourceId: ID!
    $shipDate: DateTime!
    $totalCharge: Float!
    $mailTemplateId: ID
    $notifyRecipientsDate: String
  ) {
    buyBatch(
      id: $id
      rateSelection: $rateSelection
      paymentSourceId: $paymentSourceId
      shipDate: $shipDate
      totalCharge: $totalCharge
      mailTemplateId: $mailTemplateId
      notifyRecipientsDate: $notifyRecipientsDate
    ) {
      id
      step
      shipDate
      ...RunningProcessFragment
    }
  }
`);

export default buyBatchMutation;
