import { useQuery } from '@apollo/client';
import { BuyPage } from '@ps/buy';
import { BatchDetailsPage, ShipmentDetailsPage } from '@ps/complete';
import { getBatchRefundSuccessMessage } from '@ps/refundLabels';
import { useEffect } from 'react';
import { dispatch } from 'use-bus';
import { setFlashMessage } from '../../../apollo/cache/flashMessages';
import { BATCH_PROCESS_POLL_INTERVAL, UiEvents } from '../../../constants';
import { BatchStatus } from '../../../gql/graphql';
import useLogger from '../../../hooks/useLogger';
import useNavigateOrHref from '../../../hooks/useNavigateOrHref';
import batchProcessStatusQuery from '../../../operations/queries/batchProcessStatus';
import isSingleCarrierBatch from '../../../utils/isSingleCarrierBatch';
import triggerGoogleTagManagerCustomEvent from '../../../utils/triggerGoogleTagManagerCustomEvent';
import PageLoading from '../../loading/PageLoading';
import LoadingAnimation from './LoadingAnimation';
import UploadFormPage from './UploadFormPage';

const loadingSteps: BatchStatus[] = ['VALIDATED', 'IMPORTED', 'RATING', 'PURCHASING', 'REFUNDING'];

type ShipmentWorkflowProps = {
  batchId: string;
  shipmentId?: string;
  forwardedMailClassKey?: string;
};

export default function ShipmentWorkflow({
  batchId,
  shipmentId,
  forwardedMailClassKey,
}: ShipmentWorkflowProps) {
  const logger = useLogger();
  const navigateOrHref = useNavigateOrHref();

  // (PP-14790) Event required for current Google Tag tracking campaign
  useEffect(() => {
    triggerGoogleTagManagerCustomEvent('page_view /ship/batch');
  }, []);

  // Load basic batch information
  const { data, previousData, startPolling, stopPolling } = useQuery(batchProcessStatusQuery, {
    variables: { id: batchId },
    errorPolicy: 'all',
    onError: (error) => {
      // Log error and go back to ship overview page
      logger.error('Error occurred while fetching batch process status', undefined, error);
      navigateOrHref('/ship');
    },
    onCompleted: ({ batch: { step } }) => {
      // Start polling state when batch process is running or we hit an intermediate step
      if (loadingSteps.includes(step)) {
        startPolling(BATCH_PROCESS_POLL_INTERVAL);
      }
    },
  });

  useEffect(() => {
    if (!previousData || !data) {
      return;
    }

    // Start or stop polling when presence of a background process changes
    if (loadingSteps.includes(data.batch.step) && !loadingSteps.includes(previousData.batch.step)) {
      startPolling(BATCH_PROCESS_POLL_INTERVAL);
    }
    if (!loadingSteps.includes(data.batch.step) && loadingSteps.includes(previousData.batch.step)) {
      stopPolling();
    }

    // Trigger logo animation when purchase completed
    if (previousData.batch.step !== 'BILLED' && data.batch.step === 'BILLED') {
      dispatch(UiEvents.BatchPurchased);
    }

    // Show message when refund background process completed
    if (
      previousData.batch.step === 'REFUNDING' &&
      (data.batch.status === 'REFUNDED' ||
        data.batch.status === 'PARTLY_REFUNDED' ||
        data.batch.status === 'REFUND_PENDING')
    ) {
      const { isUpsOnlyBatch, isUspsOnlyBatch } = isSingleCarrierBatch(data.batch);
      setFlashMessage(
        getBatchRefundSuccessMessage(
          // TODO Currently this is bugged, and canInstantRefundBatch is always false
          //      when the batch reaches its REFUNDED state, so the instant message
          //      will never be shown.
          data.batch.canInstantRefundBatch,
          isUspsOnlyBatch,
          isUpsOnlyBatch,
        ),
        'success',
        -1,
        true,
      );
    }
  }, [data, previousData, startPolling, stopPolling]);

  // Show loading spinner while data is loaded
  if (data === undefined) {
    return <PageLoading />;
  }

  const { step, runningProcess, numShipments, shipments } = data.batch;

  // Show loading animation when there is a running background process, but only if we are handling multiple shipments.
  if (loadingSteps.includes(step)) {
    if (runningProcess && numShipments > 1) {
      return <LoadingAnimation runningProcess={runningProcess} />;
    }

    return <PageLoading />;
  }

  switch (step) {
    case 'NEW':
      return <UploadFormPage />;
    case 'RATED':
      return <BuyPage entityId={batchId} forwardedMailClassKey={forwardedMailClassKey} />;
    case 'ERROR':
    case 'BILLED':
    case 'REFUNDED':
      return shipmentId || numShipments === 1 ? (
        <ShipmentDetailsPage
          bridgeBatchId={batchId}
          bridgeShipmentId={shipmentId ?? shipments[0].id}
        />
      ) : (
        <BatchDetailsPage bridgeBatchId={batchId} />
      );
    case 'VALIDATED':
    case 'IMPORTED':
    case 'RATING':
    case 'PURCHASING':
    case 'REFUNDING':
      // These are handled above and will show a loading animation or spinner.
      throw Error(`Unhandled loading batch step case ${step}`);
    default:
      // If the exhaustiveCheck line shows an error, the switch is missing a case
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: string = step;

      // This should never happen
      throw new Error(`Unhandled batch step case ${exhaustiveCheck}`);
  }
}
