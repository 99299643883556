import { ReactNode } from 'react';
import nl2br from 'react-nl2br';
import type { CellContext } from '@tanstack/react-table';

export type TextCellSettings = {
  splitOnComma?: boolean;
  stripBbCodes?: boolean;
  replacements?: {
    [searchString: string]: (searchString: string) => ReactNode;
  };
};

export default function TextCell<TData, TValue>(
  props: CellContext<TData, TValue>, // row could be anything, and value could be too
) {
  const value = props.getValue();
  const settings = (props.column.columnDef.meta?.cellSettings ?? {}) as TextCellSettings;

  const { splitOnComma, stripBbCodes, replacements } = settings;

  let val = String(value || '');

  if (splitOnComma) {
    val = val.replace(/, /g, '\n');
  }

  if (stripBbCodes) {
    val = val.replace(/\[[^\]]*\]/g, '');
  }

  let nodes: ReactNode[] = nl2br(val);

  Object.entries(replacements ?? {}).forEach((entry) => {
    const [searchString, replacer] = entry;

    nodes = nodes.map((node) => {
      if (typeof node === 'string') {
        const index = node.indexOf(searchString);

        if (index >= 0) {
          return [
            node.slice(0, index),
            replacer(searchString),
            node.slice(index + searchString.length),
          ];
        }
      }

      return node;
    });
  });

  return nodes;
}
