import type { CellContext } from '@tanstack/react-table';
import { DATE_FORMAT } from '../../../constants';
import useDateInUserTimezone from '../../../hooks/useDateInUserTimezone';

export type DateTimeCellSettings = {
  timezone?: string;
  showWeekday?: boolean;
};

export default function DateTimeCell<TData, TValue>(props: CellContext<TData, TValue>) {
  const { formatDate } = useDateInUserTimezone();

  const value = props.getValue() as string | Date;

  if (!value) {
    return '';
  }

  return formatDate('UTC', value, `${DATE_FORMAT.usDate} ${DATE_FORMAT.time12NoLeadingZero}`);
}
