import { gql } from '../../gql';

const refundBatchMutation = gql(/* GraphQL */ `
  mutation RefundBatchMutation($id: ID!) {
    refundBatch(id: $id) {
      id
      step
      ...RunningProcessFragment
    }
  }
`);

export default refundBatchMutation;
