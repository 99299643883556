import {
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import { ScreenClassProvider } from 'react-grid-system';
import { css } from '@emotion/react';
import focusableSelectors from 'focusable-selectors';
import usePortalContainer from '../../hooks/usePortalContainer';
import useKeyPressed from '../../hooks/useKeyPressed';
import useElementResizeObserver from '../../hooks/useElementResizeObserver';
import { ButtonProps } from '../form/Button';
import { COLOR, GREYSCALE } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { MEDIA_QUERY } from '../../styles/breakpoints';
import Z_INDEX from '../../styles/zindex';
import { LinkButton } from '../Link';
import { TYPOGRAPHY } from '../../styles/typography';
import { BORDER_RADIUS } from '../../styles/borders';
import withOpacity from '../../utils/withOpacity';
import useWindowSize from '../../hooks/useWindowSize';
import useIsMobile from '../../hooks/useIsMobile';

import ProgressButton from '../form/ProgressButton';
import ScrollBlocker from '../ScrollBlocker';

import Icon from '../Icon';

export type ModalTheme = 'dark' | 'light';

type ContainerProps = {
  dialogExceedsScreenHeight: boolean;
  forceMobileScrollbars?: boolean;
  overScrollBehaviour?: string;
  showFadeIn?: boolean;
};

// Module-scope state on the last element that was focused before the modal opened
// so we can restore focus when the modal closes again.
let lastFocusedElement: HTMLElement | null = null;

// The list of elements that should be hidden from screen readers when the modal is open.
// Hardcoding this here is not ideal, but we'll live with it until we switch to native dialog.
const ARIA_HIDDEN_ELEMENTS = ['#app-container', '.intercom-lightweight-app'];

/* this only works on android devices, iOS does not show scrollbars, requested by Bjorn */
const getScrollBarCss = (forceMobileScrollbars: boolean) => {
  if (forceMobileScrollbars) {
    return `
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 12px;
    }

    ::-webkit-scrollbar:horizontal {
      height: 12px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${GREYSCALE.grey40};
      border-radius: 2px;
    }

    ::-webkit-scrollbar-track {
      background-color: ${GREYSCALE.grey20};
    }
`;
  }
  return '';
};

const Styled = {
  Container: styled('div')<ContainerProps>`
    overflow: auto;
    overflow-y: scroll;
    overscroll-behavior: ${(props: ContainerProps) => props.overScrollBehaviour};
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${Z_INDEX.modal};
    outline: 0;
    background-color: ${withOpacity(GREYSCALE.black, 0.5)};
    ${(props: ContainerProps) =>
      props.showFadeIn
        ? css`
            animation: 150ms modal-fadein;

            @keyframes modal-fadein {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }
          `
        : ''};

    // display: ${(props: ContainerProps) => (props.dialogExceedsScreenHeight ? 'block' : 'flex')};
    // ${(props: ContainerProps) => !props.dialogExceedsScreenHeight && 'align-items: center'};

    @media print {
      overflow-y: auto;
    }

    ${(props: ContainerProps) => getScrollBarCss(props.forceMobileScrollbars || false)};
  `,
  Dialog: styled.div<{ theme: ModalTheme; showFadeIn: boolean }>`
    position: relative;
    overflow: hidden;
    border-radius: ${BORDER_RADIUS.sm};
    box-shadow: 0 3px 9px ${withOpacity(GREYSCALE.black, 0.5)};
    background-clip: padding-box;
    outline: 0;
    margin: ${SPACING.xxl} ${SPACING.auto};
    max-width: 100vw;
    color: ${({ theme }) => (theme === 'dark' ? GREYSCALE.grey20 : 'inherit')};
    // the pdf viewer generates error text within a p that we also need to target specifically in dark mode.
    p {
      color: ${({ theme }) => (theme === 'dark' ? GREYSCALE.grey20 : 'inherit')};
    }
    background-color: ${({ theme }) => (theme === 'light' ? GREYSCALE.white : GREYSCALE.black)};

    ${({ showFadeIn }) =>
      showFadeIn
        ? css`
            animation: 300ms modal-slidedown;

            @keyframes modal-slidedown {
              from {
                top: -100px;
                opacity: 0;
              }
              to {
                top: 0;
                opacity: 1;
              }
            }
          `
        : ''};

    @media (min-width: ${MEDIA_QUERY.smMin}) {
      box-shadow: 0 5px 15px ${withOpacity(GREYSCALE.black, 0.5)};
    }

    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      max-width: 100%;
      width: auto !important;
      border-radius: ${BORDER_RADIUS.none}; // No rounded corners when fullscreen
      margin: ${SPACING.none};
      padding-bottom: 80px; // Leave space for Intercom button
    }
    @media print {
      box-shadow: none;
    }
  `,
  ModalHeader: styled.div`
    margin: ${SPACING.xxl} ${SPACING.xxl} ${SPACING.none};

    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      margin: ${SPACING.xxl} ${SPACING.xl} ${SPACING.none};
    }
  `,
  CloseIcon: styled.button<{ theme: ModalTheme }>`
    position: absolute;
    top: 0;
    right: 0;
    width: ${SPACING.xxl};
    height: ${SPACING.xxl};
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    background: none;
    border: none;
    :focus-visible {
      outline-color: ${COLOR.blue};
      outline-offset: -4px;
    }

    color: ${({ theme }) => (theme === 'light' ? GREYSCALE.grey50 : GREYSCALE.white)};
    cursor: pointer;

    :hover {
      color: ${({ theme }) => (theme === 'light' ? GREYSCALE.grey60 : GREYSCALE.white)};
    }
  `,
  DialogTitle: styled.h1`
    // smaller bottom margin to account for font baseline
    margin: ${SPACING.none} ${SPACING.xl} 35px ${SPACING.none};

    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      margin-bottom: 35px;
    }
  `,
  ModalContent: styled.div`
    position: relative;
    margin: ${SPACING.none} ${SPACING.xxl} ${SPACING.xxl};

    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      margin: -${SPACING.xl} ${SPACING.xl} ${SPACING.xl};
    }
  `,

  ModalButtonBarWrapper: styled.div`
    margin-top: ${SPACING.xxl};
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      display: block;
      margin-top: ${SPACING.xl};

      > * {
        width: 100%;
      }
    }
  `,
};

export type ModalButtonBarProps = {
  buttonText: string;
  buttonType?: ButtonProps['type'];
  buttonOnClick?: ButtonProps['onClick'];
  buttonVariant?: ButtonProps['variant'];
  buttonSize?: 'large' | 'xLarge';
  buttonDisabled?: ButtonProps['disabled'];
  buttonProgress?: boolean;
  cancelText?: string;
  cancelDisabled?: ButtonProps['disabled'];
  cancelOnClick?: ButtonProps['onClick'];
};

export const CancelLinkButton = styled(LinkButton)<{
  variant: 'danger' | 'primary';
}>`
  padding: ${SPACING.xl} ${SPACING.none};
  font-size: ${TYPOGRAPHY.fontSize.sm};
  color: ${(props) => {
    switch (props.variant) {
      case 'primary':
        return COLOR.blue;
      case 'danger':
        return COLOR.red;
      default:
        return 'inherit';
    }
  }};
  :disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }
`;

export function ModalButtonBar(props: ModalButtonBarProps) {
  const {
    buttonText,
    buttonType,
    buttonOnClick,
    buttonVariant = 'primary',
    buttonSize = 'large',
    buttonDisabled,
    buttonProgress,
    cancelText,
    cancelDisabled,
    cancelOnClick,
  } = props;

  const { isMobile } = useIsMobile();

  return (
    <Styled.ModalButtonBarWrapper>
      <ProgressButton
        type={buttonType}
        onClick={buttonOnClick}
        variant={buttonVariant}
        fullWidth={isMobile}
        size={isMobile ? 'large' : buttonSize}
        disabled={buttonDisabled}
        progress={buttonProgress}
      >
        {buttonText}
      </ProgressButton>
      {cancelText && (
        <CancelLinkButton
          variant={buttonVariant === 'danger' ? 'primary' : 'danger'}
          disabled={cancelDisabled}
          onClick={cancelOnClick}
        >
          {cancelText}
        </CancelLinkButton>
      )}
    </Styled.ModalButtonBarWrapper>
  );
}

function ModalScreenClassProvider({ children }: PropsWithChildren<object>) {
  useEffect(() => {
    // Trigger resize when showing dialog so that the correct grid screenClass will be set
    // https://github.com/sealninja/react-grid-system/issues/176
    window.dispatchEvent(new Event('resize'));
  }, []);

  return <ScreenClassProvider useOwnWidth>{children}</ScreenClassProvider>;
}

type ChildrenProps = {
  title?: string;
  width?: number;
  height?: number;
  open: boolean;
  closable: boolean;
  closeDialog: () => void;
  closeDialogIfClosable: () => void;
};
type ChildrenFunc = (props: ChildrenProps) => ReactNode;

export type ModalProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
  title?: string;
  width?: number;
  height?: number;
  open: boolean;
  closable?: boolean;
  onBeforeOpen?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  onConfirm?: () => boolean;
  onAfterClose?: () => void;
  theme?: ModalTheme;
  children: ReactNode | ChildrenFunc;
  forceMobileScrollbars?: boolean;
  overScrollBehaviour?: string;
  showFadeIn?: boolean;
  datadogActionClose?: string;
  datadogActionBackdrop?: string;
};

export type ModalRef = {
  scrollToTop: () => void;
  closeDialog: () => void;
  closeDialogIfClosable: () => void;
  closable: boolean;
  title?: string;
  width?: number;
  height?: number;
  open: boolean;
};

function ModalComponent(
  {
    title,
    width,
    height,
    open,
    onBeforeOpen,
    onOpen,
    onClose,
    onConfirm,
    onAfterClose,
    closable = true,
    theme = 'light',
    children,
    forceMobileScrollbars = false,
    overScrollBehaviour = 'auto',
    showFadeIn = true,
    datadogActionClose,
    datadogActionBackdrop,
    ...props
  }: ModalProps,
  ref: Ref<ModalRef>,
) {
  const portalContainer = usePortalContainer();
  const [showDialog, setShowDialog] = useState(open);
  const [dialogExceedsScreenHeight, setDialogExceedsScreenHeight] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<HTMLDivElement>(null);
  const titleId = useId();
  const [, dialogHeight] = useElementResizeObserver(dialogRef.current as HTMLElement);
  const [, windowHeight] = useWindowSize();
  const scrollToTop = useCallback(() => containerRef.current?.scrollTo({ top: 0 }), []);

  const closeDialog = useCallback(() => onClose && onClose(), [onClose]);
  const closeDialogIfClosable = useCallback(
    () => closable && closeDialog(),
    [closable, closeDialog],
  );
  useKeyPressed('Escape', closeDialogIfClosable);

  useEffect(() => {
    const handleFocusEvent = (event: FocusEvent) => {
      const dlg = dialogRef.current;

      // If there is no dialog of focused element, don't bother
      if (!dlg || !event.target) {
        return;
      }

      const newFocusElement = event.target as HTMLElement;
      const prevFocusElement = event.relatedTarget as HTMLElement | null;

      // Get all focusable elements in the modal
      const isVisibleElement = (element: HTMLElement) =>
        Boolean(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
      const focusableElements = Array.from(
        dlg.querySelectorAll<HTMLElement>(focusableSelectors.join(',')),
      ).filter(isVisibleElement);

      // Check if the newly focused element is part of the modal. In that case we don't want
      // to change the focus.
      let currentElement = newFocusElement;
      while (currentElement.parentElement !== null) {
        currentElement = currentElement.parentElement;

        if (currentElement === dlg) {
          return;
        }
      }

      event.preventDefault();

      // At this point the focus is outside of the modal, so we put it back on the first or last
      // focusable element that is in the modal, based on where the focus was before.
      if (prevFocusElement === focusableElements[0]) {
        focusableElements[focusableElements.length - 1]?.focus();
      } else {
        focusableElements[0]?.focus();
      }
    };

    // Check for full support of the :not() selector that is used in focusableSelectors
    try {
      document.querySelector(':not([inert] *)');
    } catch (error) {
      // Don't handle focus events in case it is not supported
      return () => {};
    }

    // Capture focus events
    document.body.addEventListener('focus', handleFocusEvent, true);

    return () => {
      document.body.removeEventListener('focus', handleFocusEvent, true);
    };
  }, []);

  useEffect(() => {
    if (showDialog && dialogRef?.current) {
      setDialogExceedsScreenHeight(dialogHeight > windowHeight);
    }
  }, [showDialog, dialogRef, dialogHeight, windowHeight]);

  useEffect(() => {
    if (open && !showDialog) {
      if (onBeforeOpen) {
        onBeforeOpen();
      }

      setTimeout(() => {
        setShowDialog(true);

        // Store reference to element in focus when modal opened
        if (lastFocusedElement === null && document.activeElement instanceof HTMLElement) {
          lastFocusedElement = document.activeElement;
        }

        // Hide rest of the page from screen readers
        document
          .querySelectorAll<HTMLElement>(ARIA_HIDDEN_ELEMENTS.join(','))
          .forEach((element) => {
            // eslint-disable-next-line no-param-reassign
            element.ariaHidden = 'true';
          });

        setTimeout(() => {
          if (onOpen) {
            onOpen();
          }
        });
      }, 150);
    }

    if (!open && showDialog) {
      setShowDialog(false);

      // Show rest of the page to screen readers again
      document.querySelectorAll<HTMLElement>(ARIA_HIDDEN_ELEMENTS.join(',')).forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.ariaHidden = 'false';
      });

      // Store reference to element in focus when modal opened
      lastFocusedElement?.focus();
      lastFocusedElement = null;

      setTimeout(() => {
        if (onAfterClose) {
          onAfterClose();
        }
      });
    }
    /*
      onBeforeOpen, onAfterClose, onOpen,  removed from dependency list because the respective functions are
      called at least twice when the function body is set from undefined/void to the function body from outside

      => PP-13085 AddPaymentMethodModal onOpen is called twice
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, showDialog]);

  useImperativeHandle(
    ref,
    () => ({
      scrollToTop,
      closeDialog,
      closeDialogIfClosable,
      closable,
      title,
      width,
      height,
      open,
    }),
    [scrollToTop, closeDialog, closeDialogIfClosable, closable, title, width, height, open],
  );

  if (!open) {
    return null;
  }

  /**
   * If we provide a function as Modal children, we call it with modal state
   * and helper functions.
   */
  const modalContent =
    typeof children === 'function'
      ? children({
          title,
          width,
          height,
          open,
          closable,
          closeDialog,
          closeDialogIfClosable,
        })
      : children;

  const modal = (
    <>
      <ScrollBlocker />
      <Styled.Container
        ref={containerRef}
        id="modal"
        onClick={() => onConfirm?.() ?? closeDialogIfClosable()}
        dialogExceedsScreenHeight={dialogExceedsScreenHeight}
        forceMobileScrollbars={forceMobileScrollbars}
        overScrollBehaviour={overScrollBehaviour}
        showFadeIn={showFadeIn}
        data-dd-action-name={datadogActionBackdrop}
        aria-hidden="false"
      >
        {showDialog && (
          <Styled.Dialog
            ref={dialogRef}
            theme={theme}
            onClick={(event) => event.stopPropagation()}
            style={{ width, height }}
            showFadeIn={showFadeIn}
            role="dialog"
            aria-modal="true"
            aria-labelledby={titleId}
            {...props}
          >
            <ModalScreenClassProvider>
              <Styled.ModalHeader>
                {closable && (
                  <Styled.CloseIcon
                    autoFocus
                    theme={theme}
                    id="close"
                    onClick={() => onConfirm?.() ?? closeDialogIfClosable()}
                    aria-label="Close"
                    data-dd-action-name={datadogActionClose}
                  >
                    <Icon icon="times" size="lg" />
                  </Styled.CloseIcon>
                )}
                {title && (
                  <Styled.DialogTitle id={titleId} data-testid="modal-title">
                    {title}
                  </Styled.DialogTitle>
                )}
              </Styled.ModalHeader>
              <Styled.ModalContent>{modalContent}</Styled.ModalContent>
            </ModalScreenClassProvider>
          </Styled.Dialog>
        )}
      </Styled.Container>
    </>
  );

  return portalContainer && createPortal(modal, portalContainer);
}

const Modal = forwardRef(ModalComponent);
export default Modal;
