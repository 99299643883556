import { useRef, useState } from 'react';
import type { Row as TableRow } from '@tanstack/react-table';
import styled from '@emotion/styled';
import { PageContainer, Col, Row } from '../../layout/Grid';
import ConnectedDataGrid from '../../dataGrid/ConnectedDataGrid';
import PageHeader from '../../layout/PageHeader';
import { DataGridImperativeHandleRef } from '../../dataGrid/DataGrid';
import ProgressButton from '../../form/ProgressButton';
import PageTitle from '../../layout/PageTitle';
import IntercomArticleLink from '../../IntercomArticleLink';
import { SPACING } from '../../../styles/spacing';

const Styled = {
  Subtitle: styled.p`
    padding-top: ${SPACING.md};
  `,
};

function CarrierAdjustmentsPage() {
  const [isExporting, setIsExporting] = useState(false);
  const imperativeHandleRef = useRef<DataGridImperativeHandleRef>(null);

  const makeLinkToShipment = (row: TableRow<{ shipment_id: string }>) => {
    // if (event.target.tagName.toLowerCase() === 'a') return '';  TODO: work out the purpose of this and why it was implemented

    return `/ship/shipment?id=${row.original.shipment_id}`;
  };

  const getInitialRunId = () => {
    const reconciliationRunId = new URLSearchParams(window.location.search).get('run');

    if (!reconciliationRunId) {
      return [];
    }

    return [{ id: 'reconciliation_run_id', value: [reconciliationRunId] }];
  };

  return (
    <PageContainer>
      <PageHeader
        title={
          <div>
            <PageTitle>Carrier Adjustment Report</PageTitle>
            <Styled.Subtitle>
              If the carrier determines that you under or overpaid, or used a different package or
              service from what you entered when buying your label, they will automatically charge
              or credit your Pirate Ship account for the difference.{' '}
              <IntercomArticleLink href="https://support.pirateship.com/en/articles/5954655-what-are-carrier-adjustments">
                Check out this article
              </IntercomArticleLink>{' '}
              for help 👍
            </Styled.Subtitle>
          </div>
        }
      />
      <Row>
        <Col md={12}>
          <ConnectedDataGrid
            imperativeHandleRef={imperativeHandleRef}
            queryName="carrierAdjustmentReport"
            onRowClick={makeLinkToShipment}
            rowActionLinkMakers={{
              view: makeLinkToShipment,
            }}
            rightSideSlot={
              <Col xs={12} md={3} spaceBelow>
                <ProgressButton
                  progress={isExporting}
                  disabled={isExporting}
                  fullWidth
                  size="medium"
                  variant="info"
                  onClick={async () => {
                    setIsExporting(true);
                    await imperativeHandleRef.current?.exportData();
                    setIsExporting(false);
                  }}
                >
                  Export
                </ProgressButton>
              </Col>
            }
            initialColumnFilters={getInitialRunId()}
          />
        </Col>
      </Row>
    </PageContainer>
  );
}

export default CarrierAdjustmentsPage;
