export type DateConstructorInput = string | Date;

/**
 * @description A date construction function that ignores the browser sensor and bypasses the different browser implementations of Date(). Always use this in the app instead of new Date().
 * @param dateConstructorInput the input used for making the new date.
 * @returns the date entered as a UTC date if a timezone offset is left out. Respects timezone offset otherwise.
 */
export default function newDate(dateConstructorInput: DateConstructorInput): Date {
  // the type of the input is string? ensure the browser is not interpreting the string as being in the browser timezone.
  if (typeof dateConstructorInput === 'string') {
    // if 'now' is explicitly entered, create a date with the current timestamp.
    if (dateConstructorInput === 'now') {
      return new Date();
    }
    // the date must be of the format YYYY-MM-DD, where:
    // the year must be 4 digit and the month and day may be 1 or 2 digits.
    // then, if the user ends the string with nothing or a Z, the time of the date will be set to 00:00:00.000.
    // otherwise, the time can be explicitly defined after the date, separated from the date with a T or a space, then hh:mm:ss.SSS, where:
    // the hours and minutes are required and must be 2 digits each, and the seconds and milliseconds are optional.
    // furthermore, the timezone offset can be defined after the time with a Z (no offset) or +-hh:mm.
    const [, year, m, d, hoursAndMinutesOrEnd, seconds, ms, timeOffset] =
      dateConstructorInput.match(
        /^(\d\d\d\d)-([01]?\d)-([0123]?\d)(Z$|$|[T ]\d\d:\d\d)(:\d\d)?([:.]\d\d\d)?(Z|[+-]\d\d:\d\d)?$/,
      ) ?? ['', '', '', '', '', ''];

    // fix single month and day strings to be 2-digit
    const month = m.length === 1 ? `0${m}` : m;
    const day = d.length === 1 ? `0${d}` : d;

    // if the date is YYYY-MM-DD only, then the fourth capturing group in the regex will be a Z or empty. In this case, add 00:00:00Z
    // to stop browser timezone interference
    const isYearMonthDayOnly = hoursAndMinutesOrEnd === 'Z' || !hoursAndMinutesOrEnd;
    if (isYearMonthDayOnly) {
      return new Date(`${year}-${month}-${day}T00:00Z`);
    }
    const hoursAndMinutes = `T${hoursAndMinutesOrEnd.slice(1)}`; // there are edge cases where time is handled differently when using T or a space, so always use T
    const milliseconds = ms ? `.${ms.slice(1)}` : undefined; // there are edge cases where millisecond time generation is handled differently when using : or a ., so always use .

    // if our hours and minutes are present, then reconstruct the datetime string as-is with a zulu tag to stop browser timezone interference
    return new Date(
      `${year}-${month}-${day}${hoursAndMinutes}${seconds ?? ''}${milliseconds ?? ''}${
        timeOffset ?? 'Z'
      }`,
    );
  }
  // the type of the input is a number (i.e. timestamp) or a date object. just copy it in that case, the browser will not interfere.
  return new Date(dateConstructorInput);
}
