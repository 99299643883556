import { ApolloError, useMutation, useSuspenseQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Form, Formik } from 'formik';
import { Suspense, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import * as yup from 'yup';
import { setFlashMessage } from '../../../apollo/cache/flashMessages';
import { NEW_SHIPMENT_PRESET_VALUE, NEW_WAREHOUSE_VALUE } from '../../../constants';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useNavigateOrHref from '../../../hooks/useNavigateOrHref';
import useShipmentPresetOptions from '../../../hooks/useShipmentPresetOptions';
import useWarehouseOptions from '../../../hooks/useWarehouseOptions';
import { useCompleteBatchFromUploadMutation } from '../../../operations/mutations/completeBatchFromUpload';
import { createScopedShipmentPresetMutation } from '../../../operations/mutations/createScopedPreset';
import { useCreateShipmentPresetMutation } from '../../../operations/mutations/createShipmentPreset';
import { useCreateWarehouseMutation } from '../../../operations/mutations/createWarehouse';
import { useDeleteBatchMutation } from '../../../operations/mutations/deleteBatch';
import { useUpdateShipmentPresetMutation } from '../../../operations/mutations/updateShipmentPreset';
import { batchUploadFormQuery } from '../../../operations/queries/batchUploadForm';
import { MEDIA_QUERY } from '../../../styles/breakpoints';
import getFilteredPackageTypes from '../../../utils/getFilteredPackageTypes';
import { BottomRowActions } from '../../form/BottomRowActions';
import Checkbox from '../../form/Checkbox';
import DropdownSelect from '../../form/DropdownSelect';
import ExpandableDropdownSelect from '../../form/ExpandableDropdownSelect';
import FormControl from '../../form/FormControl';
import Label from '../../form/Label';
import TextField from '../../form/TextField';
import { Col, PageContainer, Row } from '../../layout/Grid';
import PageHeader from '../../layout/PageHeader';
import ContentLoading from '../../loading/ContentLoading';
import ConfirmationModal from '../../modals/ConfirmationModal';
import CustomsFormSubform, { CustomsFormSubformValues } from '../../subforms/CustomsFormSubform';
import { customsFormValidationSchema } from '../../subforms/customsFormSubformUtils';
import ExtraServicesSubform, {
  ExtraServicesSubformValues,
} from '../../subforms/ExtraServicesSubform';
import { extraServicesValidationSchema } from '../../subforms/extraServicesSubformUtils';
import HazardousMaterialsSubform, {
  HazardousMaterialsSubformValues,
} from '../../subforms/HazardousMaterialsSubform';
import { hazardousMaterialsValidationSchema } from '../../subforms/hazardousMaterialsSubformUtils';
import PackageSubform, { PackageSubformValues } from '../../subforms/PackageSubform';
import { packageValidationSchema } from '../../subforms/packageSubformUtils';
import SaveOrUpdatePackageSubform, {
  SaveOrUpdatePackageSubformValues,
} from '../../subforms/saveOrUpdatePackageSubform';
import saveOrUpdatePackageSchema, {
  INITIAL_SAVE_OR_UPDATE_PACKAGE_SUBFORM_VALUES,
} from '../../subforms/saveOrUpdatePackageUtils';
import mapPresetToFormValues from '../../subforms/shipmentPresetFormUtils';
import type { WarehouseSubformValues } from '../../subforms/WarehouseSubform';
import WarehouseSubform from '../../subforms/WarehouseSubform';
import {
  INITIAL_WAREHOUSE_SUBFORM_VALUES,
  warehouseValidationSchema,
} from '../../subforms/warehouseSubformUtils';
import {
  getDefaultPreset,
  getInitialPackageDetailsValues as getEmptyPresetFormValues,
  getPresetTitle,
  isDefaultPreset,
  mapAddressInputToWarehouseAddress,
  mapValuesToPreset,
} from './uploadFormPageUtils';
import IntercomArticleLink from '../../IntercomArticleLink';
import PageTitle from '../../layout/PageTitle';

const Styled = {
  CheckboxWrapper: styled.div`
    display: flex;
    align-items: start;
    justify-content: center;
    height: 100%;
    margin-top: 22px;

    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      justify-content: flex-start;
    }
  `,
};

type UploadFormPageProps = {
  entityId?: string;
};

type ValidNamespaces = keyof UploadFormPageValues;

export type UploadFormPageValues = {
  title: string;
  package: PackageSubformValues;
  extraServices: ExtraServicesSubformValues;
  hazardousMaterials: HazardousMaterialsSubformValues;
  customsForm: CustomsFormSubformValues;
  warehouse: WarehouseSubformValues;
  warehouseId: string;
  shipmentPresetId: string;
  modifyPackage: boolean;
  saveOrUpdatePackage: SaveOrUpdatePackageSubformValues;
};

export default function UploadFormPage({ entityId }: UploadFormPageProps) {
  const { batchId } = useParams<'batchId'>();
  const id = entityId || batchId || '';
  const { data, refetch: refetchData } = useSuspenseQuery(batchUploadFormQuery, {
    variables: { id },
  });
  const packageTypes = useMemo(() => getFilteredPackageTypes(data), [data]);
  const [deleteBatch, { loading: isDeletingBatch }] = useDeleteBatchMutation();
  const [createWarehouse, { loading: isCreatingWarehouse }] = useCreateWarehouseMutation();
  const [createShipmentPreset, { loading: isCreatingPreset }] = useCreateShipmentPresetMutation();
  const [updateShipmentPreset, { loading: isUpdatingPreset }] = useUpdateShipmentPresetMutation();
  const [scopedShipmentPresetMutation, { loading: isScopingPreset }] = useMutation(
    createScopedShipmentPresetMutation,
  );
  const [
    completeBatchFromUploadMutation,
    { loading: isCompletingBatch, error: completeBatchFromUploadMutationError },
  ] = useCompleteBatchFromUploadMutation();
  const [currentUser] = useCurrentUser();
  const userName = currentUser ? `${currentUser?.firstName} ${currentUser?.lastName}` : '';

  const navigateOrHref = useNavigateOrHref();
  const [deleteBatchModalOpen, setDeleteBatchModalOpen] = useState(false);

  const uploadId = data.batch.upload?.id || '0';
  const warehouseOptions = useWarehouseOptions({
    data,
    lastOptionAllowsCreation: true,
  });

  const shipmentPresetOptions = useShipmentPresetOptions({
    shipmentPresets: data.company.shipmentPresets,
    packageTypes: data.carriers.flatMap((carrier) => carrier.packageTypes),
  });

  const hasShipmentPresets = data.company.shipmentPresets.length > 0;
  const initialShipmentPreset = getDefaultPreset(data, packageTypes);

  const isLoading =
    isScopingPreset ||
    isCompletingBatch ||
    isCreatingWarehouse ||
    isDeletingBatch ||
    isUpdatingPreset ||
    isCreatingPreset;

  async function submitUploadForm(values: UploadFormPageValues) {
    if (isLoading) {
      return;
    }

    const { warehouse, saveOrUpdatePackage, title, shipmentPresetId, warehouseId } = values;

    let tempShipmentPresetId = shipmentPresetId;
    let tempWarehouseId = warehouseId;
    try {
      // Step 1: Use a new address (warehouse)
      if (warehouseId === NEW_WAREHOUSE_VALUE) {
        const {
          saveAddressForReuse,
          useOriginAsReturnAddress,
          originAddress,
          returnAddress,
          title: warehouseTitle,
        } = warehouse;

        const { data: warehouseData } = await createWarehouse({
          awaitRefetchQueries: true,
          variables: {
            title: warehouseTitle,
            saveAddressForReuse, // create a new warehouse or not
            useOriginAsReturnAddress,
            originAddress: mapAddressInputToWarehouseAddress(originAddress),
            returnAddress: useOriginAsReturnAddress
              ? undefined
              : mapAddressInputToWarehouseAddress(returnAddress),
          },
        });
        if (warehouseData?.createWarehouse.id) {
          setFlashMessage('Warehouse created', 'success');
          tempWarehouseId = warehouseData.createWarehouse.id;
        }
      }

      // STEP 2: Handle Shipment Preset
      // Step 2.1: Create & save new shipment preset if desired and update tempShipmentPresetId
      if (saveOrUpdatePackage.createNewPreset) {
        const { data: newPresetData } = await createShipmentPreset({
          variables: {
            shipmentPreset: mapValuesToPreset({
              values,
              packageTypes,
              title: values.saveOrUpdatePackage.newPresetTitle,
            }),
            default: isDefaultPreset(data, shipmentPresetId),
          },
        });
        if (newPresetData?.createShipmentPreset.shipmentPreset.id) {
          setFlashMessage('New saved package created', 'success');
          tempShipmentPresetId = newPresetData.createShipmentPreset.shipmentPreset.id;
        }
      }

      // Step 2.2: OR update existing shipment preset if desired and update tempShipmentPresetId
      if (saveOrUpdatePackage.updatePreset) {
        const { data: updatedPresetData } = await updateShipmentPreset({
          variables: {
            shipmentPreset: mapValuesToPreset({
              values,
              packageTypes,
              title: getPresetTitle(data, shipmentPresetId),
              presetId: shipmentPresetId,
            }),
            default: isDefaultPreset(data, shipmentPresetId),
          },
        });
        if (updatedPresetData?.updateShipmentPreset.shipmentPreset.id) {
          setFlashMessage('Saved package updated', 'success');
          tempShipmentPresetId = updatedPresetData.updateShipmentPreset.shipmentPreset.id;
        }
      }

      // Step 2.3: OR create scoped (one-time) shipment preset and update tempShipmentPresetId
      // only create a scoped preset if the user does not want to save or update the existing one but has modified it
      if (
        !saveOrUpdatePackage.createNewPreset &&
        !saveOrUpdatePackage.updatePreset &&
        (shipmentPresetId === NEW_SHIPMENT_PRESET_VALUE || values.modifyPackage)
      ) {
        const { data: scopedPresetData } = await scopedShipmentPresetMutation({
          variables: {
            originalPresetId: values.shipmentPresetId,
            shipmentPresetInput: mapValuesToPreset({
              values,
              packageTypes,
              title: 'scoped Preset',
            }),
          },
        });
        if (scopedPresetData?.createScopedShipmentPreset.id) {
          tempShipmentPresetId = scopedPresetData?.createScopedShipmentPreset.id;
        }
      }

      // Step 3: Complete batch with new/existing warehouse and new/scoped/updated preset
      await completeBatchFromUploadMutation({
        variables: {
          batchId: id,
          batchTitle: title,
          warehouseId: tempWarehouseId,
          shipmentPresetId: tempShipmentPresetId,
        },
      });

      navigateOrHref(`/batch/${id}`);
    } catch (error) {
      if (error instanceof ApolloError) {
        error.graphQLErrors.forEach((err) => {
          setFlashMessage(err.message, 'danger');
        });
      }
      // If CompleteBatchFromUploadMutation fails, poll recently created data
      if (
        (completeBatchFromUploadMutationError && saveOrUpdatePackage.updatePreset) ||
        saveOrUpdatePackage.createNewPreset
      ) {
        refetchData();
      }

      if (
        completeBatchFromUploadMutationError &&
        warehouseId === NEW_WAREHOUSE_VALUE &&
        warehouse.saveAddressForReuse
      ) {
        refetchData();
      }
    }
  }

  return (
    <PageContainer>
      <Formik<UploadFormPageValues>
        initialValues={{
          title: data.batch.title,
          warehouseId: data.company.settings.defaultWarehouseId,
          warehouse: { ...INITIAL_WAREHOUSE_SUBFORM_VALUES },
          shipmentPresetId: initialShipmentPreset?.id ?? NEW_SHIPMENT_PRESET_VALUE,
          modifyPackage: !initialShipmentPreset,
          saveOrUpdatePackage: { ...INITIAL_SAVE_OR_UPDATE_PACKAGE_SUBFORM_VALUES },
          ...(initialShipmentPreset
            ? mapPresetToFormValues(initialShipmentPreset, data.batch.customsFormRequired)
            : getEmptyPresetFormValues(userName, data.batch.customsFormRequired)),
        }}
        onSubmit={(values) => submitUploadForm(values)}
        validationSchema={yup
          .object<UploadFormPageValues>({
            title: yup.string().required(),
            warehouseId: yup.string().required(),
            modifyPackage: yup.boolean(),
            warehouse: warehouseValidationSchema()
              .defined()
              .when('warehouseId', (warehouseId: string, schema: yup.ObjectSchema) =>
                warehouseId === NEW_WAREHOUSE_VALUE ? schema.required() : yup.object(),
              ),
            package: yup
              .mixed<PackageSubformValues>()
              .when('customsForm', (customsForm: CustomsFormSubformValues) =>
                packageValidationSchema(
                  data.shipmentBoundaries,
                  packageTypes,
                  customsForm,
                ).required(),
              ),
            extraServices: extraServicesValidationSchema().required(),
            hazardousMaterials: hazardousMaterialsValidationSchema().required(),
            customsForm: customsFormValidationSchema(
              data.batch.customsFormRequired,
              data.batch.hsCodeRequired,
              data.shipmentBoundaries.maxWeight,
            ).required(),
            shipmentPresetId: yup.string().required(),
            saveOrUpdatePackage: saveOrUpdatePackageSchema().required(),
          })
          .required()}
      >
        {({ values, errors, handleSubmit, setValues, setFieldValue }) => {
          const isNewPresetOptionSelected = values.shipmentPresetId === NEW_SHIPMENT_PRESET_VALUE;
          const forceEnabledPackageSubform = !hasShipmentPresets || isNewPresetOptionSelected;
          const showPackageSubform = values.modifyPackage || forceEnabledPackageSubform;

          return (
            <Form
              onSubmit={(event) => {
                // Pass event on to Formik
                handleSubmit(event);

                // If there are errors in the customs form, open up the package subform
                if (errors.customsForm) {
                  setFieldValue('modifyPackage' satisfies keyof UploadFormPageValues, true);

                  // Wait one cycle for the form to update and scroll to the customs form
                  setTimeout(() => {
                    const inputName =
                      'customsForm.customsItems' satisfies `${keyof UploadFormPageValues}.${keyof CustomsFormSubformValues}`;
                    const firstCustomsFormInput = document.querySelector(
                      `input[name^="${inputName}"]`,
                    );
                    if (firstCustomsFormInput instanceof HTMLInputElement) {
                      firstCustomsFormInput.scrollIntoView();
                      firstCustomsFormInput.focus();
                    }
                  }, 0);
                }
              }}
            >
              <ConfirmationModal
                title="Delete Labels?"
                open={deleteBatchModalOpen}
                onCancel={() => setDeleteBatchModalOpen(false)}
                onConfirm={async () => {
                  try {
                    await deleteBatch({ variables: { id } });
                    setFlashMessage(`Batch Deleted`, 'success');
                    navigateOrHref('/ship');
                  } catch (error) {
                    setFlashMessage((error as Error).message, 'danger');
                  }
                }}
                confirmationType="destructive"
                cancelButtonLabel="Cancel"
                confirmationButtonLabel="Continue"
                confirmationButtonProgress={isDeletingBatch}
              />
              <Row>
                <Col>
                  <PageHeader
                    title={
                      <div>
                        <PageTitle>Import from Upload</PageTitle>
                        <p>
                          If you’ve{' '}
                          <IntercomArticleLink href="https://support.pirateship.com/en/articles/2797613-how-do-i-upload-a-spreadsheet-with-different-weights-and-dimensions#step-2-upload-your-spreadsheet-to-pirate-ship">
                            mapped values from your spreadsheet for dimension and weight
                          </IntercomArticleLink>
                          , enter any value below in those fields. Your spreadsheet data will
                          overwrite what is entered here 👍
                        </p>
                      </div>
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>Batch Name</Label>
                </Col>
              </Row>
              <Row spaceBelow>
                <Col>
                  <FormControl as={TextField} name="title" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>Ship From</Label>
                </Col>
              </Row>
              <Row spaceBelow>
                <Col>
                  <FormControl
                    as={ExpandableDropdownSelect}
                    name="warehouseId"
                    options={warehouseOptions}
                    expandedContent={
                      values.warehouseId === NEW_WAREHOUSE_VALUE ? (
                        <Suspense
                          fallback={
                            <>
                              <ContentLoading />
                              <br />
                            </>
                          }
                        >
                          <WarehouseSubform<ValidNamespaces> namespace="warehouse" />
                        </Suspense>
                      ) : undefined
                    }
                    loading={isCreatingWarehouse || values.warehouseId === ''}
                    loadingHeight={64}
                  />
                </Col>
              </Row>
              {hasShipmentPresets && (
                <>
                  <Row>
                    <Col>
                      <Label>Package Details</Label>
                    </Col>
                  </Row>
                  <Row spaceBelow>
                    <Col md={9} sm={6}>
                      <FormControl
                        name="shipmentPresetId"
                        as={DropdownSelect}
                        options={shipmentPresetOptions}
                        onChange={async (newShipmentPresetId: string | number) => {
                          const preset = data.company.shipmentPresets.find(
                            ({ id: presetId }) => presetId === newShipmentPresetId,
                          );
                          const shipmentPresetId = newShipmentPresetId.toString();
                          await setValues({
                            ...values,
                            saveOrUpdatePackage: {
                              ...INITIAL_SAVE_OR_UPDATE_PACKAGE_SUBFORM_VALUES,
                            },
                            // modifyPackage: shipmentPresetId === NEW_SHIPMENT_PRESET_VALUE,
                            shipmentPresetId,
                            ...(preset
                              ? mapPresetToFormValues(preset, data.batch.customsFormRequired)
                              : getEmptyPresetFormValues(userName, data.batch.customsFormRequired)),
                          });
                        }}
                      />
                    </Col>
                    <Col md={3} sm={6}>
                      <Styled.CheckboxWrapper>
                        <FormControl
                          as={Checkbox}
                          type="checkbox"
                          name="modifyPackage"
                          label="Modify package"
                          checked={showPackageSubform}
                          disabled={forceEnabledPackageSubform}
                        />
                      </Styled.CheckboxWrapper>
                    </Col>
                  </Row>
                </>
              )}
              {showPackageSubform && (
                <>
                  <Row>
                    <Col md={12}>
                      <PackageSubform<ValidNamespaces>
                        namespace="package"
                        packageTypes={packageTypes}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <ExtraServicesSubform<ValidNamespaces> namespace="extraServices" />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <HazardousMaterialsSubform<ValidNamespaces> namespace="hazardousMaterials" />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <CustomsFormSubform<ValidNamespaces>
                        namespace="customsForm"
                        required={data.batch.customsFormRequired}
                      />
                    </Col>
                  </Row>
                  <Row spaceBelow>
                    <Col md={12}>
                      <SaveOrUpdatePackageSubform<ValidNamespaces>
                        namespace="saveOrUpdatePackage"
                        isNewPreset={isNewPresetOptionSelected}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <BottomRowActions
                primaryAction={{
                  type: 'submit',
                  title: 'Get Rates',
                  variant: 'success',
                  size: 'xLarge',
                  progress: isLoading,
                }}
                secondaryActions={[
                  {
                    title: 'Previous Step',
                    variant: 'primary',
                    onClick: () =>
                      navigateOrHref(
                        `/upload/map/file/${uploadId}`, // ShipmentWorkflow feature flag is turned on
                        `/upload/map?file=${uploadId}`, // ShipmentWorkflow feature flag is turned off
                      ),
                  },
                  {
                    title: 'Cancel & Deleting Labels',
                    variant: 'danger',
                    onClick: () => setDeleteBatchModalOpen(true),
                  },
                ]}
              />
            </Form>
          );
        }}
      </Formik>
    </PageContainer>
  );
}
