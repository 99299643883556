import { ComponentProps, forwardRef, JSXElementConstructor, Ref, useId } from 'react';
import { useField } from 'formik';
import Shimmer from '../loading/Shimmer';
import ErrorMessage, { NoHeightErrorMessage } from './ErrorMessage';
import convertBbcode from '../../utils/convertBbcode';

// this is particularly hard to type because we have a chicken and egg situation:
// our ComponentProps rely on the component type to be inferred, but the FormComponent relies on its own props to be inferred.
// it might be worth looking into extracting the component's props into its own object prop so we can type it properly in the future,
// but this would touch all form components implementations.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormComponent<P = any> = JSXElementConstructor<P> | 'input' | 'select' | 'textarea';
type FormControlAdditionalProps = {
  loading?: boolean;
  loadingHeight?: number;
};

export type FormControlProps<C extends FormComponent, P = ComponentProps<C>> = Omit<
  P,
  'as' | 'name' | 'form' | keyof FormControlAdditionalProps
> &
  FormControlAdditionalProps & {
    name: string;
    as?: C;
    form?: string;
  };

function FormControlComponent<C extends FormComponent = 'input'>(
  props: FormControlProps<C>,
  ref: Ref<never>,
) {
  const {
    as: Component = 'input',
    name,
    form,
    loading,
    loadingHeight,
    onChange,
    ...others
  } = props;
  const [field, meta, helpers] = useField({ name, ...others });
  const hasError = meta.touched && meta.error !== undefined;
  const errorMessageElementId = useId();

  return (
    <>
      {!loading ? (
        <Component
          ref={ref}
          error={hasError || undefined} // used for TextField et al.
          aria-invalid={hasError}
          aria-errormessage={errorMessageElementId}
          aria-describedby={errorMessageElementId}
          {...field}
          onChange={(eventOrValue: { nativeEvent: unknown } | number | string) => {
            if (typeof eventOrValue === 'object' && eventOrValue.nativeEvent) {
              field.onChange(eventOrValue);
            } else {
              helpers.setValue(eventOrValue);
            }

            if (onChange) {
              onChange(eventOrValue);
            }
          }}
          {...others}
        />
      ) : (
        <Shimmer.RoundedRectangle height={loadingHeight} />
      )}
      {hasError && form === 'cc' && (
        <NoHeightErrorMessage id={errorMessageElementId} data-testid={name}>
          {meta.error}
        </NoHeightErrorMessage>
      )}
      {hasError && form !== 'cc' && (
        <ErrorMessage id={errorMessageElementId} data-testid={name}>
          {meta.error && convertBbcode(meta.error)}
        </ErrorMessage>
      )}
    </>
  );
}

const FormControl = forwardRef(FormControlComponent) as typeof FormControlComponent;
export default FormControl;
