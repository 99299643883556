import { add } from 'date-fns';
import { NotifyRecipientsSettingRowValues } from '../../components/subforms/finishPurchase/NotifyRecipientsSettingRow';
import { BatchQuery } from '../../gql/graphql';
import { setRumGlobalContextProperty } from '../../utils/addRumCustomAttributes';
import delayStringToDuration from '../../utils/delayStringToDuration';
import newDate from '../../utils/newDate';
import roundToQuarterHour from '../../utils/roundToQuarterHour';

function logBuyEventToDatadog(
  summaryIds: Record<string, string>,
  batch: BatchQuery['batch'],
  notifyRecipientsDate: NotifyRecipientsSettingRowValues,
  defaultTrackingEmailsDelay: string,
  userTimezoneMinutesOffset: number,
) {
  const { rateGroups } = batch;

  // for simplicity, we only track users with one rateGroup && both carriers enabled
  if (!rateGroups || rateGroups.length > 1) {
    return;
  }

  const cheapestMailclasses = rateGroups[0].rateSummaries.filter(({ cheapest }) => cheapest);

  const boughtMailclass = rateGroups[0].rateSummaries.find(
    ({ uniqueId }) => uniqueId === Object.values(summaryIds)[0],
  );

  // ignore the rare case that there might be multiple cheapest mailclasses, which will lead to complex analytics
  if (!boughtMailclass || cheapestMailclasses.length !== 1) {
    return;
  }

  const cheapestMailclass = cheapestMailclasses[0];

  setRumGlobalContextProperty(
    'Purchased_Labels',
    {
      boughtMailclass: {
        totalPrice: boughtMailclass.totalPrice,
        uniqueId: boughtMailclass.uniqueId,
        mailClassTitle: boughtMailclass.mailClassTitle,
        carrier: boughtMailclass.carrier.carrierKey,
      },
      cheapestMailClass: {
        totalPrice: cheapestMailclass.totalPrice,
        uniqueId: cheapestMailclass.uniqueId,
        mailClassTitle: cheapestMailclass.mailClassTitle,
        carrier: cheapestMailclass.carrier.carrierKey,
      },
      priceDifference: Math.round(cheapestMailclass.totalPrice - boughtMailclass.totalPrice),
    },
    '2',
  );

  if (notifyRecipientsDate.enabled) {
    const trackingEmailsDelay = delayStringToDuration(defaultTrackingEmailsDelay);
    const todayLocalNow = add(newDate('now'), {
      minutes: userTimezoneMinutesOffset,
    });
    const initialTrackingEmailDelay = roundToQuarterHour(add(todayLocalNow, trackingEmailsDelay));
    setRumGlobalContextProperty(
      'Email Notification Delay',
      {
        initialTrackingEmailDelay,
        onBuyTrackingEmailDelay: notifyRecipientsDate.date,
      },
      '1',
      false,
    );
  }
}

export default logBuyEventToDatadog;
